const types = {
  LOGIN: '[Auth] Login',
  LOGOUT: '[Auth] Logout',
  RESET_PASSWORD: '[Auth] Reset Password',

  UI_START_LOADING: '[UI] Start Loading',
  UI_FINISH_LOADING: '[UI] Finish Loading',

  GET_PLOTS: '[Plot] Get All Plots',
  GET_PLOT_SHADEPANELS: '[Plot] Get Plot Shadepanels',
  GET_PLOT_PLANTATION_CYCLE: '[Plot] Get Plot Plantation Cycle',
  GET_PLOT_WEATHER_PROVIDERS: '[Plot] Get Plot Weather Providers',
  GET_PLOT_WEATHER_DATA: '[Plot] Get Plot Weather Data',

  GET_SHADEPANEL_STATE: '[Shadepanel] Get Shadepanel State',
  GET_SHADEPANEL_DISPLAYABLE_WIDGETS: '[Shadepanel] Get Shadepanel Displayable Widgets'
};

export default types;
