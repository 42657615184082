import { Icon } from './icon.interface';

const IconWind = ({ height = 32, width = 32, className }: Icon) => (
  <svg
    className={className}
    data-testid="icon-wind"
    fill="none"
    height={height}
    viewBox=" 0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.496 9.844a1.798 1.798 0 0 1 2.373-.118c.225.181.401.414.512.678a1.705 1.705 0 0 1-.166 1.63 1.756 1.756 0 0 1-.638.564 1.794 1.794 0 0 1-.835.202h-7.95m9.354 9.891c.205.203.458.353.736.436a1.798 1.798 0 0 0 1.637-.317c.225-.182.401-.415.512-.678a1.704 1.704 0 0 0-.166-1.63 1.754 1.754 0 0 0-.639-.565 1.793 1.793 0 0 0-.835-.202H6.793m13.893-7.169c.257-.251.573-.437.92-.54a2.25 2.25 0 0 1 2.04.4c.28.226.5.516.639.845a2.131 2.131 0 0 1-.203 2.032c-.201.296-.474.54-.793.707a2.24 2.24 0 0 1-1.04.258H6.793"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.038"
    />
  </svg>
);

export default IconWind;
