import { useQuery } from 'react-query';

import { IGetNotesParams } from 'interfaces/noteInterface';
import { noteService } from 'services';

export default function useQueryNotes(
  date: string,
  page: number,
  perPage: number,
  plot: number,
  shadepanel: number,
  treatment: string
) {
  const queryNoteParams: IGetNotesParams = {
    date,
    page,
    perPage,
    plot,
    shadepanel,
    treatment
  };
  return useQuery(['Notes', plot, shadepanel, treatment, date, page, perPage], () => noteService.getNotes(queryNoteParams), {
    // enabled: !!plot && !!shadepanel
  });
}
