import { HTMLAttributes, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';

import { Dropdown, InputNumber, Switch } from 'components';
import { plotMock } from 'mocks';

export interface IAlertParametersItemProps extends HTMLAttributes<HTMLDivElement> {
  isEditable?: boolean;
  title: string;
}

const AlertParametersItem = ({ title, isEditable = false }: IAlertParametersItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const localPlotId = plotMock.plots[0].id;
  const [plotId, setPlotId] = useState(localPlotId);
  const [limit, setLimit] = useState(1);

  const onSwitchHandler = () => {
    setIsActive(!isActive);
  };

  const onToogle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="alert-item">
      <div className=" alert-item__header flex items-center justify-between">
        <button className="alert-item__title w-col-90" onClick={() => onToogle()} type="button">
          {title}
        </button>
        <div className="alert-item__switch">
          <Switch handleToggle={() => onSwitchHandler()} isChecked={isActive} />
        </div>
      </div>
      {isEditable && (
        <AnimatePresence>
          {isOpen && (
            <motion.div
              animate={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.3 }
              }}
              className="alert-item__dropdown"
              exit={{
                opacity: 0,
                y: -10,
                transition: { duration: 0.3 }
              }}
              initial={{
                opacity: 0,
                y: -10
              }}
            >
              <div className="gap-2 grid grid-cols-2 md:grid-cols-1">
                <div className="flex items-end">
                  <InputNumber
                    className="mr-1"
                    label={t('ALERTS.CREATE.FORM.LIMIT')}
                    min={1}
                    name="limit"
                    onChangeNumber={(number) => setLimit(number)}
                    value={limit}
                  />
                  <div className="p-1">
                    <strong>mm</strong>
                  </div>
                </div>
                <div>
                  <Dropdown
                    bindLabel="name"
                    bindValue="id"
                    defaultValue={plotId as number}
                    items={plotMock.plots}
                    label={t('ALERTS.CREATE.FORM.PLOT')}
                    onSelectOption={(number) => setPlotId(number.value as number)}
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default AlertParametersItem;
