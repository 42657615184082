import { HTMLAttributes, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { DropdownMenu, DropdownMenuItem, Modal } from 'components';
import { IconMore } from 'components/Icons';
import { IShadepanelIndicatorWidget } from 'interfaces/shadepanelInterface';
import { roundedNumber } from 'pipes';
import { iconService } from 'services';

import '../DataWidget.scss';

export interface ISensorWidgetProps extends HTMLAttributes<HTMLDivElement> {
  widget: IShadepanelIndicatorWidget;
  onDelete?: (widgetId: number) => void;
}

const IndicatorWidget = ({ widget, onDelete }: ISensorWidgetProps) => {
  const { t } = useTranslation();

  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onDeleteHandler = () => {
    if (onDelete) onDelete(widget.id);
    setIsOpenDropdownMenu(!isOpenDropdownMenu);
  };

  return (
    <div className="datawidget">
      <div className="datawidget-circle">
        <div className="datawidget__header flex justify-between">
          <div className="content-center flex items-center">
            <div>
              <div
                className={classNames(
                  `datawidget__icon datawidget__icon--${
                    iconService.generateIconWidget(widget.icon).className
                  }  flex items-center justify-center `
                )}>
                {iconService.generateIconWidget(widget.icon).template}
              </div>
            </div>
            <div>
              <h4 className="datawidget__title">{widget.label}</h4>
              {widget.sector && (
                <div className="datawidget__label">
                  {t('COMPONENTS.WIDGETS.SECTOR')} {widget.sector}
                </div>
              )}
            </div>
          </div>
          <div className="datawidget__more">
            <DropdownMenu element={<IconMore height={20} width={20} />} isOpen={isOpenDropdownMenu} position="right">
              <DropdownMenuItem>
                {onDelete && (
                  <button onClick={() => onDeleteHandler()} type="button">
                    {t('COMPONENTS.WIDGETS.DELETE')}
                  </button>
                )}
              </DropdownMenuItem>
            </DropdownMenu>
          </div>
        </div>
        <div className="flex items-center ">
          {typeof widget.data.data.val === 'number' ? (
            <div className="">
              <div className="datawidget-sensor__label">{t('DATA_TYPE.VALUE')}</div>
              <div className="datawidget-sensor__value">
                {roundedNumber(widget.data.data.val, 1)} {widget.data.unit}
              </div>
            </div>
          ) : (
            <div className="datawidget__empty flex items-center justify-center w-col-100">
              {t('COMPONENTS.WIDGET_LIST.EMPTY.TITLE')}
            </div>
          )}

          {/* <div className="flex justify-center w-col-30">
            <RadialChart color="blue" percentage={10} showIcon size={82} sizeIcon={30} strokeWidth={10} unit="unit" value={10} />
          </div>
          <div className="datawidget-circle__value  w-col-60">{t('COMPONENTS.INDICATOR_WIDGET.OBJECTIVE')} 22/06/2022</div> */}
        </div>
      </div>

      <Modal isOpen={isOpenModal} onToggleModal={() => setIsOpenModal(!isOpenModal)} title={widget.reference} width={600}>
        <div className="note-modal" data-testid="note-modal">
          <div className="my-3">
            <div className="note-modal__title">{t('COMPONENTS.WIDGETS.MODAL.TITLE')}</div>
            <p className="note-modal__content">Description</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IndicatorWidget;
