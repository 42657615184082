import React, { useEffect, useState } from 'react';

import { geoJSON, LatLngLiteral } from 'leaflet';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';

import { IZone } from 'interfaces/plotInterface';

import './Map.scss';

export interface IMapProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: number;
  zone: IZone;
}

const Map = ({ height = 350, zone }: IMapProps) => {
  const [centerMap, setCenterMap] = useState<LatLngLiteral>();

  useEffect(() => {
    const geoJsonCenter = geoJSON(zone).getBounds().getCenter();
    setCenterMap(geoJsonCenter);
  }, [zone]);

  return (
    <div className="map">
      {centerMap && (
        <MapContainer center={centerMap} scrollWheelZoom={false} style={{ height }} zoom={20}>
          <TileLayer
            attribution='&copy; <a target="_blank" href="https://www.google.com/maps">Google Maps</a>'
            url="https://www.google.com/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
          />
          <GeoJSON data={zone} />
        </MapContainer>
      )}
    </div>
  );
};

export default Map;
