import { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';

import { Logo, IconHamburger } from 'components/Icons';
import SidebarMobile from 'elements/SidebarMobile/SidebarMobile';

import './HeaderMobile.scss';

const HeaderMobile = (): JSX.Element => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <header className=" flex header header--mobile justify-between px-3 py-2">
      <div className="flex header__logo items-center">
        <Logo className="mr-2" />
        ombreapp
      </div>
      <div className="content-center flex items-center justify-between nav-responsive">
        <div aria-hidden="true" onClick={() => setShowSidebar(!showSidebar)}>
          <IconHamburger />
        </div>

        {/* <div className="flex nav-right">
          <div aria-hidden="true" className="flex items-center justify-center mr-1 nav-right__item ">
            <IconAlert height={18} width={18} />
          </div>
          <div aria-hidden="true" className="flex items-center justify-center mr-1 nav-right__item ">
            <IconDocument height={20} width={20} />
          </div>
          <div aria-hidden="true" className="flex items-center justify-center nav-right__item ">
            <IconUser height={20} width={20} />
          </div>
        </div> */}
      </div>
      {createPortal(
        <AnimatePresence>
          {showSidebar && (
            <motion.div
              animate={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.3 }
              }}
              exit={{
                opacity: 0,
                x: -300,
                transition: { duration: 0.3 }
              }}
              initial={{
                opacity: 0,
                x: -300
              }}
              style={{ position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>
              <SidebarMobile onClose={() => setShowSidebar(!showSidebar)} />
            </motion.div>
          )}
        </AnimatePresence>,
        document.body
      )}
    </header>
  );
};

export default HeaderMobile;
