import { HTMLAttributes, useEffect, useState } from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import { IconArrowRight, IconArrowUp, IconClose } from 'components/Icons';
import { useClickOutside } from 'hooks';

import './Dropdown.scss';

export interface IDropdownProps extends HTMLAttributes<HTMLDivElement> {
  bindLabel: string;
  bindValue: string;
  defaultValue?: string | number;
  selectFirstOption?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  label?: string;
  onSelectOption: (option: IDropdownOption) => void;
  placeholder?: string;
  theme?: string;
  allowClear?: boolean;
}

export interface IDropdownOption {
  value: number | string;
  label: string;
}

/**
 *  Ombrea / Octo - Dropdown
 *
 * @param {IDropdownProps}
 * @return {*}  {JSX.Element}
 */
const Dropdown = ({
  bindLabel,
  bindValue,
  className = '',
  defaultValue,
  items,
  label = '',
  onSelectOption,
  placeholder,
  theme = 'default',
  allowClear = false,
  selectFirstOption = true
}: IDropdownProps): JSX.Element => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IDropdownOption>();
  const [options, setOptions] = useState<IDropdownOption[] | null>(null);
  const { ref } = useClickOutside(isListOpen, setIsListOpen);

  const toggleList = (): void => {
    if (options && options.length >= 1) {
      if (options.length === 1 && selectedOption) {
        setIsListOpen(false);
      } else {
        setIsListOpen(!isListOpen);
      }
    }
  };

  const onSelectItem = (option: IDropdownOption) => {
    setSelectedOption(option);
    setIsListOpen(false);
    onSelectOption(option);
  };

  const generateOptions = (): IDropdownOption[] =>
    items.map((item) => ({
      value: item[bindValue],
      label: item[bindLabel]
    }));

  const onClear = () => {
    setIsListOpen(false);
    setSelectedOption(undefined);
    onSelectOption({} as IDropdownOption);
  };

  useEffect(() => {
    const optionsGenerated = generateOptions();
    setOptions(optionsGenerated);
    if (selectFirstOption) {
      setSelectedOption(optionsGenerated[0]);
      onSelectOption(optionsGenerated[0]);
    }

    if (defaultValue) {
      setSelectedOption(optionsGenerated?.find((option) => option.value === defaultValue));
    } else {
      setSelectedOption(undefined);
    }
  }, [items]);

  return (
    <div className={`${className} dropdown dropdown--${theme}`} ref={ref}>
      {label.length > 1 && <div className="dropdown__label">{label}</div>}
      <div
        className="dropdown__input-fake"
        onMouseEnter={() => setShowClearButton(true)}
        onMouseLeave={() => setShowClearButton(false)}>
        {showClearButton && (
          <div className="dropdown__clear">
            {selectedOption && allowClear && (
              <button onClick={() => onClear()} style={{ width: 25 }} type="button">
                <IconClose height={18} width={18} />
              </button>
            )}
          </div>
        )}
        <button
          className={classNames('dropdown__container flex items-center justify-between', {
            'dropdown__container--open': isListOpen
          })}
          onClick={toggleList}
          type="button">
          {selectedOption ? selectedOption.label : placeholder}
          {options && selectedOption && (
            <> {isListOpen ? <IconArrowUp height={20} width={20} /> : <IconArrowRight height={20} width={20} />}</>
          )}
        </button>
      </div>
      <AnimatePresence>
        {isListOpen && (
          <motion.div>
            <div className={classNames('dropdown-list flex flex-col', {})} style={{ width: ref.current?.clientWidth }}>
              {options &&
                options.map((option: IDropdownOption) =>
                  option.value === selectedOption?.value ? null : (
                    <button
                      className="animate__animated animate__fadeIn animate__faster dropdown-list__item "
                      key={option.value}
                      onClick={() => onSelectItem(option)}
                      type="button">
                      {option.label}
                    </button>
                  )
                )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Dropdown;
