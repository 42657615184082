import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import './Card.scss';

export interface ICardMeasureProps {
  children: React.ReactNode;
  title?: string;
  sector?: number;
  controls?: ReactElement;
}

const CardMeasure = ({ children, title = '', sector, controls }: ICardMeasureProps) => {
  const { t } = useTranslation();

  return (
    <div className="card card--measure">
      <div className="card__header flex justify-between">
        <div>
          <div className="card__title">{title}</div>
          {sector && (
            <div className="card__label">
              {t('COMPONENTS.WIDGETS.SECTOR')} {sector}
            </div>
          )}
        </div>
        {controls && <div>{controls}</div>}
      </div>
      <div className="card__body">{children}</div>
    </div>
  );
};

export default CardMeasure;
