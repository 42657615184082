import { useMutation, useQueryClient } from 'react-query';

import { noteService } from 'services';

/**
 * Hook to mutate note by React Query
 *
 * @export
 * @return {*}
 */
export default function useMutationNote() {
  const queryClient = useQueryClient();
  return useMutation(({ note }: { note: FormData }) => noteService.createNote(note), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Notes']);
    }
  });
}
