import { useMutation, useQueryClient } from 'react-query';

import { plotService } from 'services';

/**
 * Hook to mutate plot by React Query (Only name)
 *
 * @export
 * @param {number} plotId
 * @return {*}
 */
export default function useMutationPlot(plotId: number) {
  const queryClient = useQueryClient();
  return useMutation(({ plotName }: { plotName: string }) => plotService.updatePlot(plotId, plotName), {
    onSuccess: (data) => {
      queryClient.setQueryData(['PlotSelected', plotId], data);
      queryClient.invalidateQueries(['Plots']);
    }
  });
}
