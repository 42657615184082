import { IWeatherData } from 'interfaces/weatherInterface';
import { API_ROUTES } from 'services/api/urls';
import axiosInstance from 'services/interceptor';

/**
 * Retrieve weather data from a provider
 *
 * @param {string} provider
 * @param {{ latitude: number; longitude: number }} location
 * @return {*}  {Promise<IWeatherData>}
 */
export const getWeatherData = async (
  provider: string,
  location: { latitude: number; longitude: number }
): Promise<IWeatherData> => {
  const weatherResponse = await axiosInstance.get<IWeatherData>(API_ROUTES.getWeatherData(), {
    params: {
      provider,
      latitude: location.latitude,
      longitude: location.longitude
    }
  });
  return weatherResponse.data;
};
