import { useEffect, useState } from 'react';

import { defaultTheme, Provider as ProviderSpectrum, View, RangeCalendar } from '@adobe/react-spectrum';
import { CalendarDate, getLocalTimeZone, parseDate, today } from '@internationalized/date';
import dayjs from 'dayjs';

import { IconClose, IconCalendar } from 'components/Icons';
import { useClickOutside } from 'hooks';

export interface IRangeDateObject {
  start: string;
  end: string;
}

export interface IDateRangePickerProps {
  allowClear?: boolean;
  defaultValue?: IRangeDateObject;
  disableFuture?: boolean;
  label?: string;
  onChangeDate: (rangeDate: IRangeDateObject | undefined) => void;
  placeholder: string;
}

const DateRangePicker = ({
  allowClear = false,
  defaultValue = undefined,
  disableFuture = false,
  label,
  onChangeDate,
  placeholder
}: IDateRangePickerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateDefault, setDateDefault] = useState<IRangeDateObject>();

  const { ref } = useClickOutside(isOpen, () => setIsOpen(false));

  const [showClearButton, setShowClearButton] = useState(false);

  // Disabled dates
  const now = today(getLocalTimeZone());
  const [disabledFutureDates, setDisabledFutureDates] = useState<CalendarDate[][]>();

  const onChangeDateHandler = (rangeDate: any) => {
    const range = {
      start: rangeDate.start.toString(),
      end: rangeDate.end.toString()
    };
    onChangeDate(range);
    setDateDefault(range);
    setIsOpen(false);
  };

  const onReset = () => {
    setIsOpen(false);
    setDateDefault(undefined);
    onChangeDate(undefined);
  };

  const isDateUnavailable = (date: any) =>
    disabledFutureDates
      ? disabledFutureDates.some((interval) => date.compare(interval[0]) >= 0 && date.compare(interval[1]) <= 0)
      : false;

  useEffect(() => {
    if (defaultValue) {
      setDateDefault(defaultValue);
    } else {
      setDateDefault(undefined);
    }

    if (disableFuture) {
      setDisabledFutureDates([[now.add({ days: 1 }), now.add({ years: 100 })]]);
    }
  }, [defaultValue]);

  return (
    <div className="datepicker" ref={ref}>
      {label && <div className="datepicker__label">{label}</div>}
      <div
        className="datepicker__input-fake"
        onMouseEnter={() => setShowClearButton(true)}
        onMouseLeave={() => setShowClearButton(false)}>
        {dateDefault && showClearButton && allowClear && (
          <div className="datepicker__clear">
            <button onClick={onReset} style={{ width: 25 }} type="button">
              <IconClose height={18} width={18} />
            </button>
          </div>
        )}
        <button className="datepicker__input flex items-center justify-between" onClick={() => setIsOpen(!isOpen)} type="button">
          {dateDefault ? (
            `${dayjs(dateDefault.start).format('DD/MM/YYYY')} - ${dayjs(dateDefault.end).format('DD/MM/YYYY')}`
          ) : (
            <div className="datepicker__placeholder">{placeholder}</div>
          )}
          <IconCalendar className="datepicker__icon" height={24} width={24} />
        </button>
      </div>
      {isOpen && (
        <div className="datepicker__dropdown" style={{ top: '45px' }}>
          <ProviderSpectrum colorScheme="light" locale="fr-FR" theme={defaultTheme}>
            <View backgroundColor="gray-50">
              <RangeCalendar
                defaultValue={dateDefault ? { start: parseDate(dateDefault.start), end: parseDate(dateDefault.end) } : undefined}
                isDateUnavailable={isDateUnavailable}
                onChange={(e) => onChangeDateHandler(e)}
              />
            </View>
          </ProviderSpectrum>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
