import { useQuery } from 'react-query';

import { localStorageService, plotService } from 'services';

/**
 * Hook to get plot by React Query as key "PlotSelected"
 *
 * @export
 * @param {number} plotId
 * @return {*}
 */
export default function useQueryPlotShadepanels() {
  return useQuery(['Plots'], () => plotService.getPlots(), {
    onSuccess: (plots) => {
      const plotId = localStorageService.getItem('plotId');
      if (!plotId) localStorageService.setItem('plotId', plots[0].id);
    }
  });
}
