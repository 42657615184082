import {
  ICamera,
  IDataSensorDistancesWidget,
  IGraphMeasureV2,
  IGridDataPage,
  IIndicatorMeasure,
  ISensorMeasure,
  IShadepanel,
  IShadepanelDisplayableWidget,
  IShadepanelDisplayableWidgetsList,
  IShadepanelDisplayedWidget,
  IShadepanelDisplayedWidgetDto,
  IShadepanelImage,
  IShadepanelState
} from 'interfaces/shadepanelInterface';
import { IDisplayedWidgetDto, IWidgetLayoutPosition } from 'interfaces/widgetInterface';
import { apiService } from 'services';
import { API_ROUTES } from 'services/api/urls';

import axiosInstance from '../interceptor';

/**
 * Get shadepanel state
 * @version v2
 * @param {number} shadepanelId
 * @return {*}  {Promise<IShadepanelState>}
 */
export const getShadepanelState = async (shadepanelId: number): Promise<IShadepanelState> => {
  const shadepanelState = await axiosInstance.get<IShadepanelState>(API_ROUTES.getShadepanelState(shadepanelId));
  return shadepanelState.data;
};

/**
 * Get Shadepanel Displayable Widgets
 *
 * @param {number} shadepanelId
 * @return {*}  {Promise<IShadepanelDisplayableWidget[]>}
 */
export const getShadepanelDislayableWidgets = async (shadepanelId: number): Promise<IShadepanelDisplayableWidget[]> => {
  const shadepanelDisplayableWidgets = await axiosInstance.get<IShadepanelDisplayableWidget[]>(
    API_ROUTES.getShadepanelDisplayableWidgets(shadepanelId)
  );
  return shadepanelDisplayableWidgets.data;
};

/**
 * Get Shadepanel Displayed Widgets
 *
 * @param {number} shadepanelId
 * @return {*}  {Promise<IShadepanelDisplayedWidget[]>}
 */
export const getShadepanelDisplayedWidgets = async (shadepanelId: number): Promise<IShadepanelDisplayedWidget[]> => {
  const shadepanelDisplayedWidgets = await axiosInstance.get<IShadepanelDisplayedWidget[]>(
    API_ROUTES.getShadepanelDisplayedWidgets(shadepanelId)
  );
  return shadepanelDisplayedWidgets.data;
};

/**
 * Update list of all displated Widgets
 *
 * @return {*}  {Promise<IShadepanelDisplayedWidget[]>}
 */
export const updateDisplayedWidget = async (
  shadepanelId: number,
  displayedWidgets: IShadepanelDisplayedWidgetDto[]
): Promise<IShadepanelDisplayedWidget[]> => {
  const shadepanelDisplayedWidget = await axiosInstance.post<IShadepanelDisplayedWidget[]>(
    API_ROUTES.updateDisplayedWidget(shadepanelId),
    displayedWidgets
  );
  return shadepanelDisplayedWidget.data;
};

/**
 * Get all cameras from shadepanel
 *
 * @param {number} shadepanelId
 * @return {*}  {Promise<ICamera[]>}
 */
export const getShadepanelCameras = async (shadepanelId: number): Promise<ICamera[]> => {
  const cameras = await axiosInstance.get<ICamera[]>(API_ROUTES.getShadepanelCameras(shadepanelId));
  return cameras.data;
};

/**
 * Get last Shadepanel Image from camera
 *
 * @param {number} shadepanelId
 * @param {number} cameraId
 * @return {*}  {Promise<IShadepanelImage>}
 */
export const getShadepanelImage = async (shadepanelId: number, cameraId: number): Promise<IShadepanelImage> => {
  const shadepanelImage = await axiosInstance.get<IShadepanelImage>(API_ROUTES.getShadepanelLastImage(shadepanelId, cameraId));
  return shadepanelImage.data;
};

/**
 * Update Shadepanel Image
 *
 * @param {number} shadepanelId
 * @param {number} cameraId
 * @return {*}  {Promise<IShadepanelImage>}
 */
export const updateShadepanelImage = async (shadepanelId: number, cameraId: number): Promise<IShadepanelImage> => {
  const shadepanelImage = await axiosInstance.get<IShadepanelImage>(API_ROUTES.updateShadepanelImage(shadepanelId, cameraId));
  return shadepanelImage.data;
};

/**
 * Get shadepanel detail
 *
 * @param {number} shadepanelId
 * @return {*}  {Promise<IShadepanel>}
 */
export const getShadepanel = async (shadepanelId: number): Promise<IShadepanel> => {
  const shadepanel = await axiosInstance.get<IShadepanel>(API_ROUTES.getShadepanel(shadepanelId));
  return shadepanel.data;
};

/**
 * Update shadepanel (only name)
 *
 * @param {number} shadepanelId
 * @param {string} shadepanelName
 * @return {*}  {Promise<IShadepanel>}
 */
export const updateShadepanel = async (shadepanelId: number, shadepanelName: string): Promise<IShadepanel> => {
  const shadepanelUpdated = await axiosInstance.patch<IShadepanel>(API_ROUTES.updateShadepanel(shadepanelId), {
    name: shadepanelName
  });
  return shadepanelUpdated.data;
};

/**
 * Get sensor measure data
 *
 * @param {number} shadepanelId
 * @param {number} datatype
 * @param {number} refId
 * @return {*}  {Promise<ISensorMeasure>}
 */
export const getSensorMeasures = async (
  shadepanelId: number,
  datatype: string,
  refId: number,
  sector: number
): Promise<ISensorMeasure> => {
  const params = apiService.getQueryParams([{ key: 'sector', value: sector }]);
  const datatypeData = await axiosInstance.get<ISensorMeasure>(API_ROUTES.getShadepanelMeasure(shadepanelId, datatype, refId), {
    params
  });
  return datatypeData.data;
};

/**
 * Get shadepanel indicator measure
 *
 * @param {number} shadepanelId
 * @param {string} category
 * @return {*}  {Promise<IIndicatorMeasure[]>}
 */
export const getIndicatorMeasures = async (shadepanelId: number, datatype: string, refId: number): Promise<IIndicatorMeasure> => {
  const indicators = await axiosInstance.get<IIndicatorMeasure>(API_ROUTES.getShadepanelMeasure(shadepanelId, datatype, refId));
  return indicators.data;
};

/**
 * Get shadepanel graph measure
 *
 * @param {number} shadepanelId
 * @param {string} category
 * @return {*}  {Promise<IGraphMeasureV2[]>}
 */
export const getGraphMeasure = async (
  shadepanelId: number,
  datatype: string,
  refId: number,
  startDate: string,
  endDate: string,
  sector: number
): Promise<IGraphMeasureV2> => {
  const params = apiService.getQueryParams([
    { key: 'start_date', value: startDate },
    { key: 'end_date', value: endDate },
    { key: 'sector', value: sector }
  ]);
  const graphs = await axiosInstance.get<IGraphMeasureV2>(API_ROUTES.getShadepanelMeasure(shadepanelId, datatype, refId), {
    params
  });

  return graphs.data;
};

/**
 * Get Shadepanel data page by category
 *
 * @param {number} shadepanelId
 * @param {string} category
 * @return {*}  {Promise<IGridDataPage>}
 */
export const getDataPageGrid = async (shadepanelId: number, category: string): Promise<IGridDataPage> => {
  const dataPageGrid = await axiosInstance.get<IGridDataPage>(API_ROUTES.getShadepanelMeasureCategory(shadepanelId, category));
  return dataPageGrid.data;
};

/**
 * Generate Layour for Widgets
 *
 * @param {IShadepanelDisplayedWidget} widget
 * @param {string} breakpoint
 * @return {*}
 */
export const generateLayoutWidgets = (widget: IShadepanelDisplayedWidget, breakpoint: string) => {
  if (widget.type === 'type_1' || widget.type === 'type_2') {
    return {
      ...widget.position[breakpoint],
      w: 1,
      h: 14,
      y: widget.position[breakpoint].y === null ? Infinity : widget.position[breakpoint].y
    };
  }

  if (widget.type === 'type_3') {
    if (widget.data && Object.keys(widget.data).length !== 0) {
      const distances = Object.keys((widget.data as IDataSensorDistancesWidget).distances).length;
      // 1 distance = 1 row
      // 12 padding
      const distanceHeight = distances * 5 + 8;

      return {
        ...widget.position[breakpoint],
        w: 1,
        h: distanceHeight,
        y: widget.position[breakpoint].y === null ? Infinity : widget.position[breakpoint].y
      };
    }

    return {
      ...widget.position[breakpoint],
      w: 1,
      h: 14,
      y: widget.position[breakpoint].y === null ? Infinity : widget.position[breakpoint].y
    };
  }

  return {
    ...widget.position[breakpoint]
  };
};

/**
 * Generate Key for drag and drop list
 *
 * @param {IShadepanelDisplayedWidget} widget
 * @return {*}  {string}
 */
export const generateGridKey = (widget: IShadepanelDisplayedWidget | IShadepanelDisplayableWidgetsList): string => {
  if (typeof widget.sector === 'number') {
    return `${widget.data_type}-${widget.reference}-${widget.sector}`;
  }
  return `${widget.data_type}-${widget.reference}`;
};

/**
 * Order Widget positions
 *
 * @param {IWidgetLayoutPosition[]} layoutChange
 * @param {IShadepanelDisplayedWidget[]} widgets
 * @param {string} breakpoint
 * @return {*}  {IDisplayedWidgetDto[]}
 */
export const orderWidgetPositions = (
  layoutChange: IWidgetLayoutPosition[],
  widgets: IShadepanelDisplayedWidget[],
  breakpoint: string
): IDisplayedWidgetDto[] => {
  const widgetPositions = layoutChange.map((item) => {
    const newWidget = widgets.find((widget) => generateGridKey(widget) === item.i);
    const layouts = breakpoint === 'lg' || breakpoint === 'md' ? ['lg', 'md'] : ['sm', 'xs'];
    return {
      ...newWidget,
      position: {
        ...newWidget?.position,
        [layouts[0]]: {
          i: item.i,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h
        },
        [layouts[1]]: {
          i: item.i,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h
        }
      }
    };
  });
  const ids = widgetPositions.map((o) => o.id);
  const filtered = widgetPositions.filter(({ id }, index) => !ids.includes(id, index + 1));
  return filtered as IDisplayedWidgetDto[];
};
