import { AxiosResponseHeaders } from 'axios';

import { IPagination } from 'components/Pagination/Pagination';

import { PAGE_SIZE } from './api/apiService';

export const getHeadersPagination = (headers: AxiosResponseHeaders, perPage = PAGE_SIZE): IPagination => ({
  total: Number(headers['x-total']),
  pageCount: Number(headers['x-page-count']),
  currentPage: Number(headers['x-current-page']),
  perPage
});
