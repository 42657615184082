import { IconEmpty } from 'components/Icons';

import './Empty.scss';

export interface IEmptyProps {
  title: string;
  message: string;
  height?: number;
  iconSize?: number;
}

const Empty = ({ title, message, height = 250, iconSize = 80 }: IEmptyProps) => (
  <div className="empty flex items-center justify-center" style={{ height }}>
    <div>
      <div className="empty__icon flex justify-center">
        <IconEmpty height={iconSize} width={iconSize} />
      </div>
      <div className="empty__title flex justify-center my-1">{title}</div>
      <div className="empty__message flex justify-center">{message}</div>
    </div>
  </div>
);

export default Empty;
