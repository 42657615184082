import { useQuery } from 'react-query';

import { shadepanelService } from 'services';

/**
 * Hook to get shadepanel sensors measures
 *  - type: sensor
 *  - category: thermal, water, other
 *
 * @param {number} shadepanelId
 * @param {string} type
 * @param {string} category
 * @param {number} sector
 * @return {*}
 */
export default function useQueryShadepanelSensorMeasure(shadepanelId: number, refId: number, sector: number) {
  return useQuery(
    [`ShadepanelMeasure - sensor`, shadepanelId, 'sensor', refId, sector],
    () => shadepanelService.getSensorMeasures(shadepanelId, 'sensor', refId, sector),
    {
      enabled: !!shadepanelId && !!refId
    }
  );
}
