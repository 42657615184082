import { HTMLAttributes, useEffect, useState } from 'react';

import classNames from 'classnames';

import './Tabs.scss';

export interface ITabOption {
  label: string;
  value: number | string;
}

export interface ITabsProps extends HTMLAttributes<HTMLDivElement> {
  bindLabel: string;
  bindValue: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  defaultValue?: string | number;
  type?: string;
  onSelectOption: (value: ITabOption) => void;
}

/**
 *
 *
 * @param {ITabsProps} { bindLabel, bindValue, items, onSelectOption }
 * @return {*}
 */
const Tabs = ({ bindLabel, bindValue, items, onSelectOption, type = 'pills', defaultValue }: ITabsProps) => {
  const [options, setOptions] = useState<ITabOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ITabOption>();

  const onSelectTab = (tab: ITabOption) => {
    onSelectOption(tab);
    setSelectedOption(tab);
  };

  const generateOptions = (): ITabOption[] =>
    items.map((item) => ({
      label: item[bindLabel],
      value: item[bindValue]
    }));

  useEffect(() => {
    const optionsGenereted = generateOptions();
    setOptions(optionsGenereted);

    if (defaultValue) {
      setSelectedOption(optionsGenereted?.find((option) => option.value === defaultValue));
    } else {
      setSelectedOption(optionsGenereted[0]);
    }
  }, [defaultValue, items]);

  return (
    <div className={` tabs tabs--${type}`}>
      <div className="flex flex-nowrap justify-between tab-options">
        {options &&
          options.map((option: ITabOption) => (
            <div key={option.label}>
              <button
                className={classNames('tab-options__item', {
                  'tab-options__item--active': selectedOption?.value === option.value
                })}
                onClick={() => onSelectTab(option)}
                type="button"
              >
                {option.label}
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Tabs;
