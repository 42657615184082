import { useQuery } from 'react-query';

import { shadepanelService } from 'services';

/**
 * Hook to get indicator measures
 *    - type: indicator
 *    - category: thermal, water, other
 *
 * @param {number} shadepanelId
 * @param {string} type
 * @param {string} category
 * @return {*}
 */
export default function useQueryShadepanelDataPageGrid(shadepanelId: number, category: string) {
  return useQuery(
    [`ShadepanelDataPageGrid`, shadepanelId, category],
    () => shadepanelService.getDataPageGrid(shadepanelId, category),
    {
      enabled: !!shadepanelId && !!category
    }
  );
}
