const IconWaterDrop = ({ height = 32, width = 32, className }: { height?: number; width?: number; className?: string }) => (
  <svg
    className={className}
    data-testid="icon-water-drop"
    fill="none"
    height={height}
    viewBox=" 0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.29 18.767a5.987 5.987 0 0 1-11.976 0c0-2.55 1.585-5.267 3.336-7.482a33.981 33.981 0 0 1 2.353-2.653c.126-.13.24-.245.345-.35a29.787 29.787 0 0 1 2.642 2.95c1.734 2.225 3.3 4.978 3.3 7.535Z"
      stroke="currentColor"
      strokeWidth="2.113"
    />
  </svg>
);

export default IconWaterDrop;
