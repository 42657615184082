import { Icon } from './icon.interface';

const IconEyeShow = ({ height = 24, width = 24, className = '' }: Icon) => (
  <svg className={className} fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M19 12.5c0 2.485-3.134 4.5-7 4.5s-7-2.015-7-4.5S8.134 8 12 8s7 2.015 7 4.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.496"
    />
    <path
      clipRule="evenodd"
      d="M13.75 12.5a1.751 1.751 0 1 1-3.5 0 1.751 1.751 0 1 1 3.5 0v0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.496"
    />
  </svg>
);

export default IconEyeShow;
