import { Icon } from './icon.interface';

const IconWeatherCloudy = ({ height = 100, width = 100 }: Icon) => (
  <svg fill="none" height={height} viewBox="0 0 100 100" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M70.598 75.3H34.464v-.027C20.849 74.66 10 63.423 10 49.65 10 35.484 21.478 24 35.636 24c11.349 0 20.975 7.378 24.35 17.6a18.26 18.26 0 0 1 10.67-3.425c10.18 0 18.432 8.31 18.432 18.563 0 10.025-7.893 18.195-17.761 18.55v.012h-.73Z"
      fill="#fff"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M71.327 75.288c9.869-.355 17.76-8.525 17.76-18.55 0-10.252-8.251-18.563-18.43-18.563-9.956 0-18.067 7.949-18.42 17.888H34.464V75.3h36.863v-.012Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

export default IconWeatherCloudy;
