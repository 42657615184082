import { t } from 'i18next';

import { Dropdown } from 'components';
import { IDropdownOption } from 'components/Dropdown/Dropdown';
import { IconArrowLeft, IconEmail, IconPhone } from 'components/Icons';

export interface IMyAccountSupportProps {
  onSetMenuSelected: (menuSelected: string) => void;
  subjectTypes: IDropdownOption[];
  subject: string;
  onChangeSubject: (subject: IDropdownOption) => void;
}

const MyAccountSupport = ({ onSetMenuSelected, subject, onChangeSubject, subjectTypes }: IMyAccountSupportProps) => (
  <div className="">
    <div className="">
      <button className="account__back flex items-center text-left" onClick={() => onSetMenuSelected('profile')} type="button">
        <IconArrowLeft className="mr-1" height={28} width={28} />
        {t('MY_ACCOUNT.MOBILE.MY_ACCOUNT.LABEL')}
      </button>
    </div>
    <h2>{t('MY_ACCOUNT.MOBILE.SUPPORT.LABEL')}</h2>

    <div className="my-4">
      <div className="">
        <Dropdown
          bindLabel="label"
          bindValue="value"
          defaultValue={subject}
          items={subjectTypes}
          label={t('MY_ACCOUNT.SUPPORT.SUBJECT.LABEL')}
          onSelectOption={onChangeSubject}
        />
      </div>
      <div className="my-2">
        <hr />
      </div>

      <div>
        <div className="account__card-button mb-2">
          <div className="flex items-center label-card">
            <IconPhone className="icon-card mr-2" />
            {t('MY_ACCOUNT.SUPPORT.CALL')}
          </div>
        </div>
        <div className="account__card-button mb-2">
          <div className="flex items-center label-card">
            <IconEmail className="icon-card mr-2" />
            {t('MY_ACCOUNT.SUPPORT.EMAIL')}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MyAccountSupport;
