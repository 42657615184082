const IconGraphs = ({ height = 32, width = 32, className }: { height?: number; width?: number; className?: string }) => (
  <svg
    className={className}
    data-testid="icon-graphs"
    fill="none"
    height={height}
    viewBox=" 0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.549 5.712a2.085 2.085 0 0 0-1.46 1.157l-.169.358-.016 1.85-.017 1.851-2.33.016-2.33.016-.358.169a2.055 2.055 0 0 0-1.163 1.517c-.037.198-.05 2.297-.041 6.187l.015 5.887.128.32c.178.442.71.974 1.152 1.152l.32.128h17.44l.32-.128c.442-.178.974-.71 1.152-1.152l.128-.32.015-6.907c.011-4.891-.001-7.007-.043-7.253a2.08 2.08 0 0 0-1.163-1.538l-.302-.142-2.355-.015-2.354-.015-.02-.812c-.019-.78-.026-.825-.188-1.169a2.031 2.031 0 0 0-1.508-1.162c-.346-.066-4.515-.062-4.853.005M18.133 16l.001 8.32h-4.267V16l.001-8.32h4.264l.001 8.32m6.187 1.573v6.747h-4.213V10.827h4.213v6.746m-12.44 1.054.014 5.693H7.68V12.905l2.093.014 2.094.014.013 5.694"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconGraphs;
