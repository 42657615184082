import { t } from 'i18next';

import { FileItem } from 'components';
import { IconArrowLeft } from 'components/Icons';
import { useQueryUserDocuments } from 'hooks';

export interface IMyAccountDocumentsProps {
  onSetMenuSelected: (menuSelected: string) => void;
}

const MyAccountDocuments = ({ onSetMenuSelected }: IMyAccountDocumentsProps) => {
  const { data: userDocuments } = useQueryUserDocuments();

  return (
    <div className="">
      <div className="">
        <button className="account__back flex items-center text-left" onClick={() => onSetMenuSelected('profile')} type="button">
          <IconArrowLeft className="mr-1" height={28} width={28} />
          {t('MY_ACCOUNT.MOBILE.MY_ACCOUNT.LABEL')}
        </button>
      </div>
      <h2>{t('MY_ACCOUNT.MOBILE.DOCUMENTS.LABEL')}</h2>

      <div className="my-2">
        <div className="">
          {userDocuments &&
            userDocuments.map((document) => (
              <div className="mb-2" key={document.id}>
                <a href={document.file} key={document.id} rel="noreferrer" target="_blank">
                  <FileItem key={document.id} name={document.name} type="image/png" />
                </a>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MyAccountDocuments;
