import { HTMLAttributes } from 'react';

import classNames from 'classnames';

import './Container.scss';

interface IContainer extends HTMLAttributes<HTMLDivElement> {
  showSidebar?: boolean;
}

const Container = ({ children, className = '', showSidebar = false }: IContainer) => (
  <div
    className={classNames(`container ${className}`, {
      'container--sidebar': showSidebar
    })}
  >
    {children}
  </div>
);

export default Container;
