export function setItem<T>(key: string, value: T): void {
  localStorage.removeItem(key);
  localStorage.setItem(key, JSON.stringify({ value }));
}

export function getItem<T>(key: string): T | null {
  const data: string | null = localStorage.getItem(key);

  if (data !== null) {
    return JSON.parse(data).value;
  }

  return null;
}
