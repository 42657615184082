import { useState } from 'react';

import { t } from 'i18next';

import { Modal } from 'components';
import { INote } from 'interfaces/noteInterface';
import { dateFormat, truncateWords } from 'pipes';

import './NoteItem.scss';

export interface INoteItemProps {
  note: INote;
}
const NoteItem = ({ note }: INoteItemProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <button className="gap-2 grid grid-cols-4 items-center note-item" onClick={() => setIsOpenModal(!isOpenModal)} type="button">
      <div className="note-item__date">{dateFormat(note.date, false, 'DD/MM/YYYY')}</div>
      <div className="col-span-3">
        <div className="note-item__title">{note.title}</div>
        <div className="note-item__content">{truncateWords(note.content, 11, '...')}</div>
      </div>
      <Modal isOpen={isOpenModal} onToggleModal={() => setIsOpenModal} title={note.title} width={600}>
        <div className="note-modal" data-testid="note-modal">
          {note.content && note.content.length > 0 && (
            <div className="my-3">
              <div className="note-modal__title">{t('NOTES_PAGE.MODAL.CONTENT')}</div>
              <p className="note-modal__content">{note.content}</p>
            </div>
          )}

          {note.file && (
            <div className="">
              <div className="note-modal__title">{t('NOTES_PAGE.MODAL.IMAGES')}</div>
              <div className="note-modal__images pt-1">
                <img alt="" className="img-responsive" src={note.file} />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </button>
  );
};

export default NoteItem;
