import { useEffect } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Alert } from 'components';
import Button from 'components/Button/Button';
import { IconCheck, Logo } from 'components/Icons';
import Input from 'components/Input/Input';
import { useMutationPasswordRecoveryToken } from 'hooks';
import { ResetPasswordTokenDto } from 'interfaces/authInterface';
import { authService } from 'services';

import './PasswordRecovery.scss';

const PasswordRecoveryToken = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  const SECONDS_REDIRECT = 5 * 1000;

  const mutationPasswordRecovery = useMutationPasswordRecoveryToken();

  const onSubmitHandler = async (values: ResetPasswordTokenDto, resetForm: () => void) => {
    mutationPasswordRecovery.mutate(values);
    resetForm();
  };

  // Form
  const { errors, touched, isValid, dirty, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues: {
      password: '',
      password_repeat: ''
    },
    onSubmit: async (values) => {
      onSubmitHandler(values, resetForm);
    },
    validationSchema: Yup.object({
      password: Yup.string().required('RECOVERY_PASSWORD_TOKEN.FORM.PASSWORD.ERRORS.REQUIRED'),
      password_repeat: Yup.string()
        .required('RECOVERY_PASSWORD_TOKEN.FORM.PASSWORD_REPEAT.ERRORS.REQUIRED')
        .oneOf([Yup.ref('password'), null], t('RECOVERY_PASSWORD_TOKEN.FORM.PASSWORD_REPEAT.ERRORS.NOT_SAME'))
    })
  });

  useEffect(() => {
    if (token) {
      authService.setLocalToken(token);
    }
  }, []);

  useEffect(() => {
    if (mutationPasswordRecovery.isSuccess) {
      setTimeout(() => {
        navigate('/login');
      }, SECONDS_REDIRECT);
    }
  }, [mutationPasswordRecovery.isSuccess]);

  return (
    <div className="flex items-center justify-center password-recovery">
      <div className="form md:w-col-80  sm:w-col-80 w-col-30 xs:w-col-80">
        <div className="flex items-center justify-center mb-4">
          <div className="logo text-center">
            <Logo height={60} width={60} />
            <div className="mt-1">ombreapp</div>
          </div>
        </div>
        <form className="" noValidate onSubmit={handleSubmit}>
          {mutationPasswordRecovery.isSuccess && (
            <div className="my-4">
              <Alert
                icon={<IconCheck />}
                label={t('RECOVERY_PASSWORD_TOKEN.SUCCESS.LABEL')}
                title={t('RECOVERY_PASSWORD_TOKEN.SUCCESS.TITLE')}
                type="success"
              />
            </div>
          )}
          <h3 className="password-recovery__title">{t('RECOVERY_PASSWORD_TOKEN.TITLE')}</h3>
          <div className="form-control">
            <Input
              label={t('RECOVERY_PASSWORD_TOKEN.FORM.PASSWORD.LABEL')}
              type="password"
              {...getFieldProps('password')}
              error={errors.password}
              touched={touched.password}
            />
          </div>

          <div className="form-control">
            <Input
              label={t('RECOVERY_PASSWORD_TOKEN.FORM.PASSWORD_REPEAT.LABEL')}
              type="password"
              {...getFieldProps('password_repeat')}
              error={errors.password_repeat}
              touched={touched.password_repeat}
            />
          </div>

          <div className="form-control text-center">
            <Button block disabled={!(isValid && dirty)} htmlType="submit" type="primary">
              {t('RECOVERY_PASSWORD_TOKEN.FORM.BTN_SUBMIT')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordRecoveryToken;
