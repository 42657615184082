import { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import './Alert.scss';

export interface IAlertProps {
  title: string;
  label: string;
  icon: JSX.Element;
  type: 'success' | 'error' | 'warning' | 'info';
  timeToClose?: number;
}

/**
 *
 *
 * @param {IAlertProps} { title, label, icon, type, tyimeToClose }
 */
const Alert = ({ title, label, icon, type, timeToClose = 0 }: IAlertProps) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (timeToClose > 0) {
      setTimeout(() => {
        setShow(false);
      }, timeToClose);
    }
  }, []);

  return (
    <AnimatePresence>
      {show ? (
        <motion.div
          animate={{
            opacity: 1,
            transition: {
              ease: 'easeOut',
              duration: 0.3
            }
          }}
          className={`alert alert--${type} grid grid-cols-6 `}
          exit={{
            opacity: 0,
            transition: {
              ease: 'easeIn',
              duration: 0.3
            }
          }}
          initial={{
            opacity: 0
          }}
        >
          <div className="alert__container col-span-5">
            <div className="alert__title">{title}</div>
            <div className="alert__label">{label}</div>
          </div>
          <div className="alert__icon col-span-1 flex justify-end self-center">{icon}</div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default Alert;
