import { useEffect, useRef } from 'react';

/**
 *Hook for handling closing when clicking outside of an element
 *
 * @export
 * @param {boolean} isOpen
 * @param {(open: boolean) => void} setIsOpen
 * @return {*}
 */
export default function useClickOutside(isOpen: boolean, setIsOpen: (open: boolean) => void) {
  const ref = useRef<HTMLDivElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isOpen) {
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref };
}
