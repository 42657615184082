import { InputHTMLAttributes, useState } from 'react';

import classNames from 'classnames';
import { t } from 'i18next';
import { useMediaQuery } from 'react-responsive';

import { IconArrowDown, IconArrowLeft, IconArrowRight, IconArrowUp } from 'components/Icons';

import './InputNumber.scss';

export interface IInputNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  touched?: boolean;
  label?: string;
  name: string;
  onChangeNumber: (number: number) => void;
  min?: number;
  max?: number;
}

/**
 *
 *
 * @param {IInputNumberProps} { label, min, max, name, onChangeNumber, touched, error }
 * @return {*}
 */
const InputNumber = ({ label, min, max, name, className, onChangeNumber, touched, error }: IInputNumberProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 425px)' });
  const [number, setNumber] = useState<number>(1);

  const onChangeNumberHandler = (numberEvent: string) => {
    setNumber(Number(numberEvent));
    onChangeNumber(Number(numberEvent));
  };

  const onArrowClick = (direction: 'up' | 'down') => {
    if (direction === 'up') {
      const newNumber = number + 1;
      if (newNumber > (max as number)) {
        return;
      }
      onChangeNumberHandler(newNumber.toString());
    }

    if (direction === 'down') {
      const newNumber = number - 1;
      if (newNumber < (min as number)) {
        return;
      }
      onChangeNumberHandler(newNumber.toString());
    }
  };

  return (
    <div className={`${className} input-control`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div
        className={classNames('input-number', {
          'flex input-number--mobile': isMobile
        })}
      >
        {isMobile && (
          <button className="input-number__arrow--left" onClick={() => onArrowClick('down')} type="button">
            <IconArrowLeft height={15} width={15} />
          </button>
        )}
        <input
          className="input-number__input"
          id={name}
          onChange={(event) => onChangeNumberHandler(event.target.value)}
          type="number"
          value={number}
        />

        {isMobile && (
          <button className="input-number__arrow--right" onClick={() => onArrowClick('up')} type="button">
            <IconArrowRight height={15} width={15} />
          </button>
        )}
        {!isMobile && (
          <div className="flex flex-col input-number__arrows">
            <button className="input-number__arrow input-number__arrow--up" onClick={() => onArrowClick('up')} type="button">
              <IconArrowUp height={15} width={15} />
            </button>
            <button className="input-number__arrow input-number__arrow--down" onClick={() => onArrowClick('down')} type="button">
              <IconArrowDown height={15} width={15} />
            </button>
          </div>
        )}
      </div>
      {touched && error && <div className="input-control__hint input-control__hint--error">{t(error)}</div>}
    </div>
  );
};

export default InputNumber;
