import { useEffect } from 'react';

import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Alert } from 'components';
import Button from 'components/Button/Button';
import { IconAlertOctagon, Logo } from 'components/Icons';
import Input from 'components/Input/Input';
import { useMutationLogin } from 'hooks';
import { getLocalToken } from 'services/auth/authService';

import wallpaperImg from '../../assets/images/wallpaper-ombrea.png';

import './Login.scss';

export interface ILoginProps {
  isMobile: boolean;
}

const Login = ({ isMobile }: ILoginProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tokenLocal = getLocalToken();

  const { mutate: mutationLogin, isError: isErrorLogin } = useMutationLogin();

  useEffect(() => {
    if (tokenLocal) navigate('/dashboard');
  }, [tokenLocal]);

  const onSubmitHandler = async (values: { email: string; password: string }) => {
    mutationLogin(values);
  };

  // Form
  const { errors, touched, getFieldProps, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: onSubmitHandler,
    validationSchema: Yup.object({
      email: Yup.string().required('LOGIN.ERRORS.EMAIL_REQUIRED').email('LOGIN.ERRORS.EMAIL_VERIFICATION'),
      password: Yup.string().required('LOGIN.ERRORS.PASSWORD_REQUIRED')
    })
  });

  return (
    <div className="login">
      {isMobile && (
        <>
          <motion.div
            animate={{ opacity: 0, zIndex: 0 }}
            className="flex items-center justify-center login__loading"
            transition={{ duration: 0.4, delay: 2 }}
          >
            <div className="text-center">
              <Logo height={80} isWhite width={80} />
              <h2>ombreapp</h2>
            </div>
          </motion.div>
          <motion.div
            animate={{ opacity: 1 }}
            className="login-mobile"
            initial={{ opacity: 0 }}
            style={{
              backgroundImage: `url(${`${process.env.PUBLIC_URL}/images/ombrea-login.webp`})`
            }}
            transition={{ duration: 0.4, delay: 2 }}
          >
            <div className="flex justify-center login-mobile__header">
              <div className="flex flex-col justify-center ">
                <div className="text-center">
                  <Logo height={52} isWhite width={52} />
                </div>
                <div className="text-center">ombreapp</div>
              </div>
            </div>

            <div className="login-mobile__block">
              <div className="login-mobile__container">
                <div className="login-mobile__title mb-3">{t('LOGIN.TITLE')}</div>
                {isErrorLogin && (
                  <div className="my-2">
                    <Alert
                      icon={<IconAlertOctagon />}
                      label={t('LOGIN.ERRORS.WRONG_USER_PASSWORD')}
                      title={t('LOGIN.ERRORS.TITLE')}
                      type="error"
                    />
                  </div>
                )}
                <form className="form" data-testid="login-form" noValidate onSubmit={handleSubmit}>
                  <div className="form__control">
                    <Input
                      label={t('LOGIN.EMAIL')}
                      type="text"
                      {...getFieldProps('email')}
                      error={errors.email}
                      touched={touched.email}
                    />
                  </div>

                  <div className="form-control">
                    <Input
                      label={t('LOGIN.PASSWORD')}
                      type="password"
                      {...getFieldProps('password')}
                      error={errors.password}
                      touched={touched.password}
                    />
                  </div>

                  <div className="login-mobile__forget text-right">
                    <Button className="px-0" htmlType="button" onClick={() => navigate('/reset-password')} type="link">
                      {t('LOGIN.BTN_RECOVERY')}
                    </Button>
                  </div>

                  <div className="form-control">
                    <Button block data-testid="btn-submit" htmlType="submit" type="primary">
                      {t('LOGIN.BTN_LOGIN')}
                    </Button>
                  </div>
                </form>
              </div>
              <div className="login-mobile__footer ">
                <div className="login-mobile__footer-questions mb-2">{t('LOGIN.FOOTER.QUESTIONS')}</div>
                <div className="login-mobile__footer-contact">{t('LOGIN.FOOTER.CONTACT_US')}</div>
              </div>
            </div>
          </motion.div>
        </>
      )}

      {!isMobile && (
        <div className="flex login-desktop">
          <div className="login-desktop__container w-col-50">
            <div className="login-desktop__header">
              <div className="flex items-center">
                <Logo className="mr-1" /> ombreapp
              </div>
            </div>
            <div className="flex items-center justify-center login-desktop__form">
              <div className="w-col-70">
                <div className="mb-4">
                  <h2 className=" login-desktop__form-title">{t('LOGIN.CONTAINER.TITLE')}</h2>
                  <div className=" login-desktop__form-subtitle">{t('LOGIN.CONTAINER.SUBTITLE')}</div>
                </div>
                <div className="">
                  {isErrorLogin && (
                    <div className="my-2">
                      <Alert
                        icon={<IconAlertOctagon />}
                        label={t('LOGIN.ERRORS.WRONG_USER_PASSWORD')}
                        title={t('LOGIN.ERRORS.TITLE')}
                        type="error"
                      />
                    </div>
                  )}
                  <form className="form" data-testid="login-form" noValidate onSubmit={handleSubmit}>
                    <div className="form__control">
                      <Input
                        label={t('LOGIN.EMAIL')}
                        type="text"
                        {...getFieldProps('email')}
                        error={errors.email}
                        touched={touched.email}
                      />
                    </div>

                    <div className="form-control">
                      <Input
                        label={t('LOGIN.PASSWORD')}
                        type="password"
                        {...getFieldProps('password')}
                        error={errors.password}
                        touched={touched.password}
                      />
                    </div>

                    <div className="login-mobile__forget text-right">
                      <Button className="px-0" htmlType="button" onClick={() => navigate('/reset-password')} type="link">
                        {t('LOGIN.BTN_RECOVERY')}
                      </Button>
                    </div>

                    <div className="form-control">
                      <Button block data-testid="btn-submit" htmlType="submit" type="primary">
                        {t('LOGIN.BTN_LOGIN')}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="login-desktop__footer">
              <div className="login-desktop__footer-questions mb-2">{t('LOGIN.FOOTER.QUESTIONS')}</div>
              <div className="login-desktop__footer-contact">{t('LOGIN.FOOTER.CONTACT_US')}</div>
            </div>
          </div>
          <div className="login-desktop__wallpaper w-col-50" />
          <img alt="wallpaper-ombrea" className="wallpaper-img" src={wallpaperImg} />
        </div>
      )}
    </div>
  );
};

export default Login;
