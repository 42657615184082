import { useQuery } from 'react-query';

import { localStorageService, plotService } from 'services';

/**
 * Hook to get all plots by React Query as key "Plots"
 *
 * @export
 * @param {number} plotId
 * @return {*}
 */
export default function useQueryPlotSelected(plotId: number) {
  return useQuery(['PlotSelected', plotId], () => plotService.getPlot(plotId as number), {
    onSuccess: () => {
      localStorageService.setItem('plotId', plotId);
    },
    enabled: !!plotId
  });
}
