import { Icon } from './icon.interface';

const IconRefresh = ({ height = 24, width = 24, className, style }: Icon) => (
  <svg
    className={className}
    fill="none"
    height={height}
    style={style}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m18.882 7.006.374-1.316a.944.944 0 0 1 .434-.565.837.837 0 0 1 .68-.07.909.909 0 0 1 .526.466c.11.224.134.486.065.728l-1.042 3.656a.944.944 0 0 1-.434.564.837.837 0 0 1-.678.07l-3.409-1.118a.87.87 0 0 1-.313-.172.942.942 0 0 1-.227-.29 1.006 1.006 0 0 1-.07-.737.981.981 0 0 1 .168-.333.907.907 0 0 1 .274-.237.846.846 0 0 1 .69-.058l1.49.488A6.74 6.74 0 0 0 14.751 5.6a6.189 6.189 0 0 0-3.47-.655 6.323 6.323 0 0 0-3.288 1.36 7.01 7.01 0 0 0-2.16 2.986l-.233.605a.968.968 0 0 1-.188.321.892.892 0 0 1-.29.218.84.84 0 0 1-.693.007.889.889 0 0 1-.294-.21.964.964 0 0 1-.194-.317 1.017 1.017 0 0 1 .011-.743l.232-.605a8.91 8.91 0 0 1 2.74-3.79 8.038 8.038 0 0 1 4.171-1.732 7.864 7.864 0 0 1 4.406.821 8.561 8.561 0 0 1 3.38 3.141ZM5.052 17.15l-.292 1.253a.99.99 0 0 1-.144.345.92.92 0 0 1-.257.258.854.854 0 0 1-.684.114.865.865 0 0 1-.318-.162.938.938 0 0 1-.235-.283 1.003 1.003 0 0 1-.09-.735l.861-3.708a.987.987 0 0 1 .155-.349.913.913 0 0 1 .271-.254.848.848 0 0 1 .702-.082l3.41.912a.9.9 0 0 1 .549.435c.121.218.158.478.1.724a.953.953 0 0 1-.405.588.84.84 0 0 1-.675.108l-1.59-.424a6.734 6.734 0 0 0 2.773 2.56 6.18 6.18 0 0 0 3.62.574 6.372 6.372 0 0 0 3.345-1.588 7.106 7.106 0 0 0 2.038-3.259.978.978 0 0 1 .168-.326.905.905 0 0 1 .272-.233.848.848 0 0 1 .68-.061c.111.038.215.1.304.18a.94.94 0 0 1 .217.292 1.01 1.01 0 0 1 .057.73 9.136 9.136 0 0 1-1.82 3.356 8.378 8.378 0 0 1-2.974 2.216 7.834 7.834 0 0 1-5.54.302c-1.825-.578-3.411-1.806-4.497-3.482Z"
      fill="currentColor"
    />
  </svg>
);

export default IconRefresh;
