import { HTMLAttributes, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { DropdownMenu, DropdownMenuItem, Modal } from 'components';
import { IconMore } from 'components/Icons';
import { IShadepanelSensorPositionWidget, IValuesDistances } from 'interfaces/shadepanelInterface';
import { roundedNumber } from 'pipes';
import { iconService } from 'services';

import '../DataWidget.scss';
import './SensorPositionWidget.scss';

export interface ISensorWidgetProps extends HTMLAttributes<HTMLDivElement> {
  widget: IShadepanelSensorPositionWidget;
  onDelete?: (widgetId: number) => void;
}

const SensorPositionWidget = ({ widget, onDelete }: ISensorWidgetProps) => {
  const { t } = useTranslation();

  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onDeleteHandler = () => {
    if (onDelete) onDelete(widget.id);
    setIsOpenDropdownMenu(!isOpenDropdownMenu);
  };

  const generateArrayDistances = (distances: IValuesDistances) =>
    Object.keys(distances).map((key) => ({ label: key, values: distances[key] }));

  const checkHaveInOnValues = () =>
    generateArrayDistances(widget.data.distances)[0].values.out || generateArrayDistances(widget.data.distances)[0].values.in;

  const generateEmptyDistancesHeight = (): number => {
    const distances = widget.data.distances ? generateArrayDistances(widget.data.distances).length : 1;
    const heightForDistance = 62;
    return distances * heightForDistance;
  };

  return (
    <div className="datawidget datawidget--positions">
      <div className="datawidget-sensor">
        <div className="datawidget__header flex justify-between">
          <div className="content-center flex items-center">
            <div>
              <div
                className={classNames(
                  `datawidget__icon datawidget__icon--${
                    iconService.generateIconWidget(widget.icon).className
                  } flex  items-center justify-center`
                )}>
                {iconService.generateIconWidget(widget.icon).template}
              </div>
            </div>
            <div>
              <h4 className="datawidget__title">{widget.label}</h4>
              {widget.sector && (
                <div className="datawidget__label">
                  {t('COMPONENTS.WIDGETS.SECTOR')} {widget.sector}
                </div>
              )}
            </div>
          </div>
          <div className="datawidget__more">
            <DropdownMenu element={<IconMore height={20} width={20} />} isOpen={isOpenDropdownMenu} position="right">
              <DropdownMenuItem>
                {onDelete && (
                  <button onClick={() => onDeleteHandler()} type="button">
                    {t('COMPONENTS.WIDGETS.DELETE')}
                  </button>
                )}
              </DropdownMenuItem>
            </DropdownMenu>
          </div>
        </div>
        <div className="datawidget__body ">
          {Object.keys(widget.data).length !== 0 && checkHaveInOnValues() ? (
            <div className="flex justify-center">
              {typeof generateArrayDistances(widget.data.distances)[0].values.out === 'number' && (
                <div className="w-col-50">
                  <div className="datawidget-sensor__label">{t('DATA_TYPE.POSITION.out')}</div>
                  <div className="datawidget-sensor__value">
                    {generateArrayDistances(widget.data.distances).map((distance, key) => (
                      <div className="distances flex" key={uuidv4()}>
                        <div className="distances__value">
                          <div className="distances__value  flex items-center justify-center">{distance.label}cm</div>
                        </div>
                        <div
                          className={classNames('distances__line flex items-center', {
                            'distances__line--first': key === 0,
                            'distances__line--last': key === generateArrayDistances(widget.data.distances).length - 1
                          })}>
                          <div className="line-inside" />
                        </div>
                        <div className="distances__value flex items-center justify-center">
                          {roundedNumber(distance.values.out, 1)}
                          {widget.data.unit}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {typeof generateArrayDistances(widget.data.distances)[0].values.in === 'number' && (
                <div className="w-col-50">
                  <div className="datawidget-sensor__label">{t('DATA_TYPE.POSITION.in')}</div>
                  <div className="datawidget-sensor__value">
                    {generateArrayDistances(widget.data.distances).map((distance, key) => (
                      <div className="distances flex" key={uuidv4()}>
                        <div className="distances__value">
                          <div className="distances__value  flex items-center justify-center">{distance.label}cm</div>
                        </div>
                        <div
                          className={classNames('distances__line flex items-center', {
                            'distances__line--first': key === 0,
                            'distances__line--last': key === generateArrayDistances(widget.data.distances).length - 1
                          })}>
                          <div className="line-inside" />
                        </div>
                        <div className="distances__value flex items-center justify-center">
                          {roundedNumber(distance.values.in, 1)}
                          {widget.data.unit}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className="datawidget__empty flex items-center justify-center "
              style={{ height: generateEmptyDistancesHeight() }}>
              {t('COMPONENTS.WIDGET_LIST.EMPTY.TITLE')}
            </div>
          )}
        </div>

        <Modal isOpen={isOpenModal} onToggleModal={() => setIsOpenModal(!isOpenModal)} title={widget.reference} width={600}>
          <div className="note-modal" data-testid="note-modal">
            <div className="my-3">
              <div className="note-modal__title">{t('COMPONENTS.WIDGETS.MODAL.TITLE')}</div>
              <p className="note-modal__content">Description</p>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default SensorPositionWidget;
