import { useTranslation } from 'react-i18next';

import AlertParametersItem from './AlertParametersItem';

import './AlertParameters.scss';

const AlertParameters = () => {
  const { t } = useTranslation();

  return (
    <div className="alert-parameters">
      <h4>{t('ALERTS.LIST.TITLE')}</h4>
      <div className="alert-parameters__list gap-2 grid grid-cols-1">
        {/* TODO: map for AlertParametersItems, need to pass alert o alertId for UPDATE alert */}
        <AlertParametersItem title="Default" />
        <AlertParametersItem isEditable title="Gel" />
      </div>
    </div>
  );
};

export default AlertParameters;
