import { useQuery } from 'react-query';

import { IWeatherData } from 'interfaces/weatherInterface';
import { weatherService } from 'services';

/**
 * Hook to get weather data by React Query as key "PlotWeatherProviders"
 *
 * @export
 * @return {*}
 */
export default function useQueryWeatherData(provider: string, location: number[]) {
  return useQuery<IWeatherData>(['WeatherData'], () =>
    weatherService.getWeatherData(provider, { longitude: location[0], latitude: location[1] })
  );
}
