import { Icon } from './icon.interface';

const IconEmail = ({ height = 24, width = 24, className = '' }: Icon) => (
  <svg
    className={`${className}`}
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.363 8.931c.017.428.396.762.848.746.452-.015.804-.373.788-.8l-1.636.054Zm-3.857-4.155v.774h.027l-.027-.774Zm-7.012 0-.027.774h.027v-.774ZM3 8.876c-.015.428.338.786.79.801.451.016.83-.318.846-.746L3 8.877Zm18 .029c0-.428-.367-.775-.819-.775-.45 0-.818.347-.818.775H21Zm-.819 6.191.818.028v-.028h-.818Zm-4.675 4.128.027-.773-.027-.001v.774Zm-7.012 0v-.774h-.027l.027.774Zm-4.676-4.128H3v.028l.818-.028Zm.819-6.191c0-.428-.367-.775-.819-.775-.451 0-.818.347-.818.775h1.637Zm15.957.667a.751.751 0 0 0 .294-1.058c-.228-.37-.729-.493-1.12-.278l.826 1.336Zm-6.155 2.506-.412-.668-.007.003.42.665Zm-4.878 0 .419-.665-.006-.003-.413.668Zm-5.33-3.842c-.39-.215-.89-.091-1.119.278a.751.751 0 0 0 .294 1.058l.825-1.336Zm16.768.64c-.105-2.786-2.574-4.966-5.52-4.873l.054 1.547c2.045-.065 3.758 1.448 3.83 3.381L21 8.877Zm-5.493-4.874H8.494V5.55h7.012V4.002Zm-6.984 0C5.575 3.91 3.106 6.09 3 8.878l1.636.054c.073-1.933 1.786-3.446 3.83-3.38l.056-1.548Zm10.841 4.903v6.191H21V8.905h-1.637Zm0 6.164c-.072 1.933-1.785 3.446-3.83 3.382l-.054 1.546c2.946.093 5.415-2.087 5.52-4.873l-1.636-.056Zm-3.857 3.38H8.494v1.549h7.012V18.45Zm-7.04.002c-2.044.064-3.757-1.45-3.83-3.383L3 15.124c.106 2.786 2.575 4.966 5.522 4.873l-.055-1.546Zm-3.83-3.355V8.905H3v6.191h1.637Zm15.133-6.86-5.742 3.174.825 1.336 5.742-3.174-.825-1.336Zm-5.75 3.177a4.132 4.132 0 0 1-4.039 0l-.839 1.329a5.848 5.848 0 0 0 5.718 0l-.84-1.329Zm-4.045-.003L4.23 8.236l-.825 1.336 5.742 3.174.826-1.336Z"
      fill="currentColor"
    />
  </svg>
);

export default IconEmail;
