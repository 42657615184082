import { Icon } from './icon.interface';

const IconWeatherThunderStorm = ({ height = 100, width = 100 }: Icon) => (
  <svg fill="none" height={height} viewBox="0 0 100 100" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.2018 69.1884L48.4303 60.9565H57.285L55.7427 67.481C55.5614 68.248 56.1432 68.9833 56.9312 68.9833C57.9004 68.9833 58.4834 70.058 57.955 70.8704L48.1141 86L51.2306 74.1345C51.5735 72.8288 50.5886 71.5518 49.2387 71.5518C47.9803 71.5518 47.0161 70.4331 47.2018 69.1884Z"
      fill="url(#paint0_linear_2583_20135)"
    />
    <path
      clipRule="evenodd"
      d="M69.7264 63.5145H35.8899V63.4888C23.1514 62.8968 13 52.0505 13 38.7571C13 25.0841 23.7395 14 36.9873 14C47.6057 14 56.6127 21.1209 59.7698 30.9882C62.5875 28.9062 66.0333 27.6819 69.7543 27.6819C79.2789 27.6819 87.0001 35.7033 87.0001 45.5982C87.0001 55.275 79.6155 63.16 70.3814 63.5029V63.5145H69.7822C69.7729 63.5145 69.7636 63.5146 69.7543 63.5146C69.745 63.5146 69.7357 63.5145 69.7264 63.5145Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M70.3814 63.5029C79.6155 63.16 87 55.275 87 45.5982C87 35.7033 79.2788 27.6819 69.7542 27.6819C60.4396 27.6819 52.8497 35.3535 52.5196 44.9466H35.8899V63.5145H69.7021C69.7195 63.5145 69.7368 63.5146 69.7542 63.5146C69.7716 63.5146 69.789 63.5145 69.8064 63.5145H70.3814V63.5029Z"
      fill="white"
      fillRule="evenodd"
    />
    <ellipse cx="28.1565" cy="71.6819" fill="#4E8DF5" rx="2.89063" ry="4.46451" />
    <ellipse cx="37.792" cy="76.5732" fill="#4E8DF5" rx="2.89063" ry="4.46451" />
    <ellipse fill="#4E8DF5" rx="2.89063" ry="4.46451" transform="matrix(-1 0 0 1 80.9587 68.5515)" />
    <ellipse fill="#4E8DF5" rx="2.89063" ry="4.46451" transform="matrix(-1 0 0 1 71.323 73.4427)" />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2583_20135"
        x1="54.5575"
        x2="53.8051"
        y1="46.8696"
        y2="77.6516"
      >
        <stop stopColor="#E2BC38" />
        <stop offset="1" stopColor="#FFE244" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconWeatherThunderStorm;
