import { Icon } from './icon.interface';

const IconWeatherSnow = ({ height = 100, width = 100 }: Icon) => (
  <svg fill="none" height={height} viewBox="0 0 100 100" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.7986 65.9767V34.025L60.4888 25.9767C60.4888 25.9767 61.7614 24.7983 60.4888 23.6183C59.2162 22.44 57.9437 23.6183 57.9437 23.6183L51.7986 29.31V21.6667C51.7986 21.6667 51.7986 20 49.9987 20C48.1987 20 48.1987 21.6667 48.1987 21.6667V29.31L42.0537 23.6183C42.0537 23.6183 40.7811 22.44 39.5086 23.6183C38.236 24.7983 39.5086 25.9767 39.5086 25.9767L48.1987 34.025V65.9767L39.5086 74.0233C39.5086 74.0233 38.236 75.2017 39.5086 76.38C40.7811 77.5583 42.0537 76.38 42.0537 76.38L48.1987 70.69V78.3333C48.1987 78.3333 48.1987 80 49.9987 80C51.7986 80 51.7986 78.3333 51.7986 78.3333V70.69L57.9437 76.38C57.9437 76.38 59.2162 77.5583 60.4888 76.38C61.7614 75.2017 60.4888 74.0233 60.4888 74.0233L51.7986 65.9767Z"
      fill="#88C9F9"
    />
    <path
      d="M79.9174 54.7767C79.4512 53.1667 77.7125 53.5984 77.7125 53.5984L65.8418 56.5434L35.9573 40.5667L32.7767 29.575C32.7767 29.575 32.3106 27.965 30.5718 28.3967C28.8313 28.8284 29.2992 30.4384 29.2992 30.4384L31.5492 38.2117L24.3998 34.3884C24.3998 34.3884 22.841 33.555 21.941 34.9984C21.0411 36.4417 22.5998 37.275 22.5998 37.275L29.7474 41.0967L21.3525 43.1784C21.3525 43.1784 19.6119 43.61 20.0799 45.22C20.5461 46.8317 22.2848 46.4 22.2848 46.4L34.1573 43.455L64.0419 59.4317L67.2224 70.425C67.2224 70.425 67.6886 72.035 69.4273 71.6034C71.1661 71.17 70.6999 69.5617 70.6999 69.5617L68.4499 61.7884L75.5993 65.61C75.5993 65.61 77.1599 66.4434 78.0599 65.0017C78.9598 63.5567 77.3993 62.7234 77.3993 62.7234L70.2517 58.9017L78.6449 56.82C78.6449 56.8184 80.3836 56.3867 79.9174 54.7767Z"
      fill="#88C9F9"
    />
    <path
      d="M78.6448 43.1784L70.2498 41.095L77.3992 37.2734C77.3992 37.2734 78.9598 36.4384 78.0598 34.995C77.158 33.55 75.5992 34.385 75.5992 34.385L68.4517 38.205L70.6998 30.4334C70.6998 30.4334 71.166 28.8234 69.4272 28.3917C67.6885 27.96 67.2223 29.57 67.2223 29.57L64.0418 40.5617L34.1554 56.5434L22.2865 53.5967C22.2865 53.5967 20.5478 53.165 20.0816 54.775C19.6136 56.3867 21.3542 56.8167 21.3542 56.8167L29.7473 58.8984L22.5997 62.72C22.5997 62.72 21.0428 63.555 21.9428 64.9984C22.8427 66.44 24.3997 65.6067 24.3997 65.6067L31.5491 61.785L29.2992 69.5567C29.2992 69.5567 28.833 71.1684 30.5717 71.5984C32.3123 72.0317 32.7785 70.4217 32.7785 70.4217L35.9608 59.4267L65.8471 43.4517L77.7178 46.3984C77.7178 46.3984 79.4565 46.83 79.9227 45.22C80.3817 43.6084 78.6448 43.1784 78.6448 43.1784Z"
      fill="#88C9F9"
    />
  </svg>
);

export default IconWeatherSnow;
