import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button, Input, InputNumber } from 'components';
// import { IDropdownOption } from 'components/Dropdown/Dropdown';
import { IconClose } from 'components/Icons';
// import { IPlotMeasureAlerts } from 'interfaces/plotInterface';
// import { plotMock } from 'mocks';

import './AlertCreate.scss';

const AlertCreate = () => {
  const { t } = useTranslation();
  // const { plots } = plotMock;

  const {
    errors,
    touched,
    setFieldValue,
    getFieldProps,
    values: formValues,
    setValues
  } = useFormik({
    initialValues: {
      name: '',
      plots: '',
      start_datetime: '',
      end_datetime: '',
      criteria: [{ metric: '', metric_id: 0, operation: '', limit: 0 }]
    },
    onSubmit: (values) => {
      // eslint-disable-next-line no-console
      console.log(values);
    },
    validationSchema: Yup.object({
      name: Yup.string().required('required'),
      criteria: Yup.array().of(
        Yup.object().shape({
          metric: Yup.string().required('required'),
          metric_id: Yup.number().required('required'),
          operation: Yup.string().required('required'),
          limit: Yup.number().required('required')
        })
      )
    })
  });

  // const operations = [
  //   {
  //     value: '>',
  //     label: 'Supérieure à '
  //   }
  // ];

  // TODO: Delete mock
  // const plotMeasureAlerts: IPlotMeasureAlerts[] = plotMock.plotMeasureAlertsMock;

  // const onChangePlot = (plot: IDropdownOption) => {
  //   if (!plot) return;
  //   setFieldValue('plotId', plot.value);
  // };

  const onChangeLimit = (limit: number, index: number) => {
    if (!limit) return;
    formValues.criteria[index] = { ...formValues.criteria[index], limit: limit as number };
    setValues({ ...formValues });
  };

  // const onChangeCriteriaIndicator = (criteriaOption: IDropdownOption, index: number) => {
  //   formValues.criteria[index] = { ...formValues.criteria[index], metric_id: criteriaOption.value as number };
  //   setValues({ ...formValues });
  // };

  // const onChangeOperator = (operator: IDropdownOption, index: number) => {
  //   formValues.criteria[index] = { ...formValues.criteria[index], operation: operator.value as string };
  //   setValues({ ...formValues });
  // };

  const addCriteria = () => {
    setFieldValue('criteria', [...formValues.criteria, { metric: '', metric_id: 0, operation: '', limit: 0 }]);
  };

  const removeCriteria = (index: number) => {
    formValues.criteria.splice(index, 1);
    setValues({ ...formValues });
  };

  return (
    <div className="alert-create">
      <h3 className="alert-create__title mb-4">{t('ALERTS.CREATE.TITLE')}</h3>
      <form noValidate>
        <div className="gap-4 grid grid-cols-1 mb-4">
          <div>
            <Input
              error={errors.name}
              label={t('ALERTS.CREATE.FORM.NAME')}
              touched={touched.name}
              {...getFieldProps('name')}
              type="text"
            />
          </div>

          <div>
            {/* {plots && (
              <Dropdown
                bindLabel="name"
                bindValue="id"
                items={plots}
                label={t('ALERTS.CREATE.FORM.PLOT')}
                onSelectOption={onChangePlot}
                selectFirstOption
              />
            )} */}
            {errors.plots && <div className="alert-create__error">{t(errors.plots)}</div>}
          </div>

          {formValues.criteria.map((criteria, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="criteria gap-4 grid grid-cols-1" key={index}>
              <div className="flex items-center justify-between">
                <h4 className="criteria__title m-0 mb-1">
                  {t('ALERTS.CREATE.FORM.CRITERIA.TITLE')} {index + 1}
                </h4>
                <button className="criteria__remove" onClick={() => removeCriteria(index)} type="button">
                  <IconClose height={18} width={18} />
                </button>
              </div>
              <div>
                {/* <Dropdown
                  bindLabel="name"
                  bindValue="ref_id"
                  items={plotMeasureAlerts}
                  label={t('ALERTS.CREATE.FORM.CRITERIA.SENSOR')}
                  onSelectOption={(option) => onChangeCriteriaIndicator(option, index)}
                /> */}
              </div>

              <div className="gap-4 grid grid-cols-2 sm:grid-cols-1">
                {/* <Dropdown
                  bindLabel="label"
                  bindValue="value"
                  items={operations}
                  label={t('ALERTS.CREATE.FORM.CRITERIA.OPERATOR')}
                  onSelectOption={(option) => onChangeOperator(option, index)}
                /> */}
                <InputNumber
                  error={errors.name}
                  label={t('ALERTS.CREATE.FORM.CRITERIA.LIMIT')}
                  min={1}
                  name={`name-[${index}]`}
                  onChangeNumber={(limit) => onChangeLimit(limit, index)}
                  touched={touched.name}
                  type="text"
                />
              </div>
            </div>
          ))}

          <div className="">
            <Button block htmlType="button" onClick={() => addCriteria()} type="ghost">
              {t('ALERTS.CREATE.FORM.BTN_ADD')}
            </Button>
          </div>
        </div>

        <Button block htmlType="submit" type="primary">
          {t('ALERTS.CREATE.FORM.BTN_SAVE')}
        </Button>
      </form>
    </div>
  );
};

export default AlertCreate;
