import { HTMLAttributes, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Modal, Skeleton } from 'components';
import { useQueryShadepanelSensorMeasure } from 'hooks';
import { roundedNumber } from 'pipes';
import { iconService } from 'services';

import '../../DataWidget/DataWidget.scss';

export interface IDataSensorMeasureProps extends HTMLAttributes<HTMLDivElement> {
  refId: number;
  shadepanelId: number;
  refetch?: boolean;
  sector?: number;
}

const DataSensorMeasure = ({ refId, shadepanelId, refetch = false, sector }: IDataSensorMeasureProps) => {
  const { t } = useTranslation();

  const {
    data: sensor,
    refetch: refetchSensor,
    isLoading
  } = useQueryShadepanelSensorMeasure(shadepanelId, refId, sector as number);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const checkHaveInOnValues = () => {
    if (sensor) {
      return typeof sensor.data.out === 'number' || typeof sensor.data.in === 'number';
    }
    return null;
  };

  useEffect(() => {
    refetchSensor();
  }, [refetch]);

  return (
    <>
      {isLoading && <Skeleton count={1} style={{ height: 143, width: '100%', borderRadius: 8 }} />}
      {!isLoading && sensor && (
        <div className="datawidget">
          <div className="datawidget-sensor">
            <div className="datawidget__header flex justify-between">
              <div className="content-center flex items-center">
                <div>
                  <div
                    className={classNames(
                      `datawidget__icon datawidget__icon--${
                        iconService.generateIconWidget(sensor.icon).className
                      } flex  items-center justify-center`
                    )}>
                    {iconService.generateIconWidget(sensor.icon).template}
                  </div>
                </div>
                <div>
                  <h4 className="datawidget__title">{sensor.label}</h4>
                  {sensor.sector && (
                    <div className="datawidget__label">
                      {t('COMPONENTS.WIDGETS.SECTOR')} {sensor.sector}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="datawidget__body flex">
              {checkHaveInOnValues() ? (
                <>
                  {typeof sensor.data.out === 'number' && (
                    <div className="w-col-50">
                      <div className="datawidget-sensor__label">{t('DATA_TYPE.POSITION.out')}</div>
                      <div className="datawidget-sensor__value">
                        {roundedNumber(sensor.data.out, 1)}
                        {sensor.unit}
                      </div>
                    </div>
                  )}

                  {typeof sensor.data.in === 'number' && (
                    <div className="w-col-50">
                      <div className="datawidget-sensor__label">{t('DATA_TYPE.POSITION.in')}</div>
                      <div className="datawidget-sensor__value">
                        {roundedNumber(sensor.data.in, 1)}
                        {sensor.unit}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="datawidget__empty flex items-center">{t('COMPONENTS.WIDGET_LIST.EMPTY.TITLE')}</div>
              )}
            </div>

            <Modal isOpen={isOpenModal} onToggleModal={() => setIsOpenModal(!isOpenModal)} title={sensor.label} width={600}>
              <div className="note-modal" data-testid="note-modal">
                <div className="my-3">
                  <div className="note-modal__title">{t('COMPONENTS.WIDGETS.MODAL.TITLE')}</div>
                  <p className="note-modal__content">{sensor.name}</p>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default DataSensorMeasure;
