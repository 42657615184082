import './Spinner.scss';

export type ISpinner = React.HTMLAttributes<HTMLDivElement>;

const Spinner = ({ style }: ISpinner) => (
  <div className="flex items-center justify-center" style={style}>
    <div className="spinner" data-testid="spinner" />
  </div>
);

export default Spinner;
