import { HTMLAttributes, useState } from 'react';

import { Skeleton } from 'components';
import { IconArrowLeft, IconArrowRight } from 'components/Icons';
import { useQueryWeatherData } from 'hooks';

import WeatherHourGrid from '../WeatherHourGrid/WeatherHourGrid';

import './WeatherSlider.scss';

export interface IWeatherSliderProps extends HTMLAttributes<HTMLDivElement> {
  showOnly?: number;
  provider: string;
  location: number[];
}

/**
 *
 *
 * @param {IWeatherSliderProps} { days, showOnly = 4, className }
 * @return {*}  {JSX.Element}
 */
const WeatherSlider = ({ className = '', showOnly = 4, provider, location }: IWeatherSliderProps): JSX.Element => {
  const [initialIndex, setInitialIndex] = useState(0);

  const { data: weatherData, isLoading: isLoadingWeatherData } = useQueryWeatherData(provider, location);

  const moveDays = (direction: 'left' | 'right') => {
    const newIndex = initialIndex < 0 ? 0 : initialIndex;
    if (weatherData) {
      const daysLengthIndex = weatherData.forecast.length;
      const maxLimit = daysLengthIndex - showOnly;
      // If is plus than zero, the can go right
      if (newIndex >= 0) {
        // If NOT have max limint cna fo right
        if (maxLimit !== newIndex) {
          if (direction === 'right') {
            setInitialIndex(initialIndex + 1);
          }
        }
      }
      // if is plus than 1, the can go left
      if (newIndex >= 1) {
        if (direction === 'left') {
          setInitialIndex(initialIndex - 1);
        }
      }
    }
  };

  return isLoadingWeatherData ? (
    <Skeleton style={{ width: 'auto', height: 274 }} />
  ) : (
    <div className={`${className} weather-slider`}>
      <button
        className="flex items-center justify-center weather-slider__control weather-slider__control--left"
        onClick={() => moveDays('left')}
        type="button"
      >
        <IconArrowLeft height={20} width={20} />
      </button>
      <button
        className="flex items-center justify-center  weather-slider__control weather-slider__control--right"
        onClick={() => moveDays('right')}
        type="button"
      >
        <IconArrowRight height={20} width={20} />
      </button>
      {weatherData && <WeatherHourGrid forecast={weatherData.forecast} initialIndex={initialIndex} showOnly={showOnly} />}
    </div>
  );
};

export default WeatherSlider;
