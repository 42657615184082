import i18n from 'i18next';
import LanguaDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import fr from './fr.json';

const resources = {
  fr,
  en
} as const;

i18n
  .use(LanguaDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: 'fr',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources
  });

export default i18n;
