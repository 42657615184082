import { API_ROUTES } from 'services/api/urls';

import { AuthToken, LocalToken, ResetPasswordResponse, ResetPasswordTokenDto } from '../../interfaces/authInterface';
import axiosInstance from '../interceptor';

export const setLocalToken = (localToken: string): void => {
  localStorage.removeItem(LocalToken.TOKEN);
  localStorage.setItem(LocalToken.TOKEN, localToken);
};

export const setLocalRefresh = (refreshToken: string): void => {
  localStorage.removeItem(LocalToken.REFRESH);
  localStorage.setItem(LocalToken.REFRESH, refreshToken);
};

export const getLocalToken = (): string => localStorage.getItem(LocalToken.TOKEN) || '';
export const getLocalRefresh = (): string => localStorage.getItem(LocalToken.REFRESH) || '';

export const deleteLocalToken = (): void => {
  localStorage.removeItem(LocalToken.TOKEN);
  localStorage.removeItem(LocalToken.REFRESH);
  localStorage.removeItem('plotId');
  localStorage.removeItem('shadepanelId');
};

/**
 * Login user
 *
 * @param {string} email
 * @param {string} password
 * @param {string} permission
 * @return {*}  {Promise<AuthToken>}
 */
export const login = async (email: string, password: string, permission: string): Promise<AuthToken> => {
  const loginResponse = await axiosInstance.post<AuthToken>(API_ROUTES.login(), { email, password, permission });
  setLocalToken(loginResponse.data.access);
  setLocalRefresh(loginResponse.data.refresh);
  return loginResponse.data;
};

export const logout = (): void => {
  deleteLocalToken();
};

/**
 * Reset Password
 *
 * @param {string} email
 * @return {*}  {Promise<ResetPasswordResponse>}
 */
export const resetPassword = async (email: string): Promise<ResetPasswordResponse> => {
  const resetPasswordResponse = await axiosInstance.post<ResetPasswordResponse>(API_ROUTES.resetLink(), { email });
  return resetPasswordResponse.data;
};

/**
 * Reset Password Token - Change password
 *
 * @param {string} password
 * @param {string} password_repeat
 * @return {*}  {Promise<ResetPasswordResponse>}
 */
export const resetPasswordToken = async (values: ResetPasswordTokenDto): Promise<ResetPasswordResponse> => {
  const resetPasswordResponse = await axiosInstance.post<ResetPasswordResponse>(API_ROUTES.resetPassword(), values);
  return resetPasswordResponse.data;
};

/**
 * Active account
 *
 * @param {string} password
 * @param {string} password_repeat
 * @return {*}  {Promise<ResetPasswordResponse>}
 */
export const activeAccount = async (values: ResetPasswordTokenDto): Promise<ResetPasswordResponse> => {
  const resetPasswordResponse = await axiosInstance.post<ResetPasswordResponse>(API_ROUTES.activationUser(), values);
  return resetPasswordResponse.data;
};
