import { useTranslation } from 'react-i18next';

import { IconFileImage, IconFilePDF, IconTrash } from 'components/Icons';

import './FileItem.scss';

export interface IFileItemProps {
  type: string;
  fileIndex?: number;
  name: string;
  isNew?: boolean;
  canRemove?: boolean;
  onRemove?: (fileIndex: number) => void;
}

type ListIcons = {
  [key: string]: JSX.Element;
};

const FileItem = ({ fileIndex, name, type, isNew = false, canRemove = false, onRemove }: IFileItemProps) => {
  const { t } = useTranslation();

  const generateIcon = (typeIcon: string): JSX.Element => {
    const icons: ListIcons = {
      'image/png': <IconFileImage height={50} width={40} />,
      'image/jpg': <IconFileImage height={50} width={40} />,
      'image/jpeg': <IconFileImage height={50} width={40} />,
      'image/gif': <IconFileImage height={50} width={40} />,
      'application/pdf': <IconFilePDF height={50} width={40} />
    };
    return icons[typeIcon];
  };

  return (
    <div className="file flex items-center justify-between">
      <div className="flex items-center">
        <div className="file__icon">{generateIcon(type)}</div>
        <div>
          {isNew && <div className="file__new">{t('COMPONENTS.FILE.NEW')}</div>}
          <div className="file__name">{name}</div>
        </div>
      </div>
      {canRemove && (
        <button className="file__delete" onClick={() => onRemove && onRemove(fileIndex as number)} type="button">
          <IconTrash height={32} width={32} />
        </button>
      )}
    </div>
  );
};

export default FileItem;
