import { useState } from 'react';

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { t } from 'i18next';
import * as Yup from 'yup';

import { Button, Datepicker, Dropdown, Input, DropFileInput, Textarea } from 'components';
import { IDropdownOption } from 'components/Dropdown/Dropdown';
import { ITabOption } from 'components/Tabs/Tabs';
import { useMutationNote, useQueryPlots, useQueryPlotShadepanels, useQueryTypesAction } from 'hooks';
import { INoteDto } from 'interfaces/noteInterface';

import './NotesCreate.scss';

const NotesCreate = () => {
  // Plots
  const [plotId, setPlotId] = useState<number>();
  const { data: plots } = useQueryPlots();
  const { data: plotShadepanels } = useQueryPlotShadepanels(plotId as number);

  // Shadepanel
  const [shadepanelId, setShadepanelId] = useState<number>();

  // Treatement
  const { data: typesActions } = useQueryTypesAction();

  // Note
  const mutationNote = useMutationNote();

  // Files
  const [filesArray, setFilesArray] = useState<FileList[]>([]);

  const onClearFiles = () => {
    setFilesArray([]);
  };

  const onSubmitNoteHandler = (values: INoteDto) => {
    const formNote = new FormData();
    formNote.append('plot', values.plot.toString());
    formNote.append('shadepanel', values.shadepanel.toString());
    formNote.append('date', values.date);
    formNote.append('title', values.title);
    formNote.append('content', values.content);
    // Type de action on Label
    formNote.append('treatment', values.treatment);

    if (values.files[0]) {
      formNote.append('file', values.files[0] as File);
    }

    mutationNote.mutate({ note: formNote });

    onClearFiles();
  };

  const { errors, touched, getFieldProps, setFieldValue, handleSubmit, isValid, dirty } = useFormik({
    initialValues: {
      plot: '',
      shadepanel: '',
      date: '',
      title: '',
      content: '',
      treatment: '',
      files: []
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      onSubmitNoteHandler(values);
      resetForm();
    },
    validationSchema: Yup.object().shape({
      plot: Yup.number().required(t('NOTES_PAGE.CREATE.FORM.ERRORS.PLOT_REQUIRED')),
      shadepanel: Yup.number().required(t('NOTES_PAGE.CREATE.FORM.ERRORS.SHADEPANEL_REQUIRED')),
      date: Yup.string().required(t('NOTES_PAGE.CREATE.FORM.ERRORS.DATE_REQUIRED')),
      title: Yup.string().required(t('NOTES_PAGE.CREATE.FORM.ERRORS.TITLE_REQUIRED')),
      content: Yup.string(),
      treatment: Yup.string().required(t('NOTES_PAGE.CREATE.FORM.ERRORS.TYPE_ACTION_REQUIRED')),
      files: Yup.array().max(1, t('NOTES_PAGE.CREATE.FORM.ERRORS.FILE_MIN'))
    })
  });

  const onChangeShadowPanel = (shadowPanel: ITabOption) => {
    if (!shadowPanel) return;
    setShadepanelId(shadowPanel.value as number);
    setFieldValue('shadepanel', shadowPanel.value);
  };

  const onChangePlot = (plot: IDropdownOption) => {
    if (!plot) return;
    setPlotId(plot.value as number);
    setFieldValue('plot', plot.value);
  };

  const onChangeFiles = (files: FileList[]) => {
    if (!files) return;
    setFieldValue('files', files);
    setFilesArray(files);
  };

  const onChangeTypeAction = (treatmentOption: IDropdownOption) => {
    if (!treatmentOption) return;
    setFieldValue('treatment', treatmentOption.value);
  };

  const onChangeDate = (date: string) => {
    setFieldValue('date', dayjs(date).format('YYYY-MM-DD'));
  };

  return (
    <div className="notes-create">
      <div className="notes-create__title">{t('NOTES_PAGE.CREATE.TITLE')}</div>
      <form noValidate onSubmit={handleSubmit}>
        <div className="gap-4 grid grid-cols-2 mb-4">
          <div className="sm:col-span-2">
            {plots && (
              <Dropdown
                bindLabel="name"
                bindValue="id"
                items={plots}
                label={t('NOTES_PAGE.CREATE.FORM.PLOT.LABEL')}
                onSelectOption={onChangePlot}
                placeholder={t('NOTES_PAGE.CREATE.FORM.PLOT.PLACEHOLDER')}
                selectFirstOption={false}
              />
            )}
            {touched && errors.plot && <div className="notes-create__error">{t(errors.plot)}</div>}
          </div>
          <div className="sm:col-span-2">
            {plotShadepanels && (
              <Dropdown
                bindLabel="name"
                bindValue="id"
                items={plotShadepanels}
                label={t('NOTES_PAGE.CREATE.FORM.SHADEPANEL.LABEL')}
                onSelectOption={onChangeShadowPanel}
                placeholder={t('NOTES_PAGE.CREATE.FORM.SHADEPANEL.PLACEHOLDER')}
                selectFirstOption={false}
              />
            )}
            {touched && errors.shadepanel && <div className="notes-create__error">{t(errors.shadepanel)}</div>}
          </div>
          <div className="sm:col-span-2">
            {typesActions && (
              <Dropdown
                bindLabel="label"
                bindValue="id"
                items={typesActions}
                label={t('NOTES_PAGE.CREATE.FORM.TYPE_ACTION.LABEL')}
                onSelectOption={onChangeTypeAction}
                placeholder={t('NOTES_PAGE.CREATE.FORM.TYPE_ACTION.PLACEHOLDER')}
                selectFirstOption={false}
              />
            )}
            {touched && errors.treatment && <div className="notes-create__error">{t(errors.treatment)}</div>}
          </div>
          <div className="sm:col-span-2">
            <Datepicker
              disableFuture
              label="Date"
              onChangeDate={(date) => onChangeDate(date)}
              placeholder={t('COMPONENTS.DATEPICKER.PLACEHOLDER')}
            />
            {touched && errors.date && <div className="notes-create__error">{t(errors.date)}</div>}
          </div>
          <div className="col-span-2">
            <Input
              error={errors.title}
              label={t('NOTES_PAGE.CREATE.FORM.TITLE')}
              touched={touched.title}
              {...getFieldProps('title')}
              type="text"
            />
          </div>
          <div className="col-span-2">
            <Textarea
              error={errors.content}
              touched={touched.content}
              {...getFieldProps('content')}
              label={t('NOTES_PAGE.CREATE.FORM.CONTENT')}
            />
          </div>
          <div className="col-span-2">
            <DropFileInput files={filesArray} limitFiles={1} onFileChange={(files) => onChangeFiles(files)} />
            {errors.files && <div className="notes-create__error">{t(errors.files)}</div>}
          </div>
        </div>
        <Button block disabled={!(isValid && dirty)} htmlType="submit" type="primary">
          {t('NOTES_PAGE.CREATE.FORM.BTN_SAVE')}
        </Button>
      </form>
    </div>
  );
};

export default NotesCreate;
