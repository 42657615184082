import { useState } from 'react';

import classNames from 'classnames';
import { t } from 'i18next';

import { Button, Tabs } from 'components';
import { ITabOption } from 'components/Tabs/Tabs';
import { AlertCreate, AlertList, AlertParameters } from 'elements';

import './Alerts.scss';

export interface IAlertsProps {
  isMobile: boolean;
}

const Alerts = ({ isMobile }: IAlertsProps) => {
  const tabs = [
    { id: 'alerts', label: t('ALERTS.TABS.MY_ALERTS') },
    { id: 'parameters', label: t('ALERTS.TABS.PARAMETERS') }
  ];
  const [tabSelected, setTabSelected] = useState<string>('parameters');

  const onChangeTab = (tab: ITabOption) => {
    if (!tab) return;
    setTabSelected(tab.value as string);
  };

  // useEffect(() => {
  //   setTabSelected('alerts');
  // }, [isMobile]);

  return (
    <div
      className={classNames('alerts', {
        'alerts--mobile': isMobile,
        'container-fluid': !isMobile
      })}
    >
      {!isMobile && (
        <>
          <div className="alerts__subheader container-fluid flex">
            <Tabs
              bindLabel="label"
              bindValue="id"
              defaultValue={tabSelected}
              items={tabs}
              onSelectOption={(event) => onChangeTab(event)}
              type="default"
            />
          </div>
          <div>
            {tabSelected === 'alerts' && <AlertList />}
            {tabSelected === 'parameters' && (
              <div className="container-fluid flex">
                <div className="my-4 w-col-60">
                  <AlertParameters />
                </div>
                <div className="w-col-40">
                  <AlertCreate />
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {isMobile && (
        <div className="my-4">
          <Tabs
            bindLabel="label"
            bindValue="id"
            defaultValue={tabSelected}
            items={tabs}
            onSelectOption={(event) => onChangeTab(event)}
          />
          <div>
            {tabSelected === 'alerts' && <AlertList />}
            {tabSelected === 'parameters' && (
              <>
                <AlertParameters />
                <div className="my-2">
                  <Button block htmlType="button" onClick={() => setTabSelected('create-alert')} type="primary">
                    {t('ALERTS.LIST.BTN_CREATE')}
                  </Button>
                </div>
              </>
            )}
            {tabSelected === 'create-alert' && (
              <div className="my-2">
                <AlertCreate />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Alerts;
