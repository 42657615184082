import { useEffect, useState } from 'react';

import { defaultTheme, Provider as ProviderSpectrum, Calendar, View } from '@adobe/react-spectrum';
import { CalendarDate, getLocalTimeZone, parseDate, today } from '@internationalized/date';
import dayjs from 'dayjs';

import { IconCalendar, IconClose } from 'components/Icons';
import { useClickOutside } from 'hooks';

import './Datepicker.scss';

export interface IDatepickerProps {
  allowClear?: boolean;
  defaultValue?: string;
  disableFuture?: boolean;
  label?: string;
  onChangeDate: (date: string) => void;
  placeholder: string;
}

const Datepicker = ({
  allowClear = false,
  defaultValue = '',
  disableFuture = false,
  label,
  onChangeDate,
  placeholder
}: IDatepickerProps) => {
  const format = 'YYYY-MM-DD';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateDefault, setDateDefault] = useState<string>();

  const { ref } = useClickOutside(isOpen, () => setIsOpen(false));

  const [showClearButton, setShowClearButton] = useState(false);

  // Disabled dates
  const now = today(getLocalTimeZone());
  const [disabledFutureDates, setDisabledFutureDates] = useState<CalendarDate[][]>();

  const onChangeDateHandler = (date: string) => {
    onChangeDate(dayjs(date).format(format));
    setDateDefault(date);
    setIsOpen(false);
  };

  const onClear = () => {
    setIsOpen(false);
    setDateDefault(undefined);
    onChangeDate('');
  };

  const isDateUnavailable = (date: any) =>
    disabledFutureDates
      ? disabledFutureDates.some((interval) => date.compare(interval[0]) >= 0 && date.compare(interval[1]) <= 0)
      : false;

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      setDateDefault(dayjs(defaultValue).format(format));
    }

    if (disableFuture) {
      setDisabledFutureDates([[now.add({ days: 1 }), now.add({ years: 100 })]]);
    }
  }, []);

  return (
    <div className="datepicker" ref={ref}>
      {label && <div className="datepicker__label">{label}</div>}
      <div
        className="datepicker__input-fake"
        onMouseEnter={() => setShowClearButton(true)}
        onMouseLeave={() => setShowClearButton(false)}>
        {dateDefault && showClearButton && allowClear && (
          <div className="datepicker__clear">
            <button onClick={() => onClear()} style={{ width: 25 }} type="button">
              <IconClose height={18} width={18} />
            </button>
          </div>
        )}
        <button className="datepicker__input flex items-center justify-between" onClick={() => setIsOpen(!isOpen)} type="button">
          {dateDefault ? dayjs(dateDefault).format('DD/MM/YYYY') : <div className="datepicker__placeholder">{placeholder}</div>}
          <IconCalendar className="datepicker__icon" height={24} width={24} />
        </button>
      </div>

      {isOpen && (
        <div className="datepicker__dropdown">
          <div className="">
            <ProviderSpectrum colorScheme="light" locale="fr-FR" theme={defaultTheme}>
              <View backgroundColor="gray-50">
                <Calendar
                  defaultValue={dateDefault ? parseDate(dayjs(dateDefault).format('YYYY-MM-DD')) : undefined}
                  isDateUnavailable={isDateUnavailable}
                  onChange={(e) => onChangeDateHandler(e.toString())}
                />
              </View>
            </ProviderSpectrum>
          </div>
        </div>
      )}
    </div>
  );
};

export default Datepicker;
