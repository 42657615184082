import { Icon } from './icon.interface';

const IconPhone = ({ height = 24, width = 24, className = '' }: Icon) => (
  <svg
    className={`${className}`}
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M5.92 6.45c.936-.987 1.268-1.264 1.667-1.393.277-.072.566-.076.844-.012.367.107.464.188 1.665 1.385 1.056 1.05 1.16 1.165 1.261 1.37.194.361.225.787.084 1.172-.107.293-.258.487-.898 1.129l-.417.418a.363.363 0 0 0-.064.42 10.78 10.78 0 0 0 3.823 3.833.474.474 0 0 0 .554-.077l.401-.396c.248-.254.511-.493.787-.717.434-.267.973-.29 1.429-.063.223.106.296.172 1.385 1.259 1.122 1.119 1.154 1.154 1.277 1.41.233.426.23.94-.006 1.363-.12.239-.194.324-.826.97-.38.39-.74.746-.797.797a2.603 2.603 0 0 1-1.864.59 8.491 8.491 0 0 1-3.498-1.116 16.065 16.065 0 0 1-5.871-5.325 11.28 11.28 0 0 1-.859-1.471A7.32 7.32 0 0 1 5 8.226a2.539 2.539 0 0 1 .92-1.776Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default IconPhone;
