const IconDashboard = ({ height = 32, width = 32, className }: { height?: number; width?: number; className?: string }) => (
  <svg
    className={className}
    data-testid="icon-dashboard"
    height={height}
    viewBox=" 0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 7.662a.995.995 0 0 0 0-1.99v1.99Zm-6.328 7.005a.995.995 0 0 0 1.99 0h-1.99ZM12 5.672a.995.995 0 0 0 0 1.99v-1.99Zm12.338 8.995a.995.995 0 0 0 1.99 0h-1.99Zm-11.344-8a.995.995 0 0 0-1.99 0h1.99Zm-1.99 8a.995.995 0 0 0 1.99 0h-1.99Zm.995.995a.995.995 0 1 0 0-1.99v1.99Zm-5.333-1.99a.995.995 0 0 0 0 1.99v-1.99Zm5.334 0a.995.995 0 0 0 0 1.99v-1.99Zm13.333 1.99a.995.995 0 1 0 0-1.99v1.99Zm-17.672-.995a.995.995 0 0 0-1.99 0h1.99Zm18.667 0a.995.995 0 1 0-1.99 0h1.99ZM12 5.672A6.329 6.329 0 0 0 5.672 12h1.99A4.338 4.338 0 0 1 12 7.662v-1.99ZM5.672 12v2.667h1.99V12h-1.99ZM12 7.662h8v-1.99h-8v1.99Zm8 0A4.338 4.338 0 0 1 24.338 12h1.99A6.329 6.329 0 0 0 20 5.672v1.99ZM24.338 12v2.667h1.99V12h-1.99ZM11.005 6.667v8h1.99v-8h-1.99ZM12 13.672H6.667v1.99H12v-1.99Zm0 1.99h13.334v-1.99H12v1.99Zm-6.328-.995v5.334h1.99v-5.334h-1.99Zm0 5.334A6.329 6.329 0 0 0 12 26.329v-1.99A4.338 4.338 0 0 1 7.662 20h-1.99ZM12 26.329h8v-1.99h-8v1.99Zm8 0a6.328 6.328 0 0 0 6.329-6.328h-1.99A4.338 4.338 0 0 1 20 24.339v1.99Zm6.329-6.328v-5.334h-1.99v5.334h1.99Z"
      fill="currentColor"
    />
  </svg>
);

export default IconDashboard;
