import { useMutation } from 'react-query';

import { authService } from 'services';

/**
 * Hook to mutate recovery password by React Query
 *
 * @export
 * @return {*}
 */
export default function useMutationPasswordRecovery() {
  return useMutation(({ email }: { email: string }) => authService.resetPassword(email));
}
