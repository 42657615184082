import { Icon } from './icon.interface';

const IconAdd = ({ height = 32, width = 32, className, style }: Icon) => (
  <svg
    className={className}
    fill="none"
    height={height}
    style={style}
    viewBox="0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.085 6.069c-2.457.189-4.855 1.275-6.659 3.016-1.66 1.602-2.699 3.473-3.169 5.705-.639 3.036.07 6.237 1.945 8.777.423.572 1.462 1.633 2.026 2.068 2.592 1.996 5.837 2.767 8.944 2.125 4.095-.846 7.386-3.952 8.429-7.954.285-1.096.355-1.658.353-2.834-.003-1.168-.1-1.903-.388-2.928a10.974 10.974 0 0 0-8.33-7.775c-.582-.123-2.069-.278-2.434-.254l-.717.054m2.757 2.282a9.215 9.215 0 0 1 3.285 1.44c.584.408 1.674 1.498 2.082 2.082a9.653 9.653 0 0 1 1.494 3.512c.127.648.145 2.329.031 3.032-.19 1.171-.716 2.493-1.406 3.537-.512.774-1.599 1.861-2.371 2.372a9.136 9.136 0 0 1-3.087 1.326c-.892.21-2.319.245-3.281.081-2.87-.49-5.227-2.244-6.542-4.865-.707-1.408-1.035-3.36-.828-4.926.143-1.089.378-1.858.879-2.88 1.292-2.637 3.813-4.426 6.825-4.844.672-.094 2.218-.023 2.919.133m-2.351 4.016c-.191.102-.293.211-.411.436l-.157.3v2.812l-1.459.018-1.459.019-.264.155c-.7.411-.665 1.433.062 1.813l.3.157h2.82v2.82l.157.3c.397.76 1.443.76 1.84 0l.157-.3v-2.82h2.82l.3-.157c.727-.38.762-1.402.062-1.813l-.264-.155-1.459-.019-1.459-.018v-1.401c0-1.38-.003-1.406-.141-1.682-.187-.372-.456-.551-.87-.579-.258-.018-.371.005-.575.114"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconAdd;
