import { useQuery } from 'react-query';

import { shadepanelService } from 'services';

/**
 * Hook to get displayed widgets from shadepanel state by React Query as key "ShadepanelDisplayedWidgets"
 *
 * @export
 * @param {number} shadepanelId
 * @return {*}
 */
export default function useShadepanelDisplayedWidgets(shadepanelId: number) {
  return useQuery(
    ['ShadepanelDisplayedWidgets', shadepanelId],
    () => shadepanelService.getShadepanelDisplayedWidgets(shadepanelId as number),
    {
      enabled: !!shadepanelId
    }
  );
}
