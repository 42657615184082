const IconHamburger = () => (
  <svg data-testid="icon-hamburger" fill="none" height="38" width="38" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M9.26 24.973c0-.852.704-1.543 1.546-1.543h9.254a1.544 1.544 0 0 1 0 3.087h-9.253a1.542 1.542 0 0 1-1.547-1.544ZM9.26 18.8c0-.852.704-1.544 1.546-1.544h15.428a1.544 1.544 0 0 1 0 3.087H10.806A1.542 1.542 0 0 1 9.26 18.8Zm0-6.173c0-.852.704-1.544 1.546-1.544h15.428a1.543 1.543 0 1 1 0 3.087H10.806a1.542 1.542 0 0 1-1.546-1.543Z"
      fill="#4C4E58"
      fillRule="evenodd"
    />
  </svg>
);

export default IconHamburger;
