/* eslint-disable indent */
import { Dispatch, SetStateAction, useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';

import { IconClose } from 'components/Icons';
import { useClickOutside } from 'hooks';

import './Modal.scss';

export interface IModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  title: string;
  width?: number;
  onToggleModal: Dispatch<SetStateAction<boolean>>;
}

const Modal = ({ children, isOpen, title, onToggleModal, width = 500 }: IModalProps) => {
  const onCloseHandler = () => {
    onToggleModal(false);
  };
  const { ref } = useClickOutside(isOpen, onToggleModal);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {createPortal(
            <div className="flex items-center justify-center modal-overlay">
              <div className="modal-wrapper">
                <motion.div
                  animate={{
                    opacity: 1,
                    scale: 1,
                    transition: {
                      ease: 'easeOut',
                      duration: 0.2
                    }
                  }}
                  className="modal"
                  exit={{
                    opacity: 0,
                    scale: 0.75,
                    transition: {
                      ease: 'easeIn',
                      duration: 0.2
                    }
                  }}
                  initial={{
                    opacity: 0,
                    scale: 0.75
                  }}
                  ref={ref}
                  style={width ? { width: `${width}px` } : {}}
                >
                  <div className="flex items-center justify-between modal__header">
                    <div className="modal__title">{title}</div>
                    <button className="flex items-center justify-center modal__close" onClick={onCloseHandler} type="button">
                      <IconClose height={18} width={18} />
                    </button>
                  </div>
                  <div className="modal__container">{children}</div>
                </motion.div>
              </div>
            </div>,
            document.body
          )}
        </>
      )}
    </AnimatePresence>
  );
};

export default Modal;
