import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { FileItem } from 'components';
import { IconAddFile } from 'components/Icons';

import './DropFileInput.scss';

export interface IDropFileInputProps {
  onFileChange: (files: FileList[]) => void;
  limitFiles?: number;
  files: FileList[];
}

const DropFileInput = ({ onFileChange, limitFiles = 2, files }: IDropFileInputProps) => {
  const { t } = useTranslation();
  const zoneRef = useRef<HTMLDivElement>(null);
  const [isFileValid, setIsFileValid] = useState('');
  const [isPassLimit, setIsPassLimit] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [fileList, setFileList] = useState<any[]>([]);

  const onDragEnter = () => {
    if (zoneRef) zoneRef?.current?.classList.add('dragover');
  };
  const onDragLeave = () => {
    if (zoneRef) zoneRef?.current?.classList.add('dragover');
  };

  const onDrop = () => {
    if (zoneRef) zoneRef?.current?.classList.remove('dragover');
  };

  const validateFile = (file: File) => {
    const fileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    return fileTypes.includes(file.type);
  };

  const onFileDrop = (event: ChangeEvent) => {
    const target = event?.target as HTMLInputElement;
    const newFile = (target.files as FileList)[0];

    const validationFile = validateFile(newFile);
    setIsFileValid(!validationFile ? newFile.type : '');

    if (!validationFile) {
      return;
    }

    if (fileList.length >= limitFiles) {
      setIsPassLimit(true);
      return;
    }

    if (newFile) {
      const updatedFiles = [...fileList, newFile];
      setFileList(updatedFiles);
      onFileChange(updatedFiles);
    }
  };

  const onFileRemove = (fileIndex: number) => {
    const updatedFiles = fileList.filter((_, index) => index !== fileIndex);
    setFileList(updatedFiles);
    onFileChange(updatedFiles);
  };

  useEffect(() => {
    setFileList(files);
  }, [files]);

  return (
    <div className="drop-file">
      <div
        className="drop-file__zone flex items-center justify-center"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        ref={zoneRef}
      >
        <div>
          <div className="flex justify-center mb-2">
            <IconAddFile height={50} width={50} />
          </div>
          <div>{t('COMPONENTS.DROP_FILE_INPUT.LABEL')}</div>
        </div>
        <input
          accept=".png, .jpg, .jpeg"
          className="drop-file__input"
          data-testid="drop-file"
          name="file"
          onChange={onFileDrop}
          type="file"
          value=""
        />
      </div>
      {isPassLimit && (
        <div className="drop-file__error my-1">{t('COMPONENTS.DROP_FILE_INPUT.LIMIT_MESSAGE', { count: limitFiles })}</div>
      )}
      {isFileValid.length > 0 && (
        <div className="drop-file__error my-1">{t('COMPONENTS.DROP_FILE_INPUT.VALIDATION', { file: isFileValid })}</div>
      )}
      <div className="drop-file__list">
        {fileList.length > 0 &&
          fileList.map((file: File, index: number) => (
            <div className="my-1" key={uuidv4()}>
              <FileItem canRemove fileIndex={index} name={file.name} onRemove={onFileRemove} type={file.type} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default DropFileInput;
