import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CustomLink } from 'components';
import { IconArrowLeft, IconArrowRight, IconLogout, Logo } from 'components/Icons';
import { authService, sidebarService } from 'services';

import './Sidebar.scss';

interface ISidebarProps {
  showSidebar: boolean;
  onToogle: () => void;
}

const Sidebar = ({ showSidebar, onToogle }: ISidebarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const variants = {
    open: { opacity: 1, width: '250px' },
    closed: { opacity: 1, width: '84px' }
  };

  const onLogoutHandler = () => {
    authService.logout();
    navigate('/login');
  };

  return (
    <AnimatePresence>
      <motion.div
        animate={showSidebar ? 'open' : 'closed'}
        className={classNames('sidebar ', {
          'sidebar--show': showSidebar
        })}
        transition={{ ease: 'easeOut', duration: 0.3 }}
        variants={variants}>
        <div className="flex items-center p-2 pb-2 sidebar__logo">
          <Logo />
          {showSidebar && <div className="ml-1"> ombreapp</div>}
        </div>
        <button className="flex items-center justify-center  sidebar__toogle" onClick={() => onToogle()} type="button">
          {showSidebar ? <IconArrowLeft /> : <IconArrowRight />}
        </button>
        <div className="nav">
          {sidebarService.sidebarMenuDefault.map((item) => (
            <div key={item.id}>
              {!item.isSeparator ? (
                <CustomLink activeClassName="nav__title--active" className="flex items-center nav__title" to={item.to}>
                  {item.icon && item.icon}
                  {showSidebar && <div className="ml-1"> {t(item.label)}</div>}
                </CustomLink>
              ) : (
                <div className="nav__separator" />
              )}
            </div>
          ))}

          <div className="nav--help">
            <button className="flex items-center nav__title" onClick={() => onLogoutHandler()} type="button">
              <IconLogout className="mr-1" height={21} width={21} />
              {showSidebar && <div className="ml-1"> {t('HEADER.SIDEBAR.LOGOUT')}</div>}
            </button>
            {/* <div className="flex items-center nav__title nav__title--help">
              <IconHelp className="mr-1" height={28} width={28} />
            </div> */}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Sidebar;
