import { ResetPasswordResponse } from 'interfaces/authInterface';
import { IUserDocument, IUserResetPasswordDto } from 'interfaces/userInterface';
import { API_ROUTES } from 'services/api/urls';
import axiosInstance from 'services/interceptor';

/**
 * Get all user documents
 *
 * @return {*}  {Promise<IUserDocument[]>}
 */
export const getDocuments = async (): Promise<IUserDocument[]> => {
  const userDocuments = await axiosInstance.get<IUserDocument[]>(API_ROUTES.getDocuments());
  return userDocuments.data;
};

/**
 * Change password from MyAccount
 *
 * @param {IUserResetPasswordDto} changePasswordDto
 * @return {*}
 */
export const changePassword = async (changePasswordDto: IUserResetPasswordDto) => {
  const newPassword = {
    password: changePasswordDto.password,
    password_repeat: changePasswordDto.passwordRepeat
  };
  const changePasswordResponse = await axiosInstance.post<ResetPasswordResponse>(API_ROUTES.changePassword(), newPassword);
  return changePasswordResponse.data;
};
