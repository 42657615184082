import { Icon } from './icon.interface';

const IconAlertOctagon = ({ height = 32, width = 32, className }: Icon) => (
  <svg
    className={className}
    data-testid="icon-alert-octagon"
    fill="none"
    height={height}
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.86 2h8.28L22 7.86v8.28L16.14 22H7.86L2 16.14V7.86L7.86 2zM12 8v4M12 16h.01" />
  </svg>
);

export default IconAlertOctagon;
