import { useMutation, useQueryClient } from 'react-query';

import { shadepanelService } from 'services';

/**
 * Hook to mutate shadepanel refresh image  by React Query
 *
 * @export
 * @param {number} shadepanelId
 * @return {*}
 */
export default function useMutationShadepanelRefreshImage(shadepanelId: number, cameraId: number) {
  const queryClient = useQueryClient();
  return useMutation(() => shadepanelService.updateShadepanelImage(shadepanelId, cameraId), {
    onSuccess: (data) => {
      queryClient.setQueryData(['ShadepanelLastImage', shadepanelId, cameraId], data);
    }
  });
}
