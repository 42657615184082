const BASE_URL = {
  app: `${process.env.REACT_APP_API_URL}`
};


const BASE_API_ROUTES_APP = (baseUrl: string) => ({
  // Auth
  login: () => `${baseUrl}/users/token/`,
  refreshToken: () => `${baseUrl}/users/token/refresh/`,
  activationUser: () => `${baseUrl}/users/activation/`,
  resetLink: () => `${baseUrl}/users/reset-password/`,
  resetPassword: () => `${baseUrl}/users/save-password/`,

  // User
  getDocuments: () => `${baseUrl}/users/documents/`,
  changePassword: () => `${baseUrl}/users/save-password/`,

  // Plot
  getPlots: () => `${baseUrl}/projects/plots/`,
  getPlot: (plotId: number) => `${baseUrl}/projects/plots/${plotId}/`,
  updatePlot: (plotId: number) => `${baseUrl}/projects/plots/${plotId}/`,
  getPlotShadepanels: (plotId: number) => `${baseUrl}/projects/plots/${plotId}/shadepanels/`,
  getPlotPlantationCycle: (plotId: number) => `${baseUrl}/agronomics/plots/${plotId}/plantation-cycle/`,

  // Shadepanel
  getShadepanel: (shadepanelId: number) => `${baseUrl}/projects/shadepanels/${shadepanelId}/`,
  updateShadepanel: (shadepanelId: number) => `${baseUrl}/projects/shadepanels/${shadepanelId}/`,
  getShadepanelState: (shadepanelId: number) => `${baseUrl}/projects/shadepanels/${shadepanelId}/state/`,
  getShadepanelDisplayableWidgets: (shadepanelId: number) => `${baseUrl}/users/shadepanels/${shadepanelId}/displayable-widgets/`,
  getShadepanelDisplayedWidgets: (shadepanelId: number) => `${baseUrl}/users/shadepanels/${shadepanelId}/displayed-widgets/`,
  updateDisplayedWidget: (shadepanelId: number) => `${baseUrl}/users/shadepanels/${shadepanelId}/displayed-widgets/`,
  getShadepanelCameras: (shadepanelId: number) => `${baseUrl}/projects/shadepanels/${shadepanelId}/camera/`,
  getShadepanelLastImage: (shadepanelId: number, cameraId: number) =>
    `${baseUrl}/projects/shadepanels/${shadepanelId}/camera/${cameraId}/last-image/`,
  updateShadepanelImage: (shadepanelId: number, cameraId: number) =>
    `${baseUrl}/projects/shadepanels/${shadepanelId}/camera/${cameraId}/refresh-image/`,
  getShadepanelMeasure: (shadepanelId: number, datatype: string, refId: number) =>
    `${baseUrl}/devices/shadepanels/${shadepanelId}/data-page/${datatype}/${refId}/`,
  getShadepanelMeasureCategory: (shadepanelId: number, category: string) =>
    `${baseUrl}/devices/shadepanels/${shadepanelId}/data-page/${category}/`,

  // Notes
  getNotes: () => `${baseUrl}/users/notes/`,
  createNote: () => `${baseUrl}/users/notes/`,
  getTreatmentsNote: () => `${baseUrl}/users/notes/treatments`,

  // Weather
  getWeatherData: () => `${baseUrl}/utils/weather/`
});

export const API_ROUTES = {
  ...BASE_API_ROUTES_APP(BASE_URL.app)
};
