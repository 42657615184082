import { Icon } from './icon.interface';

const IconEmpty = ({ height = 24, width = 24 }: Icon) => (
  <svg data-testid="icon-empty" fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5 7.638a.798.798 0 0 0 .804-.804v-3.03A.798.798 0 0 0 11.5 3a.798.798 0 0 0-.804.804v3.011c0 .45.355.823.804.823Zm-4.301.187c.13.318.43.505.748.505a.949.949 0 0 0 .318-.056.803.803 0 0 0 .43-1.066l-1.16-2.787a.797.797 0 0 0-1.066-.43.803.803 0 0 0-.43 1.066l1.16 2.768Zm7.537.449a.873.873 0 0 0 .318.057.806.806 0 0 0 .748-.505l1.16-2.787a.825.825 0 0 0-.43-1.066c-.43-.15-.917.056-1.085.467l-1.16 2.787a.806.806 0 0 0 .449 1.047Zm4.189 6.659-2.207-4.62a.805.805 0 0 0-.73-.468H7.012a.805.805 0 0 0-.73.468l-2.206 4.62a.759.759 0 0 0-.075.355v4.676c0 .449.355.804.804.804h13.392a.798.798 0 0 0 .804-.804v-4.676a.81.81 0 0 0-.075-.355Zm-11.39-3.46h7.949l1.384 2.899H6.132l1.403-2.9Z"
      fill="currentColor"
    />
  </svg>
);

export default IconEmpty;
