import { HTMLAttributes, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button, Dropdown, Input, Tabs, Map, Skeleton, Empty } from 'components';
import { IDropdownOption } from 'components/Dropdown/Dropdown';
import { ITabOption } from 'components/Tabs/Tabs';
import { CameraImage, Subheader } from 'elements';
import {
  useQueryPlots,
  useQueryPlotSelected,
  useQueryPlotShadepanels,
  useQueryShadepanel,
  useMutationPlot,
  useMutationShadepanel,
  useQueryShadepanelCameras
} from 'hooks';
import { ICamera } from 'interfaces/shadepanelInterface';
import { localStorageService } from 'services';

import './ShadepanelDetail.scss';

export interface IShadepanelDetailProps extends HTMLAttributes<HTMLDivElement> {
  isMobile: boolean;
}

const ShadepanelDetail = ({ isMobile }: IShadepanelDetailProps) => {
  const { t } = useTranslation();

  // Plots
  const localPlotId = localStorageService.getItem<number>('plotId');
  const [plotId, setPlotId] = useState<number>(localPlotId as number);
  const { data: plots, refetch: refetchPlots } = useQueryPlots();
  const { data: plotShadepanels, refetch: refetchShadepanels } = useQueryPlotShadepanels(plotId as number);
  const { data: plotSelected, isLoading: isLoadingPlotSelected } = useQueryPlotSelected(plotId as number);

  const mutationPlot = useMutationPlot(plotId as number);

  // Shadepanel
  const localShadepanelId = localStorageService.getItem<number>('shadepanelId');
  const [shadepanelId, setShadepanelId] = useState<number>(localShadepanelId as number);
  const { data: shadepanelSelected } = useQueryShadepanel(shadepanelId as number);
  const mutationShadepanel = useMutationShadepanel(shadepanelId as number);

  // Cameras
  const { data: cameras } = useQueryShadepanelCameras(shadepanelId);
  const [cameraSelected, setCameraSelected] = useState<ICamera>();

  const onChangePlot = (plot: IDropdownOption) => {
    if (!plot) return;
    setPlotId(plot.value as number);
  };

  const onChangeShadowPanel = (shadowPanel: ITabOption) => {
    if (!shadowPanel) return;
    setShadepanelId(shadowPanel.value as number);
  };

  const onSubmitHandler = async (values: { namePlot: string; nameShadepanel: string }) => {
    mutationPlot.mutate({ plotName: values.namePlot });
    mutationShadepanel.mutate({ shadepanelName: values.nameShadepanel });
    refetchPlots();
    refetchShadepanels();
  };

  // Form
  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    setFieldValue: setFieldValueForm,
    isValid,
    dirty
  } = useFormik({
    initialValues: {
      namePlot: '',
      nameShadepanel: ''
    },
    onSubmit: onSubmitHandler,
    validationSchema: Yup.object({
      namePlot: Yup.string().required('SHADEPANEL_DETAIL_PAGE.FORM.ERRORS.PLOT_REQUIRED'),
      nameShadepanel: Yup.string()
    })
  });

  useEffect(() => {
    if (cameras) setCameraSelected(cameras[0] as ICamera);
  }, [cameras]);

  useEffect(() => {
    if (plotShadepanels) setShadepanelId(plotShadepanels[0].id);
  }, [plotShadepanels]);

  useEffect(() => {
    if (plotSelected) setFieldValueForm('namePlot', plotSelected.name);
    if (shadepanelSelected) setFieldValueForm('nameShadepanel', shadepanelSelected.name);
  }, [plotSelected, shadepanelSelected]);

  return (
    <div className="shadepanel-detail">
      {!isMobile && (
        <div className="shadepanel-detail__subheader">
          {plots && plots.length > 0 && (
            <Subheader
              dropdown={
                <Dropdown
                  bindLabel="name"
                  bindValue="id"
                  defaultValue={plotId}
                  items={plots}
                  onSelectOption={onChangePlot}
                  selectFirstOption={false}
                />
              }
              tabs={
                plotShadepanels && (
                  <Tabs
                    bindLabel="name"
                    bindValue="id"
                    items={plotShadepanels}
                    onSelectOption={onChangeShadowPanel}
                    type="default"
                  />
                )
              }
            />
          )}
        </div>
      )}

      {isMobile && (
        <>
          {plots && (
            <div className="my-4 shadepanel-detail__plots">
              <Dropdown
                bindLabel="name"
                bindValue="id"
                defaultValue={plotId}
                items={plots}
                onSelectOption={onChangePlot}
                selectFirstOption={false}
              />
            </div>
          )}

          {plotShadepanels && (
            <div className="my-4 shadepanel-detail__shadepanels">
              <Tabs
                bindLabel="name"
                bindValue="id"
                items={plotShadepanels}
                onSelectOption={() => onChangeShadowPanel}
                type="pills"
              />
            </div>
          )}
        </>
      )}

      <div className="mb-5 my-4">
        <div className="shadepanel-detail__title">{t('SHADEPANEL_DETAIL_PAGE.CHARACTERISTICS')}</div>
        <div className="shadepanel-detail__widget">
          <form noValidate onSubmit={handleSubmit}>
            <div className="gap-4 grid grid-cols-2 mb-2">
              <div className="md:col-span-2">
                <Input
                  label={t('SHADEPANEL_DETAIL_PAGE.FORM.PLOT_TITLE')}
                  type="text"
                  {...getFieldProps('namePlot')}
                  error={errors.namePlot}
                  touched={touched.namePlot}
                />
              </div>
              <div className="md:col-span-2">
                <Input
                  label={t('SHADEPANEL_DETAIL_PAGE.FORM.SHADEPANEL_TITLE')}
                  type="text"
                  {...getFieldProps('nameShadepanel')}
                  error={errors.nameShadepanel}
                  touched={touched.nameShadepanel}
                />
              </div>
            </div>
            <div className="flex justify-end">
              <Button disabled={!(isValid && dirty)} htmlType="submit" type="primary">
                {t('SHADEPANEL_DETAIL_PAGE.FORM.BTN_SAVE')}
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="my-4">
        <div className="shadepanel-detail__title">{t('SHADEPANEL_DETAIL_PAGE.VIEWS')}</div>
        {cameras && cameraSelected && (
          <div className="my-2">
            {cameras.map((camera) => (
              <Button
                className="mr-1"
                htmlType="button"
                key={camera.id}
                onClick={() => setCameraSelected(camera)}
                size="small"
                type={cameraSelected.id === camera.id ? 'primary' : 'link'}>
                {t('COMPONENTS.CAMERAS.LABEL')} {camera.camera_number}
              </Button>
            ))}
          </div>
        )}
        <div className="gap-4 grid grid-cols-2">
          <div className="md:col-span-2">
            {cameraSelected ? (
              <CameraImage cameraSelectedId={cameraSelected.id} shadepanelId={shadepanelId} />
            ) : (
              <Empty height={300} message={t('COMPONENTS.CAMERAS.EMPTY.MESSAGE')} title={t('COMPONENTS.CAMERAS.EMPTY.TITLE')} />
            )}
          </div>
          <div className="md:col-span-2 shadepanel-detail__map">
            {isLoadingPlotSelected ? (
              <Skeleton style={{ width: '100%', height: 350, borderRadius: 8 }} />
            ) : (
              <div>{plotSelected && <Map height={350} key={plotSelected.id} zone={plotSelected.zone} />}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShadepanelDetail;
