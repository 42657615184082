import { useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconWarning } from 'components/Icons';

import './Textarea.scss';

export interface ITextareaProps {
  error?: string;
  label?: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  touched?: boolean;
  value: string;
}

const Textarea = ({ placeholder, label, touched, error, ...props }: ITextareaProps) => {
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <div
      className={classnames('textarea-control', {
        'textarea-control--focused': focused,
        'textarea-control--error': touched && error
      })}
    >
      {label && <label htmlFor={props.name}>{label}</label>}
      <div className="flex items-center justify-between textarea-control__fake">
        <textarea className="textarea-control__input" onBlur={onBlur} {...props} onFocus={onFocus} placeholder={placeholder} />
        {touched && error && (
          <div className="textarea-control__icon">
            <IconWarning />
          </div>
        )}
      </div>
      {touched && error && <div className="input-control__hint input-control__hint--error">{t(error)}</div>}
    </div>
  );
};

export default Textarea;
