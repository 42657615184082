import { Icon } from './icon.interface';

const IconFlag = ({ height = 24, width = 24, className = '' }: Icon) => (
  <svg
    className={`${className}`}
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.732 12.033H6c0 .017 0 .034.002.051l.73-.05Zm.763.664v-.725l-.044.001.044.724Zm9.21 0 .086-.72a.739.739 0 0 0-.086-.005v.725Zm.648-.307.619.387-.62-.387Zm-.003-.71.618-.39a.352.352 0 0 0-.022-.033l-.596.422Zm-1.889-2.613.595-.422-.006-.009-.589.431Zm0-.707.59.43.005-.007-.595-.423Zm1.89-2.612.595.422a.355.355 0 0 0 .022-.033l-.617-.39Zm.002-.711.619-.387-.62.387Zm-.648-.307v.725a.737.737 0 0 0 .086-.004l-.086-.72Zm-9.21 0-.044.724a.48.48 0 0 0 .044.001V4.73Zm-.763.664-.73-.051A.75.75 0 0 0 6 5.394h.732Zm.732 6.64c0-.402-.328-.726-.732-.726a.728.728 0 0 0-.732.725h1.464ZM6 18.273c0 .401.328.726.732.726a.729.729 0 0 0 .732-.726H6Zm.002-6.19a1.45 1.45 0 0 0 1.538 1.337l-.09-1.448h.005l.004.002.002.004.001.002v.002l-1.46.101Zm1.493 1.339h9.21v-1.451h-9.21v1.45Zm9.123-.006a1.408 1.408 0 0 0 1.354-.64l-1.24-.773a.062.062 0 0 1 .059-.028l-.173 1.441Zm1.354-.64a1.38 1.38 0 0 0-.004-1.487l-1.236.778a.06.06 0 0 1 0-.064l1.24.773Zm-.026-1.52-1.89-2.612-1.19.845 1.89 2.612 1.19-.845Zm-1.896-2.62a.13.13 0 0 1 0 .154l-1.179-.861a1.312 1.312 0 0 0 0 1.568l1.18-.862Zm.006.146 1.89-2.613-1.19-.845-1.89 2.612 1.19.846Zm1.912-2.646a1.38 1.38 0 0 0 .004-1.487l-1.24.773a.059.059 0 0 1 0-.064l1.236.778Zm.004-1.487a1.408 1.408 0 0 0-1.354-.64l.173 1.44a.06.06 0 0 1-.059-.027l1.24-.773Zm-1.267-.646h-9.21v1.451h9.21v-1.45Zm-9.165.002a1.45 1.45 0 0 0-1.538 1.337l1.46.102-.001.004-.002.003-.004.001-.003.001h-.001l.089-1.448ZM6 5.394v6.64h1.464v-6.64H6Zm0 6.64v3.622h1.464v-3.623H6Zm0 3.622v2.618h1.464v-2.618H6Z"
      fill="currentColor"
    />
  </svg>
);

export default IconFlag;
