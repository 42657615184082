import { IGetNotesParams, INote, ITypeActions } from 'interfaces/noteInterface';
import { apiService, paginationService } from 'services';
import { ResponseWithPagination } from 'services/api/apiService';
import { API_ROUTES } from 'services/api/urls';
import axiosInstance from 'services/interceptor';

/**
 * Get all notes with params and pagination
 *
 * @param {IGetNotesParams} [queryNoteParams]
 * @return {*}  {Promise<ResponseWithPagination<INote>>}
 */
export const getNotes = async (queryNoteParams: IGetNotesParams): Promise<ResponseWithPagination<INote>> => {
  const params = apiService.getQueryParams([
    { key: 'per_page', value: queryNoteParams.perPage },
    { key: 'date', value: queryNoteParams.date },
    { key: 'page', value: queryNoteParams.page },
    { key: 'plot', value: queryNoteParams.plot },
    { key: 'shadepanel', value: queryNoteParams.shadepanel },
    { key: 'treatment', value: queryNoteParams.treatment }
  ]);

  const notesResponse = await axiosInstance.get<INote[]>(API_ROUTES.getNotes(), { params });
  const pagination = paginationService.getHeadersPagination(notesResponse.headers, queryNoteParams.perPage);
  return { items: notesResponse.data, pagination };
};

/**
 * Post note with form data
 *
 * @param {FormData} note
 * @return {*}  {Promise<INote[]>}
 */
export const createNote = async (note: FormData): Promise<INote[]> => {
  const createResponse = await axiosInstance.post<INote[]>(API_ROUTES.createNote(), note, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return createResponse.data;
};

/**
 * Get all type actions
 *
 * @return {*}  {Promise<ITypeActions[]>}
 */
export const getTypesAction = async (): Promise<ITypeActions[]> => {
  const notesResponse = await axiosInstance.get<ITypeActions[]>(API_ROUTES.getTreatmentsNote());
  return notesResponse.data;
};
