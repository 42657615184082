/* eslint-disable consistent-return */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

import { API_ROUTES } from './api/urls';
import { getLocalRefresh, getLocalToken, logout, setLocalToken } from './auth/authService';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const excludedUrls: string[] = ['/links/reset-link/'];

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.url && !excludedUrls.includes(config.url)) {
      if (getLocalToken() && config.headers) {
        config.headers.Authorization = `Bearer ${getLocalToken()}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    // Check if status 401 and token is not valid, and not url API for refresh token
    if (response.status === 401 && response.data.code === 'token_not_valid') {
      if (config.url !== '/links/token/refresh/') {
        // Get expiration milleseconds for refresh token
        const tokenRefreshDecoded: { exp: number } = jwt_decode(getLocalRefresh());

        // Check if refresh token is expired
        const isExpiredRefreshToken = tokenRefreshDecoded.exp < Date.now().valueOf() / 1000;

        // if is refresh token is expired -> logout -> redirect to login
        if (isExpiredRefreshToken) {
          logout();
          window.location.href = '/#/login';
        }

        const refreshTokenResponse = await axiosInstance.post(API_ROUTES.refreshToken(), { refresh: getLocalRefresh() });
        setLocalToken(refreshTokenResponse.data.access);

        axiosInstance.defaults.headers.common.Authorization = `Bearer ${refreshTokenResponse.data.access}`;
        return axiosInstance(config);
      }
    }
    Promise.reject(error);
  }
);

export default axiosInstance;
