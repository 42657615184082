import { Icon } from './icon.interface';

const IconWeatherClearSky = ({ height = 100, width = 100 }: Icon) => (
  <svg fill="none" height={height} viewBox="0 0 100 100" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M72.6976 21.3194C73.1826 20.7326 74.0541 20.6564 74.6336 21.15C75.2012 21.6335 75.2755 22.483 74.8005 23.0576L73.053 25.1718C72.5679 25.7586 71.6965 25.8348 71.1169 25.3411C70.5494 24.8577 70.4751 24.0082 70.9501 23.4335L72.6976 21.3194Z"
      fill="url(#paint0_linear_2583_20090)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M50.3802 13.3739C50.3767 12.6172 50.991 12.0029 51.7478 12.0065C52.4945 12.01 53.0989 12.6145 53.1024 13.3612L53.1153 16.1225C53.1189 16.8792 52.5045 17.4936 51.7478 17.49C51.0011 17.4865 50.3966 16.882 50.3931 16.1352L50.3802 13.3739Z"
      fill="url(#paint1_linear_2583_20090)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M86.1214 43.0585C86.872 42.9241 87.5887 43.4259 87.7192 44.1772C87.8488 44.923 87.3514 45.6334 86.6062 45.7668L83.9738 46.238C83.2232 46.3723 82.5065 45.8705 82.376 45.1192C82.2465 44.3734 82.7438 43.663 83.489 43.5296L86.1214 43.0585Z"
      fill="url(#paint2_linear_2583_20090)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M82.6789 68.4854C83.3262 68.8648 83.5483 69.694 83.1774 70.3462C82.7996 71.0105 81.9516 71.2377 81.2923 70.8513L78.9617 69.4853C78.3144 69.1059 78.0922 68.2767 78.4631 67.6246C78.841 66.9603 79.6889 66.7331 80.3483 67.1195L82.6789 68.4854Z"
      fill="url(#paint3_linear_2583_20090)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M63.9186 85.7203C64.17 86.4215 63.8096 87.1944 63.1108 87.4526C62.4024 87.7144 61.6165 87.3479 61.3617 86.637L60.4236 84.0208C60.1722 83.3196 60.5326 82.5467 61.2314 82.2885C61.9398 82.0267 62.7257 82.3932 62.9805 83.1041L63.9186 85.7203Z"
      fill="url(#paint4_linear_2583_20090)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M38.6357 86.6371C38.3808 87.348 37.5949 87.7145 36.8865 87.4527C36.1877 87.1945 35.8273 86.4216 36.0788 85.7204L37.0168 83.1042C37.2717 82.3933 38.0575 82.0268 38.7659 82.2886C39.4647 82.5468 39.8251 83.3197 39.5737 84.0209L38.6357 86.6371Z"
      fill="url(#paint5_linear_2583_20090)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M18.7052 70.8511C18.0459 71.2375 17.1979 71.0103 16.8201 70.346C16.4492 69.6938 16.6713 68.8647 17.3186 68.4853L19.6493 67.1193C20.3086 66.7329 21.1566 66.9601 21.5344 67.6244C21.9053 68.2766 21.6831 69.1058 21.0358 69.4851L18.7052 70.8511Z"
      fill="url(#paint6_linear_2583_20090)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M13.3911 45.7661C12.646 45.6328 12.1486 44.9224 12.2781 44.1766C12.4086 43.4253 13.1253 42.9235 13.8759 43.0578L16.5084 43.529C17.2535 43.6624 17.7509 44.3728 17.6214 45.1186C17.4909 45.8699 16.7742 46.3717 16.0236 46.2373L13.3911 45.7661Z"
      fill="url(#paint7_linear_2583_20090)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M25.197 23.0577C24.722 22.4831 24.7963 21.6336 25.3639 21.1501C25.9434 20.6565 26.8149 20.7327 27.2999 21.3195L29.0474 23.4336C29.5224 24.0083 29.4481 24.8578 28.8806 25.3412C28.301 25.8349 27.4295 25.7587 26.9445 25.1719L25.197 23.0577Z"
      fill="url(#paint8_linear_2583_20090)"
      fillRule="evenodd"
    />
    <ellipse cx="49.9989" cy="49.8016" fill="url(#paint9_linear_2583_20090)" rx="25.3531" ry="25.4922" />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2583_20090"
        x1="73.8276"
        x2="71.875"
        y1="20.7493"
        y2="23.0764"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_2583_20090"
        x1="50.5791"
        x2="50.5791"
        y1="12.2215"
        y2="15.262"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_2583_20090"
        x1="87.3551"
        x2="84.3956"
        y1="43.3528"
        y2="43.8747"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_2583_20090"
        x1="83.432"
        x2="80.8157"
        y1="69.4829"
        y2="67.9723"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_2583_20090"
        x1="63.8595"
        x2="62.8172"
        y1="86.9509"
        y2="84.0874"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint5_linear_2583_20090"
        x1="37.7824"
        x2="38.8246"
        y1="87.5496"
        y2="84.686"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint6_linear_2583_20090"
        x1="17.4405"
        x2="20.0569"
        y1="70.9983"
        y2="69.4877"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint7_linear_2583_20090"
        x1="12.3384"
        x2="15.2979"
        y1="45.0757"
        y2="45.5975"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint8_linear_2583_20090"
        x1="24.8292"
        x2="26.7819"
        y1="21.8743"
        y2="24.2014"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint9_linear_2583_20090"
        x1="35.9743"
        x2="35.9743"
        y1="26.3644"
        y2="54.5674"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconWeatherClearSky;
