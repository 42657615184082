import { Icon } from './icon.interface';

const IconPlace = ({ height = 24, width = 24, className = '' }: Icon) => (
  <svg
    className={`${className}`}
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M17.657 4.427C19.154 5.954 20 8.071 20 10.284c0 2.214-.846 4.33-2.343 5.858L12 22l-5.658-5.858C4.847 14.615 4 12.498 4 10.284c0-2.213.847-4.33 2.342-5.857 3.167-3.236 8.149-3.236 11.315 0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.125"
    />
    <path
      clipRule="evenodd"
      d="M15.2 9.775a3.5 3.5 0 0 1-.955 2.378A3.05 3.05 0 0 1 12 13.11a3.05 3.05 0 0 1-2.245-.957A3.502 3.502 0 0 1 8.8 9.775c.024-1.866 1.457-3.359 3.2-3.333 1.742-.026 3.175 1.467 3.2 3.333Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.125"
    />
  </svg>
);

export default IconPlace;
