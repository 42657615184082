import { BaseAction } from './storeInterface';

export interface AuthToken {
  refresh: string;
  access: string;
}

export interface AuthTokenDto {
  token: string;
  refresh: string;
}

export enum LocalToken {
  TOKEN = 'token',
  REFRESH = 'refresh'
}

export interface LoginAction extends BaseAction {
  payload: AuthTokenDto;
}

export type LogoutAction = BaseAction;

export interface LoginDto {
  email: string;
  password: string;
  permission: string;
}

export interface ResetPasswordDto {
  email: string;
}

export interface ResetPasswordAction extends BaseAction {
  payload: { email: string };
}

export interface ResetPasswordResponse {
  success: boolean;
}

export interface ResetPasswordTokenDto {
  password: string;
  password_repeat: string;
}
