const IconUser = ({ height = 32, width = 32, className }: { height?: number; width?: number; className?: string }) => (
  <svg
    className={className}
    data-testid="icon-user"
    fill="none"
    height={height}
    viewBox=" 0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M15.063 7.173c0 1.752-1.372 3.173-3.064 3.173s-3.063-1.42-3.063-3.173C8.936 5.42 10.307 4 11.999 4c1.692 0 3.064 1.42 3.064 3.173Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.7"
    />
    <path
      d="M6.745 16.383c-.014-.5-.416-.892-.898-.877-.482.015-.86.431-.847.93l1.745-.053Zm2.899 3.712v-.904h-.025l.025.904Zm4.712 0 .025-.904h-.025v.904ZM19 16.436c.014-.499-.365-.915-.847-.93-.482-.015-.884.378-.898.877l1.745.053Zm-1.745-.167c.014.5.416.892.898.877.482-.015.86-.431.847-.93l-1.745.053Zm-2.899-3.711v.903h.025l-.025-.903Zm-4.712 0-.025.903h.025v-.903ZM5 16.216c-.014.499.365.915.847.93.482.015.884-.378.898-.877L5 16.216Zm0 .22c.075 2.594 2.164 4.636 4.669 4.562l-.05-1.807c-1.542.045-2.828-1.211-2.874-2.808L5 16.436ZM9.644 21h4.712V19.19H9.644v1.808Zm4.687 0c2.505.073 4.594-1.969 4.669-4.563l-1.745-.053c-.046 1.597-1.332 2.853-2.874 2.808l-.05 1.807ZM19 16.215c-.075-2.594-2.164-4.636-4.669-4.562l.05 1.807c1.542-.045 2.828 1.212 2.874 2.808L19 16.216Zm-4.644-4.562H9.644v1.807h4.712v-1.807Zm-4.687 0C7.164 11.58 5.075 13.622 5 16.216l1.745.053c.046-1.596 1.332-2.853 2.874-2.808l.05-1.807Z"
      fill="currentColor"
    />
  </svg>
);

export default IconUser;
