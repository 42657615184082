import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import './Skeleton.scss';

export interface ISkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  count?: number;
  animate?: boolean;
}

const Skeleton = ({ count = 1, style, animate = true }: ISkeletonProps) => (
  <div className="skeleton">
    {count > 0 &&
      Array(count)
        .fill(0)
        .map(() => (
          <div
            className={classNames('skeleton__item', {
              'skeleton__item--animation': animate
            })}
            data-testid="skeleton"
            key={uuidv4()}
            style={{ ...style }}
          />
        ))}
  </div>
);

export default Skeleton;
