import classNames from 'classnames';

import { IconArrowLeft, IconArrowRight } from 'components/Icons';
import { usePagination } from 'hooks';
import { DOTS } from 'hooks/usePagination';

import './Pagination.scss';

export interface IPaginationProps extends IPagination {
  onPageChange: (page: number | string) => void;
  type?: 'ghost' | 'default';
}

export interface IPaginationDto {
  total?: number;
  perPage?: number;
  pageCount?: number;
  currentPage?: number;
}

export interface IPagination {
  total: number;
  perPage: number;
  pageCount?: number;
  currentPage: number;
}

const Pagination = ({ currentPage, perPage, total, onPageChange, type: theme = 'default' }: IPaginationProps) => {
  const paginationRange = usePagination({
    total,
    perPage,
    currentPage
  });

  const canChange = (currentPageToChange: number, type: string) => {
    if (type === 'prev' && currentPageToChange > 1) {
      onPageChange(currentPageToChange - 1);
    }

    if (type === 'next' && paginationRange && currentPageToChange < paginationRange[paginationRange.length - 1]) {
      onPageChange(currentPageToChange + 1);
    }
  };

  return (
    <div className={`pagination pagination--${theme}`}>
      <div className="flex">
        <button className="pagination__button" onClick={() => canChange(currentPage, 'prev')} type="button">
          <IconArrowLeft height={20} width={20} />
        </button>

        {paginationRange &&
          paginationRange.map((page, key) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={key}
            >
              {page !== DOTS ? (
                <button
                  className={classNames('pagination__button', {
                    'pagination__button--active': currentPage === page
                  })}
                  onClick={() => onPageChange(page)}
                  type="button"
                >
                  {page}
                </button>
              ) : (
                <div className="flex items-center justify-center pagination__button pagination__button--separator">{page}</div>
              )}
            </div>
          ))}

        <button className="pagination__button" onClick={() => canChange(currentPage, 'next')} type="button">
          <IconArrowRight height={20} width={20} />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
