import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { getLocalToken } from 'services/auth/authService';
import { AppStore } from 'store/store';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const tokenLocal = getLocalToken();

  const isLoading = useSelector((state: AppStore) => state.ui.loading);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (!tokenLocal) {
    return <Navigate state={{ from: location }} to="/login" />;
  }

  return children;
};

export default PrivateRoute;
