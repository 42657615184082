import { HTMLAttributes, useState } from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { IconArrowRight, IconArrowUp } from 'components/Icons';
import { useQueryWeatherData } from 'hooks';

import WeatherHourGrid from '../WeatherHourGrid/WeatherHourGrid';

import './WeatherDropdown.scss';

export interface IWeatherDropdownProps extends HTMLAttributes<HTMLDivElement> {
  provider: string;
  location: number[];
}

/**
 *
 *
 * @param {IWeatherDropdownProps}
 * @return {*}
 */
const WeatherDropdown = ({ provider, location }: IWeatherDropdownProps) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { data: weatherData } = useQueryWeatherData(provider, location);

  const toggle = (): void => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="weather-dropdown">
      <button
        className={classNames('weather-dropdown__container flex items-center justify-between', {
          'weather-dropdown__container--open': isDropdownOpen
        })}
        onClick={toggle}
        type="button"
      >
        {t('WEATHER.DROPDOWN.SHOW_BY_HOURS')}
        {isDropdownOpen ? <IconArrowUp height={20} width={20} /> : <IconArrowRight height={20} width={20} />}
      </button>
      <AnimatePresence>
        {isDropdownOpen && (
          <motion.div
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.3 }
            }}
            exit={{
              opacity: 0,
              y: -20,
              transition: { duration: 0.3 }
            }}
            initial={{
              opacity: 0,
              y: -20
            }}
          >
            {weatherData && <WeatherHourGrid forecast={weatherData.forecast} showOnly={6} />}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default WeatherDropdown;
