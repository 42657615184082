import { useState } from 'react';

import classNames from 'classnames';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Alert, Button, Dropdown, FileItem, Input, Modal } from 'components';
import { IDropdownOption } from 'components/Dropdown/Dropdown';
import { IconArrowRight, IconCheck, IconEmail, IconPhone } from 'components/Icons';
import { useMutationChangePassword, useQueryUserDocuments } from 'hooks';
import { logout } from 'services/auth/authService';

import MyAccountDocuments from './MyAccountDocuments';
import MyAccountSupport from './MyAccountSupport';

import './MyAccount.scss';

export interface IMyAccountProps {
  isMobile: boolean;
}

const MyAccount = ({ isMobile }: IMyAccountProps) => {
  const navigate = useNavigate();
  const { data: userDocuments } = useQueryUserDocuments();

  const [menuSelected, setMenuSelected] = useState('profile');
  const [isOpenModalPassword, setIsOpenModalPassword] = useState(false);

  const subjectTypes: IDropdownOption[] = [
    { value: 'technical', label: t('MY_ACCOUNT.SUPPORT.SUBJECT.OPTIONS.TECHNICAL') },
    { value: 'other', label: t('MY_ACCOUNT.SUPPORT.SUBJECT.OPTIONS.OTHER') }
  ];
  const [subject, setSubject] = useState<string>(subjectTypes && (subjectTypes[0].value as string));

  const { mutate: mutatePassword, isSuccess: isSuccessPassword } = useMutationChangePassword();

  const onSelectSubject = (subjectSelected: IDropdownOption) => {
    if (!subjectSelected) return;
    setSubject(subjectSelected.value as string);
  };

  const { isValid, dirty, handleSubmit, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      password: '',
      passwordRepeat: ''
    },
    onSubmit: (values) => {
      mutatePassword(values);
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t('MY_ACCOUNT.FORM.ERRORS.PASSWORD_REQUIRED')),
      passwordRepeat: Yup.string()
        .required(t('MY_ACCOUNT.FORM.ERRORS.PASSWORD_REPEAT_REQUIRED'))
        .oneOf([Yup.ref('password'), null], t('MY_ACCOUNT.FORM.ERRORS.PASSWORD_NOT_SAME'))
    })
  });

  const onLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div
      className={classNames('account', {
        'account--mobile': isMobile,
        'container-fluid': !isMobile
      })}>
      {!isMobile && (
        <div className="my-4">
          <div className="gap-2 grid grid-cols-3 my-2">
            <div className="col-span-1 widget widget--np">
              <div className="px-4 py-5 ">
                <div className="mb-4 widget__title">{t('MY_ACCOUNT.FORM.TITLE')}</div>
                <div className="widget__content">
                  {isSuccessPassword && (
                    <Alert
                      icon={<IconCheck height={18} width={18} />}
                      label={t('MY_ACCOUNT.FORM.SUCCESS.MESSAGE')}
                      timeToClose={2500}
                      title={t('MY_ACCOUNT.FORM.SUCCESS.TITLE')}
                      type="success"
                    />
                  )}
                  <form className="form" noValidate onSubmit={handleSubmit}>
                    <div className="form-control mb-4">
                      <Input
                        label={t('MY_ACCOUNT.FORM.NEW_PASSWORD')}
                        {...getFieldProps('password')}
                        error={errors.password}
                        touched={touched.password}
                        type="password"
                      />
                    </div>
                    <div className="form-control mb-4">
                      <Input
                        label={t('MY_ACCOUNT.FORM.CONFIRM_PASSWORD')}
                        {...getFieldProps('passwordRepeat')}
                        error={errors.passwordRepeat}
                        touched={touched.passwordRepeat}
                        type="password"
                      />
                    </div>
                    <div className="form-control">
                      <Button block disabled={!(isValid && dirty)} htmlType="submit" type="primary">
                        {t('MY_ACCOUNT.FORM.BTN_SAVE')}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-span-2 widget widget--np">
              <div className="px-4 py-5 ">
                <div className="mb-4 widget__title">{t('MY_ACCOUNT.DOCUMENTS.TITLE')}</div>
                <div className="widget__content">
                  <div className="gap-2 grid grid-cols-2">
                    {userDocuments &&
                      userDocuments.map((document) => (
                        <a href={document.file} key={document.id} rel="noreferrer" target="_blank">
                          <FileItem key={document.id} name={document.name} type="image/png" />
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="widget">
            <div className="px-4 py-5 ">
              <div className="widget__title">{t('MY_ACCOUNT.SUPPORT.TITLE')}</div>
              <div className="widget__content">
                <div className="gap-5 grid grid-cols-3 items-center">
                  <div>
                    <div className="w-col-60">
                      <Dropdown
                        bindLabel="label"
                        bindValue="value"
                        defaultValue={subject}
                        items={subjectTypes}
                        label={t('MY_ACCOUNT.SUPPORT.SUBJECT.LABEL')}
                        onSelectOption={onSelectSubject}
                        selectFirstOption={false}
                      />
                    </div>
                  </div>
                  <div className="account__card-button">
                    <div className="flex items-center label-card">
                      <IconPhone className="icon-card mr-2" />
                      {t('MY_ACCOUNT.SUPPORT.CALL')}
                    </div>
                  </div>
                  <div className="account__card-button">
                    <div className="flex items-center label-card">
                      <IconEmail className="icon-card mr-2" />
                      {t('MY_ACCOUNT.SUPPORT.EMAIL')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="my-4">
          {menuSelected === 'profile' && (
            <div>
              <h2 className="">{t('PAGES.MY_ACCOUNT')}</h2>
              <div className="mb-2 widget">
                <div className="">
                  <div className="mb-2">
                    <strong>{t('MY_ACCOUNT.MOBILE.PASSWORD.LABEL')}</strong>
                  </div>
                  <button
                    className="dropdown-fake flex items-center justify-between"
                    onClick={() => setIsOpenModalPassword(true)}
                    type="button">
                    {t('MY_ACCOUNT.MOBILE.PASSWORD.MENU')}
                    <IconArrowRight height={18} width={18} />
                  </button>

                  <Modal
                    isOpen={isOpenModalPassword}
                    onToggleModal={setIsOpenModalPassword}
                    title={t('MY_ACCOUNT.MOBILE.PASSWORD.MODAL.TITLE')}>
                    <div>
                      <form className="form" noValidate onSubmit={handleSubmit}>
                        <div className="form-control mb-4">
                          <Input
                            label={t('MY_ACCOUNT.FORM.NEW_PASSWORD')}
                            {...getFieldProps('password')}
                            error={errors.password}
                            touched={touched.password}
                            type="password"
                          />
                        </div>
                        <div className="form-control mb-4">
                          <Input
                            label={t('MY_ACCOUNT.FORM.CONFIRM_PASSWORD')}
                            {...getFieldProps('passwordRepeat')}
                            error={errors.passwordRepeat}
                            touched={touched.passwordRepeat}
                            type="password"
                          />
                        </div>
                        <div className="form-control">
                          <Button block disabled={!(isValid && dirty)} htmlType="submit" type="primary">
                            {t('MY_ACCOUNT.FORM.BTN_SAVE')}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </Modal>
                </div>
              </div>

              <div className="mb-2 widget">
                <div className="">
                  <div className="mb-2">
                    <strong>{t('MY_ACCOUNT.MOBILE.DOCUMENTS.LABEL')}</strong>
                  </div>
                  <button
                    className="dropdown-fake flex items-center justify-between"
                    onClick={() => setMenuSelected('documents')}
                    type="button">
                    {t('MY_ACCOUNT.MOBILE.DOCUMENTS.MENU')}
                    <IconArrowRight height={18} width={18} />
                  </button>
                </div>
              </div>

              <div className="mb-2 widget">
                <div className="">
                  <div className="mb-2">
                    <strong>{t('MY_ACCOUNT.MOBILE.SUPPORT.LABEL')}</strong>
                  </div>
                  <button
                    className="dropdown-fake flex items-center justify-between"
                    onClick={() => setMenuSelected('support')}
                    type="button">
                    {t('MY_ACCOUNT.MOBILE.SUPPORT.MENU')}
                    <IconArrowRight height={18} width={18} />
                  </button>
                </div>
              </div>

              <div className="flex justify-end">
                <Button htmlType="button" onClick={() => onLogout()} type="ghost">
                  {t('MY_ACCOUNT.MOBILE.LOGOUT.LABEL')}
                </Button>
              </div>
            </div>
          )}

          {menuSelected === 'documents' && (
            <div>
              <MyAccountDocuments onSetMenuSelected={setMenuSelected} />
            </div>
          )}

          {menuSelected === 'support' && (
            <div>
              <MyAccountSupport
                onChangeSubject={(value) => setSubject(value.value as string)}
                onSetMenuSelected={setMenuSelected}
                subject={subject as string}
                subjectTypes={subjectTypes}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyAccount;
