import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  DataSensorMeasure,
  Button,
  Dropdown,
  Tabs,
  DataSensorPositionMeasure,
  DataGraphMeasure
} from 'components';
import { IDropdownOption } from 'components/Dropdown/Dropdown';
import { IconRefresh } from 'components/Icons';
import { ITabOption } from 'components/Tabs/Tabs';
import { Subheader } from 'elements';
import { useQueryPlots, useQueryPlotShadepanels, useQueryShadepanelDataPageGrid } from 'hooks';
import { IGridDataItem } from 'interfaces/shadepanelInterface';
import { localStorageService } from 'services';

import './ShadepanelData.scss';

interface IShadepanelDataProps {
  isMobile?: boolean;
}

const ShadepanelData = ({ isMobile }: IShadepanelDataProps) => {
  const { t } = useTranslation();

  // Plots
  const localPlotId = localStorageService.getItem('plotId');
  const [plotId, setPlotId] = useState<number>(localPlotId as number);
  const { data: plots } = useQueryPlots();
  const { data: plotShadepanels } = useQueryPlotShadepanels(plotId as number);

  // Shadepanel
  const [shadepanelId, setShadepanelId] = useState<number>();

  // Category
  const categories = [
    { value: 'thermal', name: 'Thermique' },
    { value: 'water', name: 'Hydrique' }
  ];
  const [category, setCategory] = useState<string>(categories[0].value);

  // DataPageGrid
  const { data: dataPageGrid } = useQueryShadepanelDataPageGrid(shadepanelId as number, category);

  // Refresh Data
  const [refetch, setRefetch] = useState(false);

  const onChangeShadowPanel = (shadowPanel: ITabOption) => {
    if (!shadowPanel) return;
    setShadepanelId(shadowPanel.value as number);
    localStorageService.setItem('shadepanelId', shadowPanel.value as number);
  };

  const onChangePlot = (plot: IDropdownOption) => {
    if (!plot) return;
    setPlotId(plot.value as number);
    localStorageService.setItem('plotId', plot.value as number);
  };

  const onChangeCategory = (categorySelected: IDropdownOption) => {
    if (!categorySelected) return;
    setCategory(categorySelected.value as string);
  };

  const onRefreshData = () => {
    setRefetch(!refetch);
  };

  useEffect(() => {
    const localShadepanelId = localStorageService.getItem<number>('shadepanelId');
    if (localShadepanelId) setShadepanelId(localShadepanelId);
  }, []);

  useEffect(() => {
    if (plotShadepanels) setShadepanelId(plotShadepanels[0].id);
  }, [plotShadepanels]);

  const generateGridKey = (widget: IGridDataItem): string => {
    if (typeof widget.sector === 'number') {
      return `${widget.reference}-${widget.sector}`;
    }
    return `${widget.reference}`;
  };

  return (
    <div className="shadepanel-data">
      {!isMobile && (
        <div className="shadepanel-data__subheader">
          {plots && plots.length > 0 && (
            <Subheader
              dropdown={
                <Dropdown
                  bindLabel="name"
                  bindValue="id"
                  defaultValue={plotId}
                  items={plots}
                  onSelectOption={onChangePlot}
                  selectFirstOption={false}
                />
              }
              tabs={
                plotShadepanels && (
                  <Tabs
                    bindLabel="name"
                    bindValue="id"
                    items={plotShadepanels}
                    onSelectOption={onChangeShadowPanel}
                    type="default"
                  />
                )
              }
            />
          )}
        </div>
      )}

      <div className="my-4">
        {isMobile && (
          <div className="shadepanel-data__controls">
            <div className="gap-1 grid grid-rows-2 mb-4">
              <div className="">
                {plots && (
                  <Dropdown bindLabel="name" bindValue="id" defaultValue={plotId} items={plots} onSelectOption={onChangePlot} selectFirstOption={false} />
                )}
              </div>
              <div className="">
                {plotShadepanels && (
                  <Dropdown
                    bindLabel="name"
                    bindValue="id"
                    defaultValue={plotShadepanels[0].id}
                    items={plotShadepanels}
                    onSelectOption={onChangePlot}
                    selectFirstOption={false}
                  />
                )}
              </div>
            </div>

            <div className="">
              {categories && (
                <Tabs
                  bindLabel="name"
                  bindValue="value"
                  defaultValue={category}
                  items={categories}
                  onSelectOption={(event) => onChangeCategory(event)}
                  type="pills"
                />
              )}
            </div>
          </div>
        )}

        {!isMobile && (
          <div className="flex justify-between shadepanel-data__controls">
            <div className="shadepanel-data__title">{t('DATA_PAGE.TITLE')}</div>
            <div className="controls flex">
              <div className="controls__item controls__item--dropdown">
                <Dropdown
                  bindLabel="name"
                  bindValue="value"
                  defaultValue={category}
                  items={categories}
                  onSelectOption={(event) => onChangeCategory(event)}
                  selectFirstOption={false}
                />
              </div>

              <div className="controls__item flex">
                <Button htmlType="button" onClick={() => onRefreshData()} size="small" type="primary">
                  <IconRefresh height={18} width={18} />
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="gap-2 grid grid-cols-4 mb-3 md:gap-2 md:grid-cols-1">
        {dataPageGrid &&
          dataPageGrid.sensor.map((sensorGrid) => (
            <div className="shadepanel-grid-item" key={generateGridKey(sensorGrid)}>
              {sensorGrid.distance && (
                <DataSensorPositionMeasure
                  refId={sensorGrid.reference}
                  refetch={refetch}
                  sector={sensorGrid.sector as number}
                  shadepanelId={shadepanelId as number}
                />
              )}
              {!sensorGrid.distance && (
                <DataSensorMeasure
                  refId={sensorGrid.reference}
                  refetch={refetch}
                  sector={sensorGrid.sector as number}
                  shadepanelId={shadepanelId as number}
                />
              )}
            </div>
          ))}
      </div>

      <div className="gap-3 grid grid-cols-1 mb-3 md:gap-2 md:grid-cols-1 shadepanel-data__graphs">
        {dataPageGrid &&
          dataPageGrid.graph.map((graphGrid) => (
            <div className="shadepanel-grid-item" key={generateGridKey(graphGrid)}>
              <DataGraphMeasure
                height={380}
                refId={graphGrid.reference}
                refetch={refetch}
                sector={graphGrid.sector as number}
                shadepanelId={shadepanelId as number}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ShadepanelData;
