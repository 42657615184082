import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Tabs, Dropdown, Button, Modal, PlantationCycle, Collapse, Skeleton } from 'components';
import { IDropdownOption } from 'components/Dropdown/Dropdown';
import { IconAdd, IconRefresh, IconTrash } from 'components/Icons';
import { ITabOption } from 'components/Tabs/Tabs';
import { Subheader, WeatherSlider, WeatherDropdown, WeatherCard, WidgetList } from 'elements';
import {
  useQueryPlots,
  useQueryPlotSelected,
  useQueryPlotShadepanels,
  useQueryShadepanelDisplayedWidgets,
  useQueryShadepanelDisplayableWidgets,
  useMutationUpdateDisplayedWidget
} from 'hooks';
import { IShadepanelDisplayableWidgetsList } from 'interfaces/shadepanelInterface';
import { IDisplayedWidgetDto } from 'interfaces/widgetInterface';
import { localStorageService, shadepanelService } from 'services';

import './Dashboard.scss';

interface IDashboardProps {
  isMobile?: boolean;
}

const Dashboard = ({ isMobile = false }: IDashboardProps) => {
  const { t } = useTranslation();

  // Grid
  const [colActive, setColActive] = useState(3);

  // Weather
  const [provider, setProvider] = useState<string>('OpenWeather');

  // Plot
  const localPlotId = localStorageService.getItem<number>('plotId');
  const [plotId, setPlotId] = useState<number>(localPlotId as number);
  const { data: plots } = useQueryPlots();
  const { data: plotSelected } = useQueryPlotSelected(plotId as number);
  const { data: plotShadepanels } = useQueryPlotShadepanels(plotId as number);

  // Shadepanel
  const [shadepanelId, setShadepanelId] = useState<number>();
  const [shadepanelDisplayableWidgetsFormatted, setShadepanelDisplayableWidgetsFormatted] = useState<
    IShadepanelDisplayableWidgetsList[]
  >([]);

  const {
    data: shadepanelDisplayedWidgets,
    refetch: refetchDisplayedWidgets,
    isLoading: isLoadingDisplayedWidgets
  } = useQueryShadepanelDisplayedWidgets(shadepanelId as number);
  const { data: shadepanelDisplayableWidgets } = useQueryShadepanelDisplayableWidgets(shadepanelId as number);

  const mutationCreateShadepanelDisplayedWidget = useMutationUpdateDisplayedWidget(shadepanelId as number);

  // Modal
  const [isOpenModalAddSensor, setIsOpenModalAddSensor] = useState<boolean>(false);

  const onChangePlot = (plot: IDropdownOption) => {
    if (!plot) return;
    setPlotId(plot.value as number);
  };

  const onChangeShadowPanel = (shadowPanel: ITabOption) => {
    if (!shadowPanel) return;
    setShadepanelId(shadowPanel.value as number);
  };

  const onAddSensorType = () => {
    setIsOpenModalAddSensor(!isOpenModalAddSensor);
  };

  const onSelectShadepanelDisplayableWidgets = (indexSelected: number, isSelected: boolean) => {
    setShadepanelDisplayableWidgetsFormatted((widgets) =>
      widgets.map((widget, index) => {
        if (index === indexSelected) {
          widget._isSelected = !isSelected;
        }
        return widget;
      })
    );
  };

  const onRefreshDisplayabledWidgets = () => {
    refetchDisplayedWidgets();
  };

  const onSaveShadepanelDisplayedWidgets = () => {
    const activeWidgets =
      shadepanelDisplayedWidgets &&
      shadepanelDisplayedWidgets.map((widget) => ({
        ...widget,
        data_type: widget.data_type,
        type: widget.type,
        reference: widget.reference,
        position: widget.position
      }));

    const newWidgetsToAdd = shadepanelDisplayableWidgetsFormatted
      .filter((widget) => widget._isSelected)
      .map((widget: IShadepanelDisplayableWidgetsList) => {
        const positionStartDefault = {
          i: shadepanelService.generateGridKey(widget),
          w: 1,
          h: 14,
          y: new Date().valueOf(),
          x: activeWidgets && (activeWidgets.length * 1) % colActive
        };
        return {
          ...widget,
          position: {
            lg: positionStartDefault,
            md: positionStartDefault,
            sm: positionStartDefault,
            xs: positionStartDefault
          }
        };
      });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newActiveWidgets = activeWidgets ? newWidgetsToAdd.concat(activeWidgets as any) : newWidgetsToAdd;

    mutationCreateShadepanelDisplayedWidget.mutate({ displayedWidgets: newActiveWidgets });
    setIsOpenModalAddSensor(false);
  };

  const onLayoutChange = (newLayout: IDisplayedWidgetDto[]) => {
    mutationCreateShadepanelDisplayedWidget.mutate({ displayedWidgets: newLayout });
  };

  const onSetProvider = (test: string) => {
    setProvider(test);
  };

  useEffect(() => {
    const localShadepanelId = localStorageService.getItem<number>('shadepanelId');
    if (localShadepanelId) setShadepanelId(localShadepanelId as number);
  }, []);

  useEffect(() => {
    if (plots) {
      const testPlotId = localStorageService.getItem<number>('plotId');
      
      if (testPlotId) {
        setPlotId(testPlotId as number);
      } else {
        localStorageService.setItem<number>('plotId', plots[0].id);
        setPlotId(plots[0].id);
      }
    }
  }, [plots]);

  useEffect(() => {
    if (plotShadepanels) setShadepanelId(plotShadepanels[0].id);
  }, [plotShadepanels]);

  useEffect(() => {
    if (shadepanelDisplayableWidgets) {
      const shadepanelDisplayableWidgetFormatted: IShadepanelDisplayableWidgetsList[] = shadepanelDisplayableWidgets.map(
        (widget) => ({
          ...widget,
          _isSelected: false
        })
      );
      setShadepanelDisplayableWidgetsFormatted(shadepanelDisplayableWidgetFormatted);
    }
  }, [shadepanelDisplayableWidgets]);

  return (
    <div className="dashbaord">
      {!isMobile && (
        <div className="dashboard__subheader">
          {plots && plots.length > 0 && (
            <Subheader
              dropdown={
                <Dropdown
                  bindLabel="name"
                  bindValue="id"
                  defaultValue={plotId || plots[0].id}
                  items={plots}
                  onSelectOption={onChangePlot}
                />
              }
              tabs={
                plotShadepanels && (
                  <Tabs
                    bindLabel="name"
                    bindValue="id"
                    items={plotShadepanels}
                    onSelectOption={onChangeShadowPanel}
                    type="default"
                  />
                )
              }
            />
          )}
        </div>
      )}
      <div className="flex gap-4 md:block my-4 sm:flex-col">
        <div className="md:w-col-100 sm:w-col-100 w-col-70">
          {isMobile && (
            <div className="dashboard__select-plot mb-2">
              {plots && plots.length > 0 && (
                <Dropdown
                  bindLabel="name"
                  bindValue="id"
                  defaultValue={plotId || plots[0].id}
                  items={plots}
                  onSelectOption={onChangePlot}
                />
              )}
            </div>
          )}
          <div className="dashboard__weather mb-4">
            <div className="gap-4 grid grid-cols-10 md:grid-cols-1 sm:grid-cols-1">
              <div className="col-span-5 sm:col-span-1 xxl:col-span-5 ">
                {plotSelected && (
                  <WeatherCard
                    location={plotSelected.zone.coordinates[0][0]}
                    onChangeProvider={(event) => onSetProvider(event)}
                    provider={provider}
                  />
                )}
              </div>
              {!isMobile && (
                <div className="col-span-5 dashboard__weather-slider xxl:col-span-5">
                  {plotSelected && (
                    <WeatherSlider location={plotSelected.zone.coordinates[0][0]} provider={provider} showOnly={5} />
                  )}
                </div>
              )}
            </div>
            {isMobile && (
              <div className="dashboard__weather-dropdown my-2">
                {plotSelected && <WeatherDropdown location={plotSelected.zone.coordinates[0][0]} provider={provider} />}
              </div>
            )}
          </div>
          <div className="dashboard__shadepanel mb-4">
            {plotShadepanels && plotShadepanels.length > 0 && isMobile && (
              <div className="dashboard__shadepanel-tabs my-2 ">
                {plotShadepanels.length > 0 && (
                  <Tabs
                    bindLabel="name"
                    bindValue="id"
                    items={plotShadepanels}
                    onSelectOption={onChangeShadowPanel}
                    type="pills"
                  />
                )}
              </div>
            )}
          </div>
          <div className="dashboard__data mb-4">
            {!isMobile && (
              <div className=" dashboard__widget">
                <div className="flex items-center justify-between mb-2 shadepanel-data__header">
                  <div className="dashboard__widget-title">{t('DASHBOARD.DATA.TITLE')}</div>
                  <div className="flex">
                    <Button
                      className="mr-2"
                      htmlType="button"
                      onClick={onRefreshDisplayabledWidgets}
                      size="small"
                      type="secondary">
                      <IconRefresh height={18} width={18} />
                    </Button>
                    <Button className="mr-2" htmlType="button" onClick={onAddSensorType} size="small" type="primary">
                      Ajouter une donnée
                    </Button>
                  </div>
                </div>

                <div className="shadepanel-data__container">
                  {isLoadingDisplayedWidgets && <Skeleton style={{ width: 'auto', height: 250 }} />}
                  {shadepanelDisplayedWidgets && (
                    <WidgetList onColChange={setColActive} onLayoutChange={onLayoutChange} widgets={shadepanelDisplayedWidgets} />
                  )}
                </div>
              </div>
            )}
            {isMobile && (
              <div>
                <div className="dashboard__shadepanel-widgets mb-2">
                  <div className="flex items-center justify-between">
                    <div className="dashboard__shadepanel-title">{t('DASHBOARD.DATA.TITLE')}</div>
                    <div className="">
                      <Button className="p-0" htmlType="button" onClick={onRefreshDisplayabledWidgets} size="small" type="link">
                        <IconRefresh height={24} width={24} />
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="dashboard__shadepanel-data-list mb-4 shadepanel-data">
                  {shadepanelDisplayedWidgets && (
                    <WidgetList onColChange={setColActive} onLayoutChange={onLayoutChange} widgets={shadepanelDisplayedWidgets} />
                  )}
                </div>

                <div className="dashboard__shadepanel-add ">
                  <Button block htmlType="button" onClick={onAddSensorType} size="big" type="primary">
                    {t('DASHBOARD.DATA.BTN_ADD')}
                  </Button>
                </div>
              </div>
            )}
            <Modal
              isOpen={isOpenModalAddSensor}
              onToggleModal={setIsOpenModalAddSensor}
              title={t('DASHBOARD.SENSORS_TYPE.MODAL.TITLE')}
              width={700}>
              <div className="add-sensor__list gap-2 grid grid-cols-2 mb-2">
                {shadepanelDisplayableWidgetsFormatted &&
                  shadepanelDisplayableWidgetsFormatted.map((widget, index) => (
                    <div className="add-sensor__item col-span-1 md:col-span-2" key={uuidv4()}>
                      <Collapse
                        controls={
                          <>
                            {widget._isSelected && (
                              <button onClick={() => onSelectShadepanelDisplayableWidgets(index, true)} type="button">
                                <IconTrash className="add-sensor__controls add-sensor__controls--trash" height={28} width={28} />
                              </button>
                            )}
                            {!widget._isSelected && (
                              <button onClick={() => onSelectShadepanelDisplayableWidgets(index, false)} type="button">
                                <IconAdd className="add-sensor__controls  add-sensor__controls--add" height={28} width={28} />
                              </button>
                            )}
                          </>
                        }
                        label={widget.sector ? `${t('COMPONENTS.WIDGETS.SECTOR')} ${widget.sector}` : undefined}
                        showArrow={false}
                        title={widget.label}>
                        {/* {t(`DATA_TYPE.${widget.type}.${widget.reference}.DESCRIPTION`)} */}
                      </Collapse>
                    </div>
                  ))}
              </div>
              <div className="add-sensor__button">
                <Button block htmlType="button" onClick={onSaveShadepanelDisplayedWidgets} type="primary">
                  {t('DASHBOARD.SENSORS_TYPE.MODAL.BTN_ADD')}
                </Button>
              </div>
            </Modal>
          </div>
        </div>
        <div className="dashboard__cycle dashboard__widget md:w-col-100 sm:w-col-100 w-col-30">
          <div className="mb-2">
            <div className="dashboard__title">Cycle de la culture</div>
          </div>
          <div className="">{plotSelected && <PlantationCycle isMobile={isMobile} plotId={plotId} />}</div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
