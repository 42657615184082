import { useEffect, useState } from 'react';

import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';

import { Empty, IndicatorWidget, SensorWidget, SensorPositionWidget } from 'components';
import {
  IShadepanelDisplayedWidget,
  IShadepanelIndicatorWidget,
  IShadepanelSensorPositionWidget,
  IShadepanelSensorWidget
} from 'interfaces/shadepanelInterface';
import { IDisplayedWidgetDto, IWidgetLayoutPosition } from 'interfaces/widgetInterface';
import { shadepanelService } from 'services';

import './WidgetList.scss';

export interface IWidgetListProps {
  widgets: IShadepanelDisplayedWidget[];
  onLayoutChange: (layoutChange: IDisplayedWidgetDto[]) => void;
  onColChange: (col: number) => void;
}

const WidgetList = ({ widgets, onLayoutChange, onColChange }: IWidgetListProps) => {
  const { t } = useTranslation();

  const { width, ref: refParent } = useResizeDetector();

  const beakpoints = {
    lg: 1024,
    md: 768,
    sm: 640,
    xs: 320
  };
  const cols = { lg: 3, md: 3, sm: 1, xs: 1 };

  const [breakpointActive, setBreakpointActive] = useState('lg');

  const [layout, setLayout] = useState<IWidgetLayoutPosition[]>([]);

  const breakpointChange = (newBreakpoint: string, newCol: number) => {
    setBreakpointActive(newBreakpoint);
    onColChange(newCol);
  };

  const onLayoutChangeG = (layoutChange: IWidgetLayoutPosition[]) => {
    const widgetPositions = shadepanelService.orderWidgetPositions(layoutChange, widgets, breakpointActive);
    onLayoutChange(widgetPositions);
  };

  const onRemoveWidget = (widgetId: number) => {
    const widgetsFiltered = widgets.filter((widget) => widget.id !== widgetId);
    onLayoutChange(widgetsFiltered);
  };

  const generateGridLayout = () => {
    const layoutGenerated = widgets.map((widget) => {
      const position = shadepanelService.generateLayoutWidgets(widget, breakpointActive);
      return position;
    });
    const uniqueArray = Array.from(
      new Map(layoutGenerated.filter(Boolean).map((item) => [(item as IWidgetLayoutPosition).i, item])).values()
    );

    setLayout(uniqueArray as IWidgetLayoutPosition[]);
  
  };

  useEffect(() => {
    generateGridLayout();
  }, [widgets]);

  useEffect(() => {
    generateGridLayout();
  }, [breakpointActive]);

  return (
    <div ref={refParent}>
      {width && layout.length > 0 ? (
        <ResponsiveGridLayout
          breakpoints={beakpoints}
          cols={cols}
          isDroppable
          isResizable={false}
          layouts={{ lg: layout, md: layout, sm: layout, xs: layout }}
          margin={[10, 10]}
          onBreakpointChange={(newBreakpoint, newCols) => breakpointChange(newBreakpoint, newCols)}
          onDragStop={(layoutChange: IWidgetLayoutPosition[]) => onLayoutChangeG(layoutChange)}
          rowHeight={1}
          width={width as number}>
          {widgets &&
            widgets.map((widget) => (
              <div key={shadepanelService.generateGridKey(widget)}>
                {widget.type === 'type_1' && (
                  <SensorWidget onDelete={onRemoveWidget} widget={widget as IShadepanelSensorWidget} />
                )}
                {widget.type === 'type_2' && (
                  <IndicatorWidget onDelete={onRemoveWidget} widget={widget as IShadepanelIndicatorWidget} />
                )}
                {widget.type === 'type_3' && (
                  <SensorPositionWidget onDelete={onRemoveWidget} widget={widget as IShadepanelSensorPositionWidget} />
                )}
              </div>
            ))}
        </ResponsiveGridLayout>
      ) : (
        <Empty height={500} message={t('COMPONENTS.WIDGET_LIST.EMPTY.MESSAGE')} title={t('COMPONENTS.WIDGET_LIST.EMPTY.TITLE')} />
      )}
    </div>
  );
};

export default WidgetList;
