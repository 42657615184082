import { useQuery } from 'react-query';

import { localStorageService, plotService } from 'services';

/**
 * Hook to get all shadepanels by React Query as key "PlotsShadepanel"
 *
 * @export
 * @param {number} plotId
 * @return {*}
 */
export default function useQueryPlotShadepanels(plotId: number) {
  return useQuery(['PlotsShadepanel', plotId], () => plotService.getPlotShadepanels(plotId as number), {
    onSuccess: (shadepanels) => localStorageService.setItem('shadepanelId', shadepanels[0].id),
    enabled: !!plotId
  });
}
