import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { CustomLink } from 'components';
import { IconArrowLeft, IconLogout, Logo } from 'components/Icons';
import { authService, sidebarService } from 'services';

import './SidebarMobile.scss';

interface ISidebarProps {
  onClose: () => void;
}

const SidebarMobile = ({ onClose }: ISidebarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const isLandscape = useMediaQuery({ orientation: 'landscape' });

  const onCloseHandler = () => {
    onClose();
  };

  const onLogoutHandler = () => {
    authService.logout();
    navigate('/login');
  };

  return (
    <div className="sidebar-mobile">
      <div className="sidebar-mobile__body">
        <div className="sidebar-mobile__container ">
          <div
            className="flex justify-between sidebar-mobile__header"
            style={{ padding: isLandscape ? '18px 24px' : '40px 24px' }}>
            <div className="flex items-center logo">
              <Logo className="mr-1" />
              ombreapp
            </div>

            <button className="flex header__arrow items-center justify-center" onClick={() => onClose()} type="button">
              <IconArrowLeft height={22} width={22} />
            </button>
          </div>

          <div className="content">
            <div className="nav-block" style={{ padding: isLandscape ? '0' : '32px 0' }}>
              <div className="nav">
                {sidebarService.sidebarMenuDefault.map((item) => (
                  <div key={item.id}>
                    {!item.isSeparator ? (
                      <CustomLink
                        activeClassName="nav__title--active"
                        className="nav__title"
                        onClose={() => onCloseHandler()}
                        style={{ margin: isLandscape ? '6px 24px' : '8px 24px' }}
                        to={item.to}>
                        {item.icon && item.icon}
                        <div className="ml-1"> {t(item.label)}</div>
                      </CustomLink>
                    ) : (
                      isPortrait && <div className="nav__separator" />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="nav-block nav-block--help">
              <div className="nav">
                <button className="mb-1 nav__title" onClick={() => onLogoutHandler()} type="button">
                  <IconLogout className="mr-1" height={21} width={21} />
                  {t('HEADER.SIDEBAR.LOGOUT')}
                </button>
                {/* <div className="nav__title nav__title--help">
                  <IconHelp className="mr-1" height={24} width={24} />
                  {t('HEADER.SIDEBAR.HELP')}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div aria-label="close-sidebar" className="siderbar-overlay" onClick={() => onClose()} role="button" tabIndex={0} />
    </div>
  );
};

export default SidebarMobile;
