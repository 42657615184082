import { IPagination } from 'components/Pagination/Pagination';

interface IParameterDto {
  key: string;
  value: string | number | undefined;
}

export interface IParameters {
  [param: string]: number | number[] | string | string[];
}

export interface ResponseWithPagination<T> {
  items: T[];
  pagination: IPagination;
}

export const PAGE_SIZE = 15;

export const getQueryParams = (params: IParameterDto[]) => {
  const object: IParameters = {};
  params.forEach((param: IParameterDto) => {
    if (param.value && param.key) {
      object[param.key] = param.value;
    }
  });
  return object;
};
