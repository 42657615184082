import { useEffect, useState } from 'react';

import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Route, Routes, HashRouter, Navigate } from 'react-router-dom';

import { Header, HeaderMobile } from 'elements';
import Footer from 'elements/Footer/Footer';
import Sidebar from 'elements/Sidebar/Sidebar';
import Container from 'layout/Container';
import Layout from 'layout/Layout';
import {
  ActiveAccount,
  Alerts,
  Dashboard,
  Login,
  MyAccount,
  Notes,
  PasswordRecovery,
  PasswordRecoveryToken,
  ShadepanelData,
  ShadepanelDetail
} from 'pages';
import PrivateRoute from 'routes/PrivateRouter';

const App = (): JSX.Element => {
  const isMobile = useMediaQuery({ query: '(max-width: 740px)' });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const { t } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    if (isMobile) setShowSidebar(false);
  }, [isMobile]);

  return (
    <>
      <HashRouter>
        <Routes>
          <Route element={<Navigate to="/login" />} path="/" />
          <Route element={<Login isMobile={isMobile} />} path="/login" />
          <Route element={<PasswordRecovery />} path="/reset-password" />
          <Route element={<PasswordRecoveryToken />} path="/reset-password/:token" />
          <Route element={<ActiveAccount />} path="/activation/:token" />
          <Route
            element={
              <PrivateRoute>
                <>
                  {!isMobile && <Sidebar onToogle={() => setShowSidebar(!showSidebar)} showSidebar={showSidebar} />}
                  <Layout isMobile={isMobile}>
                    {isMobile && <HeaderMobile />}
                    {!isMobile && <Header showSidebar={showSidebar} title={t('PAGES.DASHBOARD')} />}
                    <Container showSidebar={showSidebar}>
                      <Dashboard isMobile={isMobile} />
                    </Container>
                  </Layout>
                  {isMobile && isPortrait && <Footer />}
                </>
              </PrivateRoute>
            }
            path="/dashboard"
          />

          <Route
            element={
              <PrivateRoute>
                <>
                  {!isMobile && <Sidebar onToogle={() => setShowSidebar(!showSidebar)} showSidebar={showSidebar} />}
                  <Layout isMobile={isMobile}>
                    {isMobile && <HeaderMobile />}
                    {!isMobile && <Header showSidebar={showSidebar} title={t('PAGES.DATA')} />}
                    <Container showSidebar={showSidebar}>
                      {}
                      <ShadepanelData isMobile={isMobile} />
                    </Container>
                  </Layout>
                  {isMobile && isPortrait && <Footer />}
                </>
              </PrivateRoute>
            }
            path="/shadepanels/data"
          />

          <Route
            element={
              <PrivateRoute>
                <>
                  {!isMobile && <Sidebar onToogle={() => setShowSidebar(!showSidebar)} showSidebar={showSidebar} />}
                  <Layout isMobile={isMobile}>
                    {isMobile && <HeaderMobile />}
                    {!isMobile && <Header showSidebar={showSidebar} title={t('PAGES.SHADEPANELS')} />}
                    <Container showSidebar={showSidebar}>
                      <ShadepanelDetail isMobile={isMobile} />
                    </Container>
                  </Layout>
                  {isMobile && isPortrait && <Footer />}
                </>
              </PrivateRoute>
            }
            path="/shadepanels"
          />

          <Route
            element={
              <PrivateRoute>
                <>
                  {!isMobile && <Sidebar onToogle={() => setShowSidebar(!showSidebar)} showSidebar={showSidebar} />}
                  <Layout isMobile={isMobile}>
                    {isMobile && <HeaderMobile />}
                    {!isMobile && <Header showSidebar={showSidebar} title={t('PAGES.NOTES')} />}
                    <Container showSidebar={showSidebar}>
                      <Notes isMobile={isMobile} />
                    </Container>
                  </Layout>
                  {isMobile && isPortrait && <Footer />}
                </>
              </PrivateRoute>
            }
            path="/notes"
          />
          <Route
            element={
              <PrivateRoute>
                <>
                  {!isMobile && <Sidebar onToogle={() => setShowSidebar(!showSidebar)} showSidebar={showSidebar} />}
                  <Layout isMobile={isMobile}>
                    {isMobile && <HeaderMobile />}
                    {!isMobile && <Header showSidebar={showSidebar} title={t('PAGES.MY_ACCOUNT')} />}
                    <Container showSidebar={showSidebar}>
                      <MyAccount isMobile={isMobile} />
                    </Container>
                  </Layout>
                  {isMobile && isPortrait && <Footer />}
                </>
              </PrivateRoute>
            }
            path="/user/my-account"
          />

          <Route
            element={
              <PrivateRoute>
                <>
                  {!isMobile && <Sidebar onToogle={() => setShowSidebar(!showSidebar)} showSidebar={showSidebar} />}
                  <Layout isMobile={isMobile}>
                    {isMobile && <HeaderMobile />}
                    {!isMobile && <Header showSidebar={showSidebar} title={t('PAGES.ALERTS')} />}
                    <Container showSidebar={showSidebar}>
                      <Alerts isMobile={isMobile} />
                    </Container>
                  </Layout>
                  {isMobile && isPortrait && <Footer />}
                </>
              </PrivateRoute>
            }
            path="/alerts"
          />

          <Route element={<>Public</>} path="/public" />
        </Routes>
      </HashRouter>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  );
};

export default App;
