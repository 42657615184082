import { HTMLAttributes } from 'react';

import classNames from 'classnames';

import './Layout.scss';

interface ILayout extends HTMLAttributes<HTMLDivElement> {
  isMobile?: boolean;
}

const Layout = ({ children, className = '', isMobile = false }: ILayout) => (
  <div
    className={classNames(`layout ${className}`, {
      'layout--mobile': isMobile
    })}
  >
    {children}
  </div>
);

export default Layout;
