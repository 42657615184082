import { Icon } from './icon.interface';

const IconWeatherFewClouds = ({ height = 100, width = 100 }: Icon) => (
  <svg fill="none" height={height} viewBox="0 0 100 100" width={width} xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="61.6017" cy="38.8322" fill="url(#paint0_linear_2583_20161)" rx="16.3051" ry="16.8322" />
    <path
      clipRule="evenodd"
      d="M69.718 77.9999H35.8896V77.9743C23.1513 77.3859 13 66.6081 13 53.3987C13 39.812 23.7395 28.7978 36.9873 28.7978C47.6057 28.7978 56.6126 35.8737 59.7697 45.6787C62.5873 43.6099 66.0331 42.3934 69.754 42.3934C79.2786 42.3934 86.9998 50.3642 86.9998 60.1967C86.9998 69.8124 79.6152 77.6477 70.3811 77.9884V77.9999H69.7901C69.7781 78 69.7661 78 69.754 78C69.742 78 69.73 78 69.718 77.9999Z"
      fill="white"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M70.3814 77.9876C79.6155 77.647 87.0002 69.8117 87.0002 60.1959C87.0002 50.3634 79.279 42.3926 69.7545 42.3926C60.4398 42.3926 52.85 50.0159 52.5199 59.5485H35.8899V77.9992H69.7243C69.7343 77.9992 69.7444 77.9992 69.7545 77.9992C69.7645 77.9992 69.7746 77.9992 69.7847 77.9992H70.3814V77.9876Z"
      fill="white"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2583_20161"
        x1="52.5822"
        x2="52.5822"
        y1="23.3569"
        y2="41.979"
      >
        <stop stopColor="#FFC100" />
        <stop offset="1" stopColor="#FF8E00" />
      </linearGradient>
    </defs>
  </svg>
);

export default IconWeatherFewClouds;
