import { useQuery } from 'react-query';

import { shadepanelService } from 'services';

/**
 * Hook to get last image from shadepanel and camera selected by React Query as key "ShadepanelImage"
 *
 * @export
 * @param {number} shadepanelId
 * @param {number} cameraId
 * @return {*}
 */
export default function useQueryShadepanelLastImage(shadepanelId: number, cameraId: number) {
  return useQuery(
    ['ShadepanelLastImage', shadepanelId, cameraId],
    () => shadepanelService.getShadepanelImage(shadepanelId as number, cameraId as number),
    {
      enabled: Boolean(shadepanelId) && Boolean(cameraId)
    }
  );
}
