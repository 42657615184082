import { IAlert } from 'interfaces/alertInterface';
import { dateFormat } from 'pipes';

export interface IAlertListItemProps {
  alert: IAlert;
}

const AlertListItem = ({ alert }: IAlertListItemProps) => (
  <div className={`alert-item alert-item--${alert.type}`}>
    <div className="alert-item__title">Alerte gel - risque moyen</div>
    <div className="alert-item__date my-2">En cours depuis le {dateFormat(alert.start_time)}</div>
    <div className="alert-item__card">
      <div className=" mb-4">
        <div className="mb-1">Definition du risque:</div>
        <div className="">
          <strong>{alert.info}</strong>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="">Zones concernées:</div>
        <div className="">
          <strong>Parcelle {alert.plot}</strong>
        </div>
      </div>
    </div>
  </div>
);

export default AlertListItem;
