import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const dateFormat = (date: number | string, nano = false, format = 'DD/MM/YYYY'): string => {
  if (date === null || date === undefined) return '';
  if (nano) {
    return dayjs
      .unix((date as number) / 1000000000)
      .utc(true)
      .format(format);
  }
  return dayjs(date).format(format);
};

export default dateFormat;
