import { useMutation, useQueryClient } from 'react-query';

import { IShadepanelDisplayedWidgetDto } from 'interfaces/shadepanelInterface';
import { shadepanelService } from 'services';

/**
 * Hook to mutate displayable widget by React Query"
 *
 * @export
 * @param {number} shadepanelId
 * @return {*}
 */
export default function useMutationUpdateDisplayedWidget(shadepanelId: number) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ displayedWidgets }: { displayedWidgets: IShadepanelDisplayedWidgetDto[] }) =>
      shadepanelService.updateDisplayedWidget(shadepanelId, displayedWidgets),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['ShadepanelDisplayedWidgets', shadepanelId], data);
        queryClient.invalidateQueries('ShadepanelDisplayableWidgets');
      }
    }
  );
}
