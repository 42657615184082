import { Icon } from './icon.interface';

const IconAddFile = ({ height = 24, width = 24 }: Icon) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M9.381 4.752h5.614c.059 0 .117.004.175.011a4.373 4.373 0 0 1 4.067 4.348v6.538a4.374 4.374 0 0 1-4.38 4.358H9.38A4.374 4.374 0 0 1 5 15.647V9.112a4.373 4.373 0 0 1 4.381-4.359Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.929"
    />
    <path
      d="M19.237 9.873a.762.762 0 1 0 0-1.525v1.525ZM15.17 9.11h-.762c0 .422.34.763.762.763V9.11Zm.763-4.347a.763.763 0 1 0-1.525 0h1.525Zm-3.05 6.09a.762.762 0 1 0-1.526 0h1.525Zm-2.798 1.272a.763.763 0 0 0 0 1.526v-1.526Zm1.272 2.797a.762.762 0 1 0 1.525 0h-1.525Zm2.796-1.271a.763.763 0 1 0 0-1.526v1.526Zm5.085-5.303H15.17v1.525h4.068V8.348Zm-3.305.762V4.763h-1.525V9.11h1.525Zm-4.576 1.744v2.033h1.525v-2.033h-1.525Zm.763 1.27h-2.035v1.527h2.035v-1.526Zm-.763.763v2.035h1.525v-2.035h-1.525Zm.763.764h2.033v-1.526H12.12v1.526Z"
      fill="currentColor"
    />
  </svg>
);

export default IconAddFile;
