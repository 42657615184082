import './Switch.scss';

export interface ISwitchProps {
  isChecked: boolean;
  handleToggle: () => void;
}

const Switch = ({ isChecked, handleToggle }: ISwitchProps) => (
  <label className="switch">
    {isChecked}
    <input checked={isChecked} onChange={handleToggle} type="checkbox" />
    <span className="switch-slider" />
  </label>
);

export default Switch;
