import { useMutation } from 'react-query';

import { IUserResetPasswordDto } from 'interfaces/userInterface';
import { userService } from 'services';

/**
 * Hook to mutate change password by React Query
 *
 * @export
 * @return {*}
 */
export default function useMutationCreateShadepanelDisplayedWidget() {
  return useMutation((changePasswordDto: IUserResetPasswordDto) => userService.changePassword(changePasswordDto));
}
