import { Icon } from './icon.interface';

const IconTemp = ({ height = 24, width = 24, className }: Icon) => (
  <svg
    className={className}
    data-testid="icon-temp"
    fill="none"
    height={height}
    viewBox=" 0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.222 14.257V5.045a1.97 1.97 0 0 0-.65-1.446A2.323 2.323 0 0 0 12 3c-.59 0-1.155.215-1.571.6a1.963 1.963 0 0 0-.651 1.445v9.212a3.744 3.744 0 0 0-1.544 1.821 3.404 3.404 0 0 0-.062 2.31c.25.755.756 1.417 1.445 1.887.69.471 1.525.725 2.383.725.858 0 1.694-.254 2.383-.724a3.701 3.701 0 0 0 1.445-1.889 3.405 3.405 0 0 0-.062-2.309 3.745 3.745 0 0 0-1.544-1.82Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default IconTemp;
