import { AnyAction } from 'redux';

import types from 'store/types';

export interface AuthState {
  token: string;
  refresh: string;
  userEmail: string;
  isAuthenticated: boolean;
}

export const initialAuthState: AuthState = {
  token: '',
  refresh: '',
  userEmail: '',
  isAuthenticated: false
};

const authReducer = (state = initialAuthState, action: AnyAction) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        token: action.payload.token,
        refresh: action.payload.refresh,
        userEmail: action.payload.userEmail,
        isAuthenticated: true
      };
      break;
    case types.LOGOUT:
      return {
        ...initialAuthState
      };
    default:
      return state;
      break;
  }
};

export default authReducer;
