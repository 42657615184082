import { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { Skeleton } from 'components';
import { IconCheck, IconPlace } from 'components/Icons';
import { IPlantationCycle } from 'interfaces/plotInterface';
import { plotService } from 'services';

import './PlantationCycle.scss';

dayjs.extend(isBetween);

export interface IPlantationCycleProps {
  isMobile?: boolean;
  plotId: number | undefined;
}

export interface IPlantationCycleFormatted {
  status: string;
  name: string;
}

const PlantationCycle = ({ isMobile = false, plotId }: IPlantationCycleProps) => {
  const { t } = useTranslation();

  const phasesList = useRef<HTMLDivElement>(null);
  const phasesActive = useRef<HTMLDivElement>(null);

  const [phaseActiveObject, setPhaseActiveObject] = useState<IPlantationCycle>();
  const [phasesFormatted, setPhasesFormatted] = useState<IPlantationCycleFormatted[]>([]);

  const generatePhasesStatus = (plantationPhases: IPlantationCycle[]): IPlantationCycleFormatted[] =>
    plantationPhases.map((phase) => {
      const today = dayjs().format('YYYY-MM-DD');
      const todayInfinite = dayjs(`9999-${today.substring(5)}`);
      const isDone = todayInfinite.isAfter(phase.phase_range.lower);
      const isActive = todayInfinite.isBetween(phase.phase_range.lower, phase.phase_range.upper, 'day', '[)');
      const isIdle = todayInfinite.isBefore(phase.phase_range.upper);

      let status = 'idle';
      if (isActive) {
        status = 'active';
        setPhaseActiveObject(phase);
      } else if (isIdle) {
        status = 'idle';
      } else if (isDone) {
        status = 'done';
      }

      return {
        name: phase.phase_name,
        status
      };
    });

  //
  const { data: plotCycles, isLoading: isLoadingPlotCycles } = useQuery(['PlotCycles', plotId], () =>
    plotService.getPlotPlantationCycle(plotId as number)
  );

  useEffect(() => {
    if (plotCycles) {
      const phasesStatus = generatePhasesStatus(plotCycles);
      setPhasesFormatted(phasesStatus);
    }
  }, [plotCycles]);

  useEffect(() => {
    setTimeout(() => {
      if (!isMobile && phasesList.current && phasesActive.current)
        phasesList.current.scrollTo({
          behavior: 'smooth',
          top: (phasesActive.current.offsetTop as number) - 400
        });

      if (isMobile && phasesList.current && phasesActive.current)
        phasesList.current.scrollTo({
          behavior: 'smooth',
          left: (phasesActive.current.offsetLeft as number) - 50
        });
    }, 1000);
  }, [phasesList.current, phasesActive.current]);

  return (
    <div>
      {!isMobile && (
        <div className="culture-phases">
          <div className="culture-phases__header flex items-center mb-4">
            <div>
              <div className=" culture-phases__icon flex items-center justify-center mr-1">
                <div className="flex items-center justify-center">
                  <IconPlace height={17} width={17} />
                </div>
              </div>
            </div>
            {phaseActiveObject && (
              <div className="culture-phases__status">
                {t('COMPONENTS.PLANTATION_CYCLE.STATUS')} <strong>{phaseActiveObject && phaseActiveObject.phase_name}</strong>.
              </div>
            )}
          </div>
          <div className="culture-phases__container " id="phase-list" ref={phasesList}>
            <div className="flex flex-col items-center justify-center phases-list">
              {isLoadingPlotCycles ? (
                <div className="flex items-center phases-block">
                  <Skeleton count={1} style={{ borderRadius: 46, marginRight: 8, height: 46, width: 46 }} />
                  <Skeleton count={1} style={{ height: 20, width: 250 }} />
                </div>
              ) : (
                phasesFormatted.map((phase, key) => (
                  <div
                    className={`phases-block phases-block--${phase.status}`}
                    id={phase.status === 'active' ? 'phase-active' : `phase-${key}`}
                    key={uuidv4()}
                    ref={phase.status === 'active' ? phasesActive : null}>
                    {key !== 0 && <div className="phases-block__line" />}
                    <div className="flex items-center phase-item">
                      <div>
                        <div className="flex items-center justify-center phase-item__icon">
                          {phase.status === 'done' && <IconCheck height={17} width={17} />}
                          {phase.status === 'active' && <IconPlace height={17} width={17} />}
                          {phase.status === 'inactive' && null}
                        </div>
                      </div>
                      <div
                        className={classNames('phase-item__title', {
                          'phase-item__title--active': phase.status === 'active'
                        })}>
                        {phase.name}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div
          className={classNames('', {
            'culture-phases--mobile': isMobile
          })}>
          <div className="culture-phases__container">
            <div className="flex  items-center phases-list" ref={phasesList}>
              {phasesFormatted.map((phase) => (
                <div
                  className={`flex items-center justify-center phases-block phases-block--${phase.status}`}
                  key={uuidv4()}
                  ref={phase.status === 'active' ? phasesActive : null}>
                  <div className="flex flex-col phase-item">
                    <div className="flex justify-center">
                      <div className="flex items-center justify-center phase-item__icon">
                        {phase.status === 'done' && <IconCheck height={17} width={17} />}
                        {phase.status === 'active' && <IconPlace height={17} width={17} />}
                        {phase.status === 'inactive' && null}
                      </div>
                    </div>
                    <div className="phase-item__title">{phase.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {phaseActiveObject && (
            <div className="flex items-center phases-status">
              <div>
                <div className="flex items-center justify-center mr-1 phases-status__icon">
                  <div>
                    <IconPlace height={17} width={17} />
                  </div>
                </div>
              </div>
              <div className="phases-status__title">
                {t('COMPONENTS.PLANTATION_CYCLE.STATUS')} {phaseActiveObject && phaseActiveObject.phase_name}.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlantationCycle;
