import dayjs from 'dayjs';

import { IWeatherDataForecast } from 'interfaces/weatherInterface';

import WeatherHourItem from './WeatherHourItem';

import './WeatherHourGrid.scss';

export interface IWeatherHourGridProps {
  forecast: IWeatherDataForecast[];
  showOnly?: number;
  initialIndex?: number;
}

/**
 *
 *
 * @param {IWeatherHourGridProps} { days, showOnly = 6, initialIndex = 0 }
 * @return {*}
 */
const WeatherHourGrid = ({ forecast, showOnly = 6, initialIndex = 0 }: IWeatherHourGridProps) => {
  const hoursToShow = forecast.slice(initialIndex, initialIndex + showOnly);

  const isActive = (date: string) => {
    const checkDate = dayjs(date).utc(true).local();
    const today = dayjs().utc(true).local();
    return today.isSame(checkDate, 'hour');
  };

  return (
    <div className={`grid grid-cols-${showOnly} weather-hours`}>
      {hoursToShow.map((hour) => (
        <WeatherHourItem hour={hour} isActive={isActive(hour.datetime)} key={hour.forecast} />
      ))}
    </div>
  );
};

export default WeatherHourGrid;
