import { useQuery } from 'react-query';

import { localStorageService, shadepanelService } from 'services';

/**
 * Hook to get shadepanel by React Query as key "ShadepanelSelected"
 *
 * @export
 * @param {number} shadepanelId
 * @return {*}
 */
export default function useQueryShadepanel(shadepanelId: number) {
  return useQuery(['ShadepanelSelected', shadepanelId], () => shadepanelService.getShadepanel(shadepanelId as number), {
    onSuccess: () => {
      localStorageService.setItem('shadepanelId', shadepanelId);
    },
    enabled: !!shadepanelId
  });
}
