const IconNotes = ({ height = 32, width = 32, className }: { height?: number; width?: number; className?: string }) => (
  <svg
    className={className}
    data-testid="icon-notes"
    fill="none"
    height={height}
    viewBox=" 0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.84 5.725a2.28 2.28 0 0 0-.907.469c-.105.092-.555.602-1 1.133-.974 1.163-1.561 1.836-4.357 4.993-1.549 1.748-2.188 2.509-2.289 2.72l-.14.293v3.52l.147.301c.259.527.763.898 1.334.981.384.056 3.349-.62 3.739-.853.226-.134.908-.92 3.944-4.536 2.018-2.405 3.726-4.481 3.793-4.613.172-.334.258-.795.214-1.146a2.396 2.396 0 0 0-.486-1.08c-.083-.095-.668-.581-1.299-1.08-.784-.621-1.235-.941-1.428-1.014-.365-.138-.924-.176-1.265-.088m1.499 2.709.973.77c.021.017-1.315 1.626-1.403 1.69-.176.128-.974-.187-1.372-.542-.239-.211-.737-.826-.737-.908 0-.077 1.449-1.764 1.516-1.764.038 0 .498.339 1.023.754M10.64 8.246a5.991 5.991 0 0 0-3.187 1.656c-.903.913-1.347 1.734-1.697 3.138-.092.368-.137 8.388-.052 9.213.299 2.898 2.552 5.112 5.469 5.374.79.07 7.677.068 8.427-.003a5.872 5.872 0 0 0 1.884-.468c1.688-.728 2.909-2.168 3.437-4.052.111-.395.116-.529.133-3.477.011-1.687.004-3.146-.014-3.244-.043-.23-.357-.574-.596-.653-.299-.098-.724-.04-.947.13-.392.299-.374.144-.408 3.5-.034 3.332-.027 3.26-.398 4.027a4.088 4.088 0 0 1-2.586 2.144c-.318.088-.686.096-4.745.096h-4.4l-.4-.125c-1.415-.442-2.446-1.525-2.776-2.916-.104-.435-.107-.602-.092-4.838l.015-4.388.139-.409c.23-.674.494-1.089 1.033-1.626.414-.412.568-.525.992-.731.849-.412.888-.417 3.547-.454l2.351-.033.202-.155c.58-.442.501-1.367-.144-1.679-.228-.11-.295-.113-2.497-.108-1.709.003-2.368.023-2.69.081m9.698 3.708c.196.165.526.389.732.498.332.174.368.207.306.285-.073.092-3.922 4.696-3.993 4.776-.023.026-.535.16-1.139.298l-1.097.252.002-1.125.002-1.125 2.135-2.409 2.135-2.41.279.33c.154.181.441.465.638.63M9.999 21.994c-.321.14-.611.6-.612.969-.001.2.259.633.462.77l.197.134h10.691l.205-.164c.303-.242.401-.464.379-.857-.022-.386-.19-.65-.522-.82-.204-.104-.319-.106-5.424-.104-4.189.002-5.247.016-5.376.072"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconNotes;
