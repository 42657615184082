import { IconDashboard, IconDocument, IconGraphs, IconShadowpanel, IconUser } from 'components/Icons';

export interface ISidebarMenuItem {
  id: number;
  name: string;
  label: string;
  to: string;
  icon: React.ReactNode;
  showResponsive: boolean;
  isSeparator?: boolean;
}

const iconSize = 24;

export const sidebarMenuDefault: ISidebarMenuItem[] = [
  {
    id: 1,
    name: 'Account',
    label: 'HEADER.SIDEBAR.MY_ACCOUNT',
    to: '/user/my-account',
    icon: <IconUser height={iconSize} width={iconSize} />,
    showResponsive: true
  },
  {
    id: 2,
    name: '',
    label: '',
    to: '',
    icon: '',
    showResponsive: true,
    isSeparator: true
  },
  {
    id: 3,
    name: 'Dashboard',
    label: 'HEADER.SIDEBAR.DASHBOARD',
    to: '/dashboard',
    icon: <IconDashboard height={iconSize} width={iconSize} />,
    showResponsive: true
  },
  {
    id: 4,
    name: 'ShadepanelData',
    label: 'HEADER.SIDEBAR.DATA',
    to: '/shadepanels/data',
    icon: <IconGraphs height={iconSize} width={iconSize} />,
    showResponsive: true
  },
  {
    id: 5,
    name: 'Shadepanel',
    label: 'HEADER.SIDEBAR.SHADEPANELS',
    to: '/shadepanels',
    icon: <IconShadowpanel height={iconSize} width={iconSize} />,
    showResponsive: true
  },
  {
    id: 6,
    name: 'Notes',
    label: 'HEADER.SIDEBAR.NOTES',
    to: '/notes',
    icon: <IconDocument height={iconSize} width={iconSize} />,
    showResponsive: true
  }
  // {
  //   id: 7,
  //   name: 'Alerts',
  //   label: 'HEADER.SIDEBAR.ALERTS',
  //   to: '/alerts',
  //   icon: <IconAlert height={iconSize} width={iconSize} />,
  //   showResponsive: true
  // }
];
