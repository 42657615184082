import { Icon } from './icon.interface';

const IconFileImage = ({ height = 40, width = 31 }: Icon) => (
  <svg
    data-testid="icon-file-image"
    fill="none"
    height={height}
    viewBox="0 0 40 31"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.556 38.537H3.444A2.944 2.944 0 0 1 .5 35.593V3.444A2.944 2.944 0 0 1 3.444.5h18.062c.796 0 1.558.322 2.113.894l6.05 6.233c.533.549.831 1.285.831 2.05v25.916a2.944 2.944 0 0 1-2.944 2.944Z"
      fill="#fff"
      stroke="#4C4E58"
    />
    <path
      d="M4.535 27.484h21.93V12.516H4.535v14.968ZM6.14 14.323c0-.143.12-.258.267-.258h18.186c.148 0 .267.115.267.258v11.354c0 .143-.12.259-.267.259H6.407a.263.263 0 0 1-.267-.259V14.323Z"
      fill="#fff"
    />
    <path
      d="M24.326 14.58H6.674v10.84h17.652V14.58Zm-14.71.775c.886 0 1.605.693 1.605 1.548 0 .855-.719 1.549-1.605 1.549s-1.604-.694-1.604-1.549.718-1.548 1.604-1.548Zm-2.14 9.29L11.757 20l2.674 1.773 3.21-6.418 2.674 5.685 2.14-1.04 1.07 4.645H7.476Z"
      fill="#7FBC49"
    />
    <path
      d="M6.407 25.935h18.186a.263.263 0 0 0 .267-.258V14.323a.263.263 0 0 0-.267-.258H6.407a.263.263 0 0 0-.267.258v11.354c0 .143.12.258.267.258Zm.267-11.354h17.652v10.838H6.674V14.581Z"
      fill="#000"
    />
    <path
      d="M9.616 18.451c.886 0 1.605-.693 1.605-1.548 0-.855-.719-1.548-1.605-1.548s-1.604.693-1.604 1.548c0 .855.718 1.549 1.604 1.549Zm10.698 2.589-2.674-5.685-3.21 6.418L11.756 20l-4.28 4.645h16.047L22.453 20l-2.139 1.04Z"
      fill="#fff"
    />
  </svg>
);

export default IconFileImage;
