import { useEffect } from 'react';

import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Button, Spinner, ToastTemplate } from 'components';
import { useMutationShadepanelRefreshImage, useQueryShadepanelLastImage } from 'hooks';
import { dateFormat } from 'pipes';

interface ICamerasProps {
  shadepanelId: number;
  cameraSelectedId: number;
}

const CameraImage = ({ shadepanelId, cameraSelectedId }: ICamerasProps) => {
  const { t } = useTranslation();

  const {
    data: shaepanelLastImage,
    refetch: refecthLastImage,
    isLoading: isLoadingShadepanelLastImage
  } = useQueryShadepanelLastImage(shadepanelId, cameraSelectedId);

  const {
    mutate: mutationShaepanelRefreshImage,
    isLoading: isLoadingRefreshImage,
    isError: mutationRefreshImageError
  } = useMutationShadepanelRefreshImage(shadepanelId, cameraSelectedId);

  const onUpdateShadepanelImage = () => {
    mutationShaepanelRefreshImage();
  };

  useEffect(() => {
    refecthLastImage();
  }, [shadepanelId, cameraSelectedId]);

  useEffect(() => {
    if (mutationRefreshImageError) {
      toast.error(() => (
        <ToastTemplate description={t('SHADEPANEL_DETAIL_PAGE.IMAGE.REFRESH_ERROR')} title="Erreur" type="error" />
      ));
    }
  }, [mutationRefreshImageError]);

  return (
    <div>
      {!cameraSelectedId && <>No hay</>}
      {isLoadingShadepanelLastImage || isLoadingRefreshImage ? (
        <Spinner style={{ width: '100%', height: 350 }} />
      ) : (
        <div>
          {shaepanelLastImage?.photo ? (
            <div className="shadepanel-detail__image" style={{ backgroundImage: `url("${shaepanelLastImage?.photo}")` }}>
              <div className="image-date">{dateFormat(shaepanelLastImage?.timestamp, true, 'DD/MM/YYYY HH:mm:ss')}</div>
              <Button
                className="shadepanel-detail__refresh"
                htmlType="button"
                onClick={onUpdateShadepanelImage}
                size="small"
                type="secondary">
                {t('SHADEPANEL_DETAIL_PAGE.IMAGE.REFRESH')}
              </Button>
            </div>
          ) : (
            <div className="flex items-center justify-center md:col-span-2 shadepanel-detail__image shadepanel-detail__image--no-image">
              {t('SHADEPANEL_DETAIL_PAGE.IMAGE.NO_IMAGE')}
              <Button
                className="shadepanel-detail__refresh"
                htmlType="button"
                onClick={onUpdateShadepanelImage}
                size="small"
                type="secondary">
                {t('SHADEPANEL_DETAIL_PAGE.IMAGE.REFRESH')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CameraImage;
