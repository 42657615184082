import './ToastTemplete.scss';

interface IToastTemplateProps {
  title?: string;
  description: string;
  type?: 'error' | 'success';
}

const ToastTemplete = ({ title, description, type = 'success' }: IToastTemplateProps) => (
  <div className={`toast toast--${type}`}>
    <div className="mb-1 toast__title">{title}</div>
    <div className="toast__description">{description}</div>
  </div>
);

export default ToastTemplete;
