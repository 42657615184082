import { IAlert } from 'interfaces/alertInterface';

export const alerts: IAlert[] = [
  {
    activated_alert: 1,
    start_time: '2022-01-01 00:00:00',
    end_time: '2022-01-01 03:00:00',
    info: 'La température a dépassé les 5°C',
    plot: 1,
    type: 'warning'
  },
  {
    activated_alert: 1,
    start_time: '2022-01-01 00:00:00',
    end_time: '2022-01-01 03:00:00',
    info: 'La température a dépassé les 5°C',
    plot: 1,
    type: 'danger'
  }
];
