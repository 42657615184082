import { Link, useMatch, useResolvedPath } from 'react-router-dom';

interface ICustomLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  activeClassName?: string;
  onClose?: () => void;
}

const CustomLink = ({ to, children, className, activeClassName = '', onClose }: ICustomLinkProps) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const classNameGenerated = () => (match ? `${className} ${activeClassName}` : `${className}`);

  return (
    <Link className={classNameGenerated()} onClick={onClose} to={to}>
      {children}
    </Link>
  );
};

export default CustomLink;
