import { IPlantationCycle, IPlot, IPlotMeasureAlerts, IPlotShadepanels } from 'interfaces/plotInterface';

export const plots: IPlot[] = [
  {
    id: 1,
    name: 'Parcelle Potager',
    site: {
      id: 1,
      name: 'Arbois',
      location: 'Avenue Louis Philibert, 13290 Aix-en-Provence',
      farmer: 'Ombrea',
      organization: {
        id: 1,
        name: 'Compte Ombrea'
      }
    },
    zone: {
      type: 'Polygon',
      coordinates: [
        [
          [5.337443053722382, 43.49024343594966],
          [5.337465852499008, 43.49016268021375],
          [5.337656289339066, 43.490197706811266],
          [5.337620079517365, 43.49028040841923],
          [5.337443053722382, 43.49024343594966]
        ]
      ]
    },
    plantation: {
      id: 2,
      species: {
        id: 4,
        name: 'Maraîchage'
      },
      variety: {
        id: 1,
        name: 'Cabernet'
      },
      date_start: '2019-06-01',
      date_end: null
    }
  }
];

export const plotShadepanelsMock: IPlotShadepanels[] = [
  {
    id: 2,
    description: 'Rians 1A',
    date_creation: '2019-09-01',
    ip_address: '100.85.137.5',
    plot: {
      id: 2,
      name: 'Parcelle Rians 1'
    }
  },
  {
    id: 3,
    description: 'Rians 1B',
    date_creation: '2019-09-01',
    ip_address: '100.85.137.6',
    plot: {
      id: 2,
      name: 'Parcelle Rians 1'
    }
  }
];

export const plotPlantationCycle: IPlantationCycle[] = [
  {
    phase_range: {
      lower: '9999-01-01',
      upper: '9999-02-28'
    },
    phase_name: 'Forcage'
  },
  {
    phase_range: {
      lower: '9999-03-01',
      upper: '9999-12-31'
    },
    phase_name: 'Floraison'
  }
];

export const plotMeasureAlertsMock: IPlotMeasureAlerts[] = [
  {
    name: 'dli',
    unit: 'm-2 d-1',
    type: 'indicator',
    ref_id: 1
  },
  {
    name: 'uf',
    unit: '°C',
    type: 'indicator',
    ref_id: 2
  }
];
