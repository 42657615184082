import { useState } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconEyeHide, IconWarning, IconEyeShow } from 'components/Icons';

import './Input.scss';

export interface IInputProps {
  error?: string;
  label?: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  touched?: boolean;
  type?: 'text' | 'password';
  value: string;
}

/**
 *
 *
 * @param {IInputProps} { type, placeholder, label, touched, error, ...props }
 * @return {*}
 */
const Input = ({ type = 'text', placeholder, label, touched, error, ...props }: IInputProps) => {
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const onClickShowPassword = () => setPasswordShow(!passwordShow);

  return (
    <div
      className={classnames('input-control', {
        'input-control--focused': focused,
        'input-control--error': touched && error
      })}
    >
      {label && <label htmlFor={props.name}>{label}</label>}
      <div className="flex items-center justify-between">
        <div className="input-fake">
          <input
            className="flex input-control__input items-center"
            id={props.name}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder && placeholder}
            type={passwordShow ? 'text' : type}
            {...props}
          />
          <div className="flex input-fake__icon items-center">
            {touched && error && (
              <div className=" input-fake__icon--error">
                <IconWarning />
              </div>
            )}
            {type === 'password' && (
              <button className=" input-fake__icon--password" onClick={onClickShowPassword} tabIndex={-1} type="button">
                {passwordShow ? <IconEyeHide /> : <IconEyeShow />}
              </button>
            )}
          </div>
        </div>
      </div>
      {touched && error && <div className="input-control__hint input-control__hint--error">{t(error)}</div>}
    </div>
  );
};

export default Input;
