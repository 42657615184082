import { useQuery } from 'react-query';

import { shadepanelService } from 'services';

/**
 * Hook to get shadepanel sensors measures
 *    - type: graph, sensor, indicator
 *    - category: thermal, water, other
 *
 * @param {number} shadepanelId
 * @param {string} type
 * @param {string} category
 * @return {*}
 */
export default function useQueryShadepanelGraphMeasure(
  shadepanelId: number,
  refId: number,
  startDate: string,
  endDate: string,
  sector: number
) {
  return useQuery(
    [`ShadepanelMeasure - graph`, shadepanelId, 'graph', refId, startDate, endDate, sector],
    () => shadepanelService.getGraphMeasure(shadepanelId, 'graph', refId, startDate, endDate, sector),
    {
      enabled: !!shadepanelId && !!refId
    }
  );
}
