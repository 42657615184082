import './Subheader.scss';

export interface ISubheaderProps {
  dropdown?: React.ReactNode;
  tabs?: React.ReactNode;
}

const Subheader = ({ dropdown = null, tabs = null }: ISubheaderProps) => (
  <div className="flex items-center subheader">
    {dropdown && (
      <div className="subheader__select">
        <div className="">{dropdown}</div>
      </div>
    )}
    {tabs && <div className="flex justify-center subheader__tabs">{tabs}</div>}
  </div>
);

export default Subheader;
