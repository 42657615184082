import { Icon } from './icon.interface';

const IconTrash = ({ height = 32, width = 32, className = '' }: Icon) => (
  <svg className={className} fill="none" height={height} viewBox="0 0 32 32" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M22.903 12.75H11.097a1.18 1.18 0 0 0-1.18 1.18v9.445a3.542 3.542 0 0 0 3.541 3.541h7.083a3.542 3.542 0 0 0 3.542-3.541V13.93a1.18 1.18 0 0 0-1.18-1.18ZM21.25 9.916l-1.026-2.05c-.24-.48-.73-.783-1.266-.783h-3.916c-.536 0-1.027.304-1.267.783l-1.025 2.05h8.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.125"
    />
    <path
      d="M15.7 17.472a1.063 1.063 0 0 0-2.124 0H15.7Zm-2.124 4.721a1.062 1.062 0 1 0 2.125 0h-2.125Zm6.848-4.721a1.062 1.062 0 1 0-2.125 0h2.125Zm-2.125 4.721a1.062 1.062 0 1 0 2.125 0h-2.125Zm2.95-13.339a1.063 1.063 0 0 0 0 2.125V8.854Zm2.834 2.125a1.063 1.063 0 0 0 0-2.125v2.125Zm-11.333 0a1.063 1.063 0 0 0 0-2.125v2.125ZM9.916 8.854a1.063 1.063 0 0 0 0 2.125V8.854Zm3.66 8.618v4.721H15.7v-4.721h-2.125Zm4.723 0v4.721h2.125v-4.721h-2.125Zm2.95-6.493h2.834V8.854H21.25v2.125Zm-8.5-2.125H9.917v2.125h2.834V8.854Z"
      fill="currentColor"
    />
  </svg>
);

export default IconTrash;
