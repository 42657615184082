import { IconFlag, IconTemp, IconWaterDrop, IconWind } from 'components/Icons';

interface IIconSensorTemplate {
  template: JSX.Element;
  className: string;
}

export function generateIcon(sensorId: number) {
  const sensorTypeIcons: { [key: string]: IIconSensorTemplate } = {
    1: { className: 'green', template: <IconWind height={26} width={26} /> },
    2: { className: 'green', template: <IconWind height={26} width={26} /> },
    3: { className: 'green', template: <IconWind height={26} width={26} /> },
    4: { className: 'green', template: <IconTemp height={26} width={26} /> },
    6: { className: 'green', template: <IconTemp height={26} width={26} /> },
    7: { className: 'green', template: <IconWind height={26} width={26} /> },
    8: { className: 'green', template: <IconWind height={26} width={26} /> },
    9: { className: 'green', template: <IconWaterDrop height={26} width={26} /> },
    17: { className: 'green', template: <IconWaterDrop height={26} width={26} /> }
  };
  const defaultIcon = { className: 'green', template: <IconTemp height={26} width={26} /> };
  return sensorTypeIcons[sensorId] ? sensorTypeIcons[sensorId] : defaultIcon;
}

export function generateIconWidget(sensor: string) {
  const indicatorTypeIcons: { [key: string]: IIconSensorTemplate } = {
    'icon-wind': { className: 'green', template: <IconWind height={26} width={26} /> },
    'icon-temp': { className: 'green', template: <IconTemp height={26} width={26} /> },
    'icon-water-drop': { className: 'green', template: <IconWaterDrop height={26} width={26} /> },
    'icon-flag': { className: 'green', template: <IconFlag height={26} width={26} /> }
  };
  const defaultIcon = { className: 'green', template: <IconTemp height={26} width={26} /> };
  return indicatorTypeIcons[sensor] ? indicatorTypeIcons[sensor] : defaultIcon;
}
