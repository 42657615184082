import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Alert } from 'components';
import Button from 'components/Button/Button';
import { IconCheck, Logo } from 'components/Icons';
import Input from 'components/Input/Input';
import { useMutationPasswordRecovery } from 'hooks';

import './PasswordRecovery.scss';

const PasswordRecovery = () => {
  const { t } = useTranslation();

  const mutationPasswordRecovery = useMutationPasswordRecovery();

  const onSubmitHandler = async (values: { email: string }, resetForm: () => void) => {
    mutationPasswordRecovery.mutate(values);
    resetForm();
  };

  // Form
  const { isValid, dirty, errors, touched, getFieldProps, handleSubmit, resetForm } = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: async (values) => {
      onSubmitHandler(values, resetForm);
    },
    validationSchema: Yup.object({
      email: Yup.string().required('LOGIN.ERRORS.EMAIL_REQUIRED').email('LOGIN.ERRORS.EMAIL_VERIFICATION')
    })
  });

  return (
    <div className="flex items-center justify-center password-recovery">
      <div className="form md:w-col-80  sm:w-col-80 w-col-30 xs:w-col-80">
        <div className="flex items-center justify-center mb-4">
          <div className="logo text-center">
            <Logo height={60} width={60} />
            <div className="mt-1">ombreapp</div>
          </div>
        </div>
        <form className="" noValidate onSubmit={handleSubmit}>
          {mutationPasswordRecovery.isSuccess && (
            <div className="my-4">
              <Alert
                icon={<IconCheck />}
                label={t('RECOVERY_PASSWORD.SUCCESS.LABEL')}
                title={t('RECOVERY_PASSWORD.SUCCESS.TITLE')}
                type="success"
              />
            </div>
          )}
          <h3 className="password-recovery__title">{t('RECOVERY_PASSWORD.TITLE')}</h3>
          <div className="form-control">
            <Input
              label={t('RECOVERY_PASSWORD.EMAIL')}
              type="text"
              {...getFieldProps('email')}
              error={errors.email}
              touched={touched.email}
            />
          </div>

          <div className="form-control text-center">
            <Button block disabled={!(isValid && dirty)} htmlType="submit" type="primary">
              {t('RECOVERY_PASSWORD.BTN_SUBMIT')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordRecovery;
