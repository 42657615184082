import { useQuery } from 'react-query';

import { shadepanelService } from 'services';

/**
 * Hook to get cameras from shadepanel by React Query as key "ShadepanelCameras"
 *
 * @export
 * @param {number} shadepanelId
 * @return {*}
 */
export default function useQueryShadepanelCameras(shadepanelId: number) {
  return useQuery(['ShadepanelCameras', shadepanelId], () => shadepanelService.getShadepanelCameras(shadepanelId as number), {
    enabled: !!shadepanelId
  });
}
