import { useState } from 'react';

import classNames from 'classnames';

import { useClickOutside } from 'hooks';

import './DropdownMenu.scss';

export interface IDropdownMenuProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  isOpen: boolean;
  element: React.ReactNode;
  position?: 'left' | 'right' | 'default';
}

const DropdownMenu = ({ children, isOpen, element, position = 'default' }: IDropdownMenuProps) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(isOpen);
  const { ref } = useClickOutside(isOpenDropdown, setIsOpenDropdown);

  return (
    <div className="dropdown-menu" ref={ref}>
      <div className="dropdown-menu__container">
        <button
          className="dropdown-menu__trigger"
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
          onTouchStart={() => setIsOpenDropdown(!isOpenDropdown)}
          type="button"
        >
          {element}
        </button>

        <div
          className={classNames(`dropdown-menu__list dropdown-menu__list--${position}`, {
            'dropdown-menu__list--open': isOpenDropdown
          })}
        >
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
