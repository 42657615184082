import { HTMLAttributes, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { DropdownMenu, DropdownMenuItem, Modal } from 'components';
import { IconMore } from 'components/Icons';
import { IShadepanelSensorWidget } from 'interfaces/shadepanelInterface';
import { roundedNumber } from 'pipes';
import { iconService } from 'services';

import '../DataWidget.scss';

export interface ISensorWidgetProps extends HTMLAttributes<HTMLDivElement> {
  widget: IShadepanelSensorWidget;
  onDelete?: (widgetId: number) => void;
}

const SensorWidget = ({ widget, onDelete }: ISensorWidgetProps) => {
  const { t } = useTranslation();

  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onDeleteHandler = (widgetId: number) => {
    if (onDelete) onDelete(widgetId);
    setIsOpenDropdownMenu(!isOpenDropdownMenu);
  };

  const checkHaveInOnValues = () => typeof widget.data.out === 'number' || typeof widget.data.in === 'number';

  return (
    <div className="datawidget datawidget--sensor">
      <div className="datawidget__header flex justify-between">
        <div className="content-center flex items-center">
          <div>
            <div
              className={classNames(
                `datawidget__icon datawidget__icon--${
                  iconService.generateIconWidget(widget.icon).className
                } flex items-center justify-center`
              )}>
              {iconService.generateIconWidget(widget.icon).template}
            </div>
          </div>
          <div>
            <h4 className="datawidget__title">{widget.label}</h4>
            {widget.sector && (
              <div className="datawidget__label">
                {t('COMPONENTS.WIDGETS.SECTOR')} {widget.sector}
              </div>
            )}
          </div>
        </div>
        <div className="datawidget__more">
          <DropdownMenu element={<IconMore height={20} width={20} />} isOpen={isOpenDropdownMenu} position="right">
            <DropdownMenuItem>
              {onDelete && (
                <button onClick={() => onDeleteHandler(widget.id)} onTouchStart={() => onDeleteHandler(widget.id)} type="button">
                  {t('COMPONENTS.WIDGETS.DELETE')}
                </button>
              )}
            </DropdownMenuItem>
          </DropdownMenu>
        </div>
      </div>
      <div className="datawidget__body flex justify-center">
        {checkHaveInOnValues() ? (
          <>
            {typeof widget.data.out === 'number' && (
              <div className="w-col-50">
                <div className="datawidget-sensor__label">{t('DATA_TYPE.POSITION.out')}</div>
                <div className="datawidget-sensor__value">
                  {roundedNumber(widget.data.out, 1)}
                  {widget.data.unit}
                </div>
              </div>
            )}
            {typeof widget.data.in === 'number' && (
              <div className="w-col-50">
                <div className="datawidget-sensor__label">{t('DATA_TYPE.POSITION.in')}</div>
                <div className="datawidget-sensor__value">
                  {roundedNumber(widget.data.in, 1)}
                  {widget.data.unit}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="datawidget__empty flex items-center">{t('COMPONENTS.WIDGET_LIST.EMPTY.TITLE')}</div>
        )}
      </div>

      <Modal isOpen={isOpenModal} onToggleModal={() => setIsOpenModal(!isOpenModal)} title={widget.reference} width={600}>
        <div className="note-modal" data-testid="note-modal">
          <div className="my-3">
            <div className="note-modal__title">{t('COMPONENTS.WIDGETS.MODAL.TITLE')}</div>
            <p className="note-modal__content">Description</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SensorWidget;
