const IconDocument = ({ height = 24, width = 24, className }: { height?: number; width?: number; className?: string }) => (
  <svg
    className={className}
    data-testid="icon-document"
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.62 3.948h5.921c.063 0 .124.004.184.012 2.41.179 4.282 2.299 4.29 4.86v7.309c-.01 2.697-2.076 4.876-4.62 4.871H8.62c-2.545.005-4.612-2.175-4.62-4.872V8.82c.008-2.697 2.075-4.878 4.62-4.872Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.089"
    />
    <path
      d="M19.11 9.7c.492 0 .89-.402.89-.899a.894.894 0 0 0-.89-.898V9.7Zm-4.54-.9h-.889c0 .497.399.899.89.899V8.8Zm.89-4.902A.893.893 0 0 0 14.57 3a.894.894 0 0 0-.889.898h1.778Zm-2.025 10.06c.491 0 .89-.402.89-.898a.894.894 0 0 0-.89-.899v1.797Zm-6.81-1.797a.894.894 0 0 0-.89.899c0 .496.4.898.89.898v-1.797Zm3.405-1.644c.491 0 .89-.402.89-.898a.894.894 0 0 0-.89-.899v1.797ZM6.625 8.721a.894.894 0 0 0-.89.899c0 .496.4.898.89.898V8.721Zm7.945 8.68c.491 0 .89-.403.89-.9a.894.894 0 0 0-.89-.898V17.4Zm-7.945-1.798a.894.894 0 0 0-.89.899c0 .496.4.898.89.898v-1.797Zm12.485-7.7h-4.54V9.7h4.54V7.903Zm-3.65.898V3.9h-1.78v4.9h1.778Zm-2.025 3.361h-6.81v1.797h6.81v-1.797Zm-3.405-3.44H6.625v1.796h3.405V8.721Zm4.54 6.881H6.625V17.4h7.945v-1.797Z"
      fill="currentColor"
    />
  </svg>
);

export default IconDocument;
