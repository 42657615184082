import { useTranslation } from 'react-i18next';

import { CustomLink } from 'components';
import IconDashboard from 'components/Icons/IconDashboard';
import IconGraphs from 'components/Icons/IconGraphs';
import IconNotes from 'components/Icons/IconNotes';
import IconShadowpanel from 'components/Icons/IconShadowpanel';

import './Footer.scss';

const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="grid grid-cols-4 nav">
        <div className="nav__item">
          <CustomLink activeClassName="active" to="/dashboard">
            <div className="nav-icon">
              <IconDashboard />
            </div>
            <div className="nav__label">{t('FOOTER.NAV.DASHBOARD')}</div>
          </CustomLink>
        </div>

        <div className="nav__item">
          <CustomLink activeClassName="active" to="/shadepanels/data">
            <div className="nav-icon">
              <IconGraphs />
            </div>
            <div className="nav__label">{t('FOOTER.NAV.DATA')}</div>
          </CustomLink>
        </div>

        <div className="nav__item">
          <CustomLink activeClassName="active" to="/shadepanels">
            <div className="nav-icon">
              <IconShadowpanel />
            </div>
            <div className="nav__label">{t('FOOTER.NAV.SHADOWPANEL')}</div>
          </CustomLink>
        </div>

        <div className="nav__item">
          <CustomLink activeClassName="active" to="/notes">
            <div className="nav-icon">
              <IconNotes />
            </div>
            <div className="nav__label">{t('FOOTER.NAV.NOTES')}</div>
          </CustomLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
