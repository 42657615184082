import { t } from 'i18next';

import { Datepicker, Dropdown } from 'components';
import { IDropdownOption } from 'components/Dropdown/Dropdown';
import { ITypeActions } from 'interfaces/noteInterface';
import { IPlot, IPlotShadepanels } from 'interfaces/plotInterface';

export interface INotesSearchProps {
  plots?: IPlot[];
  plotShadepanels?: IPlotShadepanels[];
  typesAction?: ITypeActions[];
  onChangePlot: (plot: IDropdownOption) => void;
  onChangeShadowPanel: (shadowPanel: IDropdownOption) => void;
  onChangeTypeAction: (treatment: IDropdownOption) => void;
  onChangeDate: (date: string) => void;
}

const NotesSearch = ({
  plots,
  plotShadepanels,
  typesAction,
  onChangePlot,
  onChangeShadowPanel,
  onChangeTypeAction,
  onChangeDate
}: INotesSearchProps) => (
  <div className="notes__search">
    <div className="gap-2 grid grid-cols-4 notes-search sm:grid-cols-2">
      <div className="">
        {plots && (
          <Dropdown
            allowClear
            bindLabel="name"
            bindValue="id"
            items={plots}
            label={t('NOTES_PAGE.SEARCH.PLOT.LABEL')}
            onSelectOption={onChangePlot}
            placeholder={t('NOTES_PAGE.SEARCH.PLOT.PLACEHOLDER')}
            selectFirstOption={false}
          />
        )}
      </div>
      <div>
        {plotShadepanels && (
          <Dropdown
            allowClear
            bindLabel="name"
            bindValue="id"
            defaultValue={plotShadepanels[0].id}
            items={plotShadepanels}
            label={t('NOTES_PAGE.SEARCH.SHADEPANEL.LABEL')}
            onSelectOption={onChangeShadowPanel}
            placeholder={t('NOTES_PAGE.SEARCH.SHADEPANEL.PLACEHOLDER')}
            selectFirstOption
          />
        )}
      </div>
      <div>
        {typesAction && (
          <Dropdown
            allowClear
            bindLabel="label"
            bindValue="id"
            items={typesAction}
            label={t('NOTES_PAGE.CREATE.FORM.TYPE_ACTION.LABEL')}
            onSelectOption={onChangeTypeAction}
            placeholder={t('NOTES_PAGE.SEARCH.TYPE_ACTION.PLACEHOLDER')}
            selectFirstOption={false}
          />
        )}
      </div>
      <div>
        <Datepicker
          allowClear
          disableFuture
          label={t('NOTES_PAGE.SEARCH.DATE.LABEL')}
          onChangeDate={onChangeDate}
          placeholder={t('NOTES_PAGE.SEARCH.DATE.PLACEHOLDER')}
        />
      </div>
    </div>
  </div>
);

export default NotesSearch;
