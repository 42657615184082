import classNames from 'classnames';

import './Header.scss';

interface IHeaderProps {
  title: string;
  showSidebar: boolean;
}

const Header = ({ title, showSidebar }: IHeaderProps) => (
  <div
    className={classNames(`header`, {
      'header--sidebar': showSidebar
    })}>
    <div className="content-center flex items-center justify-between">
      <div className="header__title">{title}</div>
      <div className="nav-right">
        <div className="flex items-center justify-center nav-right__item ">{/* <IconAlert height={20} width={20} /> */}</div>
      </div>
    </div>
  </div>
);

export default Header;
