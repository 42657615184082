/* eslint-disable react/button-has-type */

import classNames from 'classnames';

import './Button.scss';

export interface IButtonProps {
  block?: boolean;
  children: React.ReactNode;
  darkMode?: boolean;
  disabled?: boolean;
  htmlType: JSX.IntrinsicElements['button']['type'];
  onClick?: () => void;
  size?: 'small' | 'default' | 'big';
  type: 'link' | 'primary' | 'secondary' | 'ghost';
  className?: string;
}

/**
 *
 *
 * @param {IButtonProps} {
 *   block = false,
 *   children,
 *   disabled = false,
 *   darkMode = false,
 *   htmlType = 'button',
 *   onClick,
 *   size = 'default',
 *   type
 * }
 */
const Button = ({
  block = false,
  children,
  className = '',
  darkMode = false,
  disabled = false,
  htmlType = 'button',
  onClick,
  size = 'default',
  type
}: IButtonProps) => (
  <button
    className={classNames(`${className} btn btn--${type} btn--${size}`, {
      'btn--block': block,
      'btn--disabled': disabled,
      'btn--dark': darkMode
    })}
    disabled={disabled}
    onClick={onClick}
    type={htmlType}
  >
    {children}
  </button>
);

export default Button;
