import { IPlantationCycle, IPlot, IPlotShadepanels } from 'interfaces/plotInterface';
import { API_ROUTES } from 'services/api/urls';
import axiosInstance from 'services/interceptor';

/**
 * Get all plots
 * @version v2
 * @return {*}  {Promise<IPlot[]>}
 */
export const getPlots = async (): Promise<IPlot[]> => {
  const plotsResponse = await axiosInstance.get<IPlot[]>(API_ROUTES.getPlots());
  return plotsResponse.data;
};

/**
 * Get plot by id
 *
 * @param {number} plotId
 * @return {*}  {Promise<IPlot>}
 */
export const getPlot = async (plotId: number): Promise<IPlot> => {
  const plotResponse = await axiosInstance.get<IPlot>(API_ROUTES.getPlot(plotId));
  return plotResponse.data;
};

/**
 * Get shadepanels for a plot
 * @version v2
 * @param {number} plotId
 * @return {*}  {Promise<IPlotShadepanels[]>}
 */
export const getPlotShadepanels = async (plotId: number): Promise<IPlotShadepanels[]> => {
  const plotShadepanelsResponse = await axiosInstance.get<IPlotShadepanels[]>(API_ROUTES.getPlotShadepanels(plotId));
  return plotShadepanelsResponse.data;
};

/**
 *Get plantation cycle for a plot
 *
 * @param {number} plotId
 * @return {*}  {Promise<IPlantationCycle[]>}
 */
export const getPlotPlantationCycle = async (plotId: number): Promise<IPlantationCycle[]> => {
  const plotPlantationCycleResponse = await axiosInstance.get<IPlantationCycle[]>(API_ROUTES.getPlotPlantationCycle(plotId));
  return plotPlantationCycleResponse.data;
};

/**
 * Patch plot by id (only name)
 *
 * @param {number} plotId
 * @param {string} plotName
 * @return {*}  {Promise<IPlot>}
 */
export const updatePlot = async (plotId: number, plotName: string): Promise<IPlot> => {
  const updatedPlot = await axiosInstance.patch<IPlot>(API_ROUTES.updatePlot(plotId), { name: plotName });
  return updatedPlot.data;
};
