import { useQuery } from 'react-query';

import { IUserDocument } from 'interfaces/userInterface';
import { userService } from 'services';

/**
 * Hook to get user documents by React Query as key "UserDocuments"
 *
 * @export
 * @return {*}
 */
export default function useQueryUserDocuments() {
  return useQuery<IUserDocument[]>('UserDocuments', () => userService.getDocuments());
}
