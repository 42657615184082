import { Icon } from './icon.interface';

const IconWarning = ({ height = 24, width = 24, className = '' }: Icon) => (
  <svg className={className} fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M19 13v-2a6 6 0 0 0-6-6h-2a6 6 0 0 0-6 6v2a6 6 0 0 0 6 6h2a6 6 0 0 0 6-6Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path d="M12 12V8" stroke="currentColor" strokeLinecap="round" strokeWidth="1.5" />
    <path d="M12 14.5a.5.5 0 1 1-.001 1.001A.5.5 0 0 1 12 14.5Z" fill="currentColor" />
    <path d="M12 16a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" fill="currentColor" />
  </svg>
);

export default IconWarning;
