import { Icon } from './icon.interface';

const IconArrowUp = ({ height = 32, width = 32, className = '' }: Icon) => (
  <svg className={className} fill="none" height={height} viewBox=" 0 0 32 32" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M25.153 20.967c-.045-.155-.892-1.034-3.864-4.005-4.188-4.187-4.004-4.03-4.53-3.876-.21.06-.726.555-4.047 3.876-4.181 4.18-4.031 4.006-3.877 4.522.093.309.538.755.848.847.51.153.389.255 4.016-3.366l3.3-3.294 3.302 3.293c3.497 3.488 3.484 3.477 3.92 3.395.25-.046.62-.32.792-.584a.967.967 0 0 0 .14-.808"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconArrowUp;
