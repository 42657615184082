import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import Modal from 'components/Modal/Modal';
import Skeleton from 'components/Skeleton/Skeleton';
import { useQueryShadepanelSensorMeasure } from 'hooks';
import { IValuesDistances } from 'interfaces/shadepanelInterface';
import { roundedNumber } from 'pipes';
import { iconService } from 'services';

export interface IDataSensorPositionMeasureProps {
  refId: number;
  shadepanelId: number;
  refetch?: boolean;
  sector?: number;
}

const DataSensorPositionMeasure = ({ refId, shadepanelId, refetch = false, sector }: IDataSensorPositionMeasureProps) => {
  const { t } = useTranslation();

  const {
    data: sensor,
    refetch: refetchSensor,
    isLoading
  } = useQueryShadepanelSensorMeasure(shadepanelId, refId, sector as number);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const generateArray = (distances: IValuesDistances) =>
    Object.keys(distances).map((key) => ({ label: key, values: distances[key] }));

  const checkHaveInOnValues = () => {
    if (sensor) {
      return generateArray(sensor.data.distances)[0].values.out || generateArray(sensor.data.distances)[0].values.in;
    }
    return null;
  };

  useEffect(() => {
    refetchSensor();
  }, [refetch]);

  return (
    <>
      {isLoading && <Skeleton count={1} style={{ height: 143, width: '100%', borderRadius: 8 }} />}
      {!isLoading && sensor && (
        <div className="datawidget">
          <div className="datawidget-sensor">
            <div className="datawidget__header flex justify-between">
              <div className="content-center flex items-center">
                <div>
                  <div
                    className={classNames(
                      `datawidget__icon datawidget__icon--${
                        iconService.generateIconWidget(sensor.icon).className
                      } flex  items-center justify-center`
                    )}>
                    {iconService.generateIconWidget(sensor.icon).template}
                  </div>
                </div>
                <div>
                  <h4 className="datawidget__title">{sensor.label}</h4>
                  {sensor.sector && (
                    <div className="datawidget__label">
                      {t('COMPONENTS.WIDGETS.SECTOR')} {sensor.sector}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="datawidget_body">
              {checkHaveInOnValues() ? (
                <div className="flex justify-center">
                  {typeof generateArray(sensor.data.distances)[0].values.in === 'number' && (
                    <div className="w-col-50">
                      <div className="datawidget-sensor__label">{t('DATA_TYPE.POSITION.in')}</div>
                      <div className="datawidget-sensor__value">
                        {sensor.data.distances &&
                          generateArray(sensor.data.distances).map((distance, key) => (
                            <div className="distances flex" key={uuidv4()}>
                              <div className="distances__value">
                                <div className="distances__value  flex items-center justify-center">{distance.label}cm</div>
                              </div>
                              {sensor.data.distances && (
                                <div
                                  className={classNames('distances__line flex items-center', {
                                    'distances__line--first': key === 0,
                                    'distances__line--last': key === generateArray(sensor.data.distances).length - 1
                                  })}>
                                  <div className="line-inside" />
                                </div>
                              )}
                              <div className="distances__value flex items-center justify-center">
                                {roundedNumber(distance.values.in, 1)}
                                {sensor.unit}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}

                  {typeof generateArray(sensor.data.distances)[0].values.out === 'number' && (
                    <div className="w-col-50">
                      <div className="datawidget-sensor__label">{t('DATA_TYPE.POSITION.out')}</div>
                      <div className="datawidget-sensor__value">
                        {sensor.data.distances &&
                          generateArray(sensor.data.distances).map((distance, key) => (
                            <div className="distances flex" key={uuidv4()}>
                              <div className="distances__value">
                                <div className="distances__value  flex items-center justify-center">{distance.label}cm</div>
                              </div>
                              {sensor.data.distances && (
                                <div
                                  className={classNames('distances__line flex items-center', {
                                    'distances__line--first': key === 0,
                                    'distances__line--last': key === generateArray(sensor.data.distances).length - 1
                                  })}>
                                  <div className="line-inside" />
                                </div>
                              )}
                              <div className="distances__value flex items-center justify-center">
                                {roundedNumber(distance.values.out, 1)}
                                {sensor.unit}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="datawidget__empty flex items-center">{t('COMPONENTS.WIDGET_LIST.EMPTY.TITLE')}</div>
              )}
            </div>
          </div>

          <Modal isOpen={isOpenModal} onToggleModal={() => setIsOpenModal(!isOpenModal)} title={sensor.label} width={600}>
            <div className="note-modal" data-testid="note-modal">
              <div className="my-3">
                <div className="note-modal__title">{t('COMPONENTS.WIDGETS.MODAL.TITLE')}</div>
                <p className="note-modal__content">{sensor.name}</p>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default DataSensorPositionMeasure;
