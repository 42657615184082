import { Icon } from './icon.interface';

const IconArrowLeft = ({ height = 32, width = 32, className = '' }: Icon) => (
  <svg className={className} fill="none" height={height} viewBox=" 0 0 32 32" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M20.967 8.847c-.155.045-1.034.892-4.005 3.864-4.187 4.188-4.03 4.004-3.876 4.53.06.21.555.726 3.876 4.047 4.18 4.181 4.006 4.031 4.522 3.877.309-.093.755-.538.847-.848.153-.51.255-.389-3.366-4.016l-3.294-3.3 3.293-3.302c3.488-3.497 3.477-3.484 3.395-3.92-.046-.25-.32-.62-.584-.792a.967.967 0 0 0-.808-.14"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconArrowLeft;
