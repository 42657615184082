import { Icon } from './icon.interface';

const IconArrowLeft = ({ height = 24, width = 24, className = '' }: Icon) => (
  <svg className={className} fill="none" height={height} viewBox=" 0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.8 5.6H6.6A1.6 1.6 0 0 0 5 7.2v11.2A1.6 1.6 0 0 0 6.6 20h11.2a1.6 1.6 0 0 0 1.6-1.6V7.2a1.6 1.6 0 0 0-1.6-1.6ZM15.4 4v3.2M9 4v3.2m-4 3.2h14.4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconArrowLeft;
