import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Datepicker } from 'components';
import { alertMock } from 'mocks';

import AlertListItem from './AlertListItem';

import './AlertList.scss';

const AlertList = () => {
  const { t } = useTranslation();

  const { alerts } = alertMock;

  const onChangeDate = (_date: string) => _date;

  return (
    <div className="alert-list my-4">
      <div className="flex justify-between">
        <div className="flex items-center md:justify-between md:w-col-100 w-col-50">
          <div className="w-col-50">{t('ALERTS.LIST.FILTER')}</div>
          <div className="md:w-col-40 mx-1 w-col-20">
            <Datepicker onChangeDate={(date) => onChangeDate(date)} placeholder={t('COMPONENTS.DATEPICKER.PLACEHOLDER')} />
          </div>
        </div>
      </div>

      <div className="gap-3 grid grid-cols-3 md:grid-cols-1 my-4">
        {alerts &&
          alerts.map((alert) => (
            <div key={uuidv4()}>
              <AlertListItem alert={alert} />
            </div>
          ))}
      </div>

      <div className="" />
    </div>
  );
};

export default AlertList;
