import { useMutation, useQueryClient } from 'react-query';

import { shadepanelService } from 'services';

/**
 * Hook to mutate shadepanel by React Query (Only name)
 *
 * @export
 * @param {number} shadepanelId
 * @return {*}
 */
export default function useMutationShadepanel(shadepanelId: number) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ shadepanelName }: { shadepanelName: string }) => shadepanelService.updateShadepanel(shadepanelId, shadepanelName),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['ShadepanelSelected', shadepanelId], data);
        queryClient.invalidateQueries(['PlotsShadepanel']);
      }
    }
  );
}
