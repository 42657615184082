import { createStore, combineReducers, applyMiddleware, Action } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkDispatch } from 'redux-thunk';

import authReducer, { AuthState } from './reducers/authReducer';
import uiReducer, { UiState } from './reducers/uiReducer';

export interface AppStore {
  auth: AuthState;
  ui: UiState;
}

const reducers = combineReducers<AppStore>({
  auth: authReducer,
  ui: uiReducer
});

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof store.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, object, Action>;

export default store;
