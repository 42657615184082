import { useEffect, useRef, useState } from 'react';

import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { Button, DateRangePicker, Skeleton } from 'components';
import CardMeasure from 'components/Card/CardMeasure';
import { IRangeDateObject } from 'components/DateRangePicker/DateRangePicker';
import { useQueryShadepanelGraphMeasure } from 'hooks';
import { IChartZoomCoordinates } from 'interfaces/shadepanelInterface';

import './DataGraphMeasure.scss';

export interface IDataGraphMeasureProps extends React.HTMLAttributes<HTMLDivElement> {
  refId: number;
  shadepanelId: number;
  height: number;
  refetch?: boolean;
  sector?: number;
}

const DataGraphMeasure = ({ refId, shadepanelId, height, refetch = false, sector }: IDataGraphMeasureProps) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const [rangeDates, setRangeDates] = useState<IRangeDateObject>();
  const [seriesSelection, setSeriesSelection] = useState<number[][] | null>(null);
  const chartRef = useRef<any>(null);

  const {
    data: graph,
    refetch: refetchGraph,
    isLoading
  } = useQueryShadepanelGraphMeasure(shadepanelId, refId, startDate as string, endDate as string, sector as number);

  const generateChartOptions = (unit: string, label: string): ApexOptions => ({
    chart: {
      height: 350,
      zoom: {
        enabled: true
      },
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      },
      events: {
        // https://codepen.io/luisramirez/pen/RwxrOPN?editors=0010
        zoomed(_, { xaxis }: IChartZoomCoordinates) {
          setStartDate(dayjs(xaxis.min).year(dayjs().year()).format('YYYY-MM-DD HH:mm'));
          setEndDate(dayjs(xaxis.max).year(dayjs().year()).format('YYYY-MM-DD HH:mm'));
        },
        legendClick() {
          setSeriesSelection(chartRef.current.chart.w.globals.series);
        }
      }
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value) => `${value ? value.toFixed(2) : ''} ${unit}`
      },
      x: {
        formatter: (value) => dayjs(value).utc(false).format('DD/MM/YYYY HH:mm')
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'round',
      width: 4
    },
    grid: {
      strokeDashArray: 20
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false
      }
    },
    yaxis: {
      title: {
        text: `${label} ${unit}`
      },
      labels: {
        formatter: (value) => value.toFixed(2)
      }
    }
  });

  const onChangeDate = (rangeDate: IRangeDateObject | undefined) => {
    if (rangeDate) {
      const range = {
        start: rangeDate.start.toString(),
        end: rangeDate.end.toString()
      };

      setStartDate(range.start);
      setEndDate(range.end);
      setRangeDates(range);
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
      setRangeDates(undefined);
    }
  };

  const onReset = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setRangeDates(undefined);
    setSeriesSelection(null);
  };

  useEffect(() => {
    if (graph?.data) {
      if (graph?.data.length > 4 && chartRef.current && chartRef.current.chart) {
        if (seriesSelection !== null) {
          for (let i = 0; i < graph.data.length; i += 1) {
            const seriesNameToToggle = graph.data[i].name;            
            if (seriesSelection[i].length > 0) {
              chartRef.current.chart.showSeries(seriesNameToToggle);
            } else {
              chartRef.current.chart.hideSeries(seriesNameToToggle);
            }
          }
        } else {
          for (let i = 4; i < graph.data.length; i += 1) {
            const seriesNameToToggle = graph.data[i].name;
            chartRef.current.chart.hideSeries(seriesNameToToggle);
          }
        }
      }
    }
  }, [graph]);

  useEffect(() => {
    refetchGraph();
  }, [refetch]);

  return (
    <div className="graph-measure">
      {isLoading && <Skeleton count={1} style={{ height: 465, width: '100%', borderRadius: 8 }} />}
      {!isLoading && graph ? (
        <CardMeasure sector={graph.sector} title={graph.label}>
          <div className="flex items-center justify-end my-2 sm:block">
            <div className="mr-2 sm:w-col-100">
              <Button className="my-1 w-col-100 " htmlType="submit" onClick={onReset} type="secondary">
                {t('COMPONENTS.DATA_GRAPH_MEASURE.RESET')}
              </Button>
            </div>
            <div className="graph-measure__daterangepicker">
              <DateRangePicker
                defaultValue={rangeDates}
                disableFuture
                onChangeDate={onChangeDate}
                placeholder={t('COMPONENTS.DATA_GRAPH_MEASURE.DATE_RANGE.PLACEHOLDER')}
              />
            </div>
          </div>
          <Chart
            height={height}
            options={generateChartOptions(graph.unit, graph.label)}
            ref={chartRef}
            series={graph.data as ApexAxisChartSeries}
          />
        </CardMeasure>
      ) : null}
    </div>
  );
};

export default DataGraphMeasure;
