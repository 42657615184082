import { useQuery } from 'react-query';

import { shadepanelService } from 'services';

/**
 * Hook to get displayable widgets from shadepanel state by React Query as key "ShadepanelDisplayableWidgets"
 *
 * @export
 * @param {number} shadepanelId
 * @return {*}
 */
export default function useQueryShadepanelDisplayableWidgets(shadepanelId: number) {
  return useQuery(
    ['ShadepanelDisplayableWidgets', shadepanelId],
    () => shadepanelService.getShadepanelDislayableWidgets(shadepanelId as number),
    {
      enabled: !!shadepanelId
    }
  );
}
