import { useState } from 'react';

import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import { IconArrowRight } from 'components/Icons';

import './Collapse.scss';

export interface ICollapseProps {
  children: React.ReactNode;
  controls?: React.ReactNode;
  title: string;
  label?: string;
  showArrow?: boolean;
  theme?: 'white' | 'default';
}

const Collapse = ({ children, controls, title, label, showArrow = true, theme = 'default' }: ICollapseProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const variantsArrow = {
    open: { rotate: -90, transition: { duration: 0.2 } },
    closed: { rotate: 0, transition: { duration: 0.3 } }
  };

  const handlerIsOpen = () => {
    if (showArrow) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      className={classNames('collapse', {
        'collapse--white': theme === 'white'
      })}>
      <div className="flex items-center justify-between">
        <button className="collapse__header" onClick={() => handlerIsOpen()} type="button">
          <div className="collapse__title">{title}</div>
          {true && <div className="collapse__label">{label}</div>}
        </button>
        <div className="flex">
          {controls && <div className="collapse__controls mr-1">{controls}</div>}
          {showArrow && (
            <AnimatePresence>
              <motion.button
                animate={isOpen ? 'open' : 'closed'}
                className="collapse__arrow"
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                variants={variantsArrow}>
                <IconArrowRight height={28} width={28} />
              </motion.button>
            </AnimatePresence>
          )}
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.3 }
            }}
            className="collapse__content"
            exit={{
              opacity: 0,
              y: -10,
              transition: { duration: 0.3 }
            }}
            initial={{
              opacity: 0,
              y: -10
            }}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Collapse;
