import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';

import {
  IconWeatherCloudy,
  IconWeatherFewClouds,
  IconWeatherMist,
  IconWeatherRain,
  IconWeatherSnow,
  IconWeatherThunderStorm
} from 'components/Icons';
import IconWaterDrop from 'components/Icons/IconWaterDrop';
import IconWeatherClearSky from 'components/Icons/IconWeatherClearSky';
import IconWind from 'components/Icons/IconWind';
import { IWeatherDataForecast } from 'interfaces/weatherInterface';

dayjs.extend(utc);

interface WeatherDayItemProps {
  isActive?: boolean;
  hour: IWeatherDataForecast;
}

/**
 *
 *
 * @param {WeatherDayItemProps} { isActive = false, day }
 */
const WeatherHourItem = ({ isActive = false, hour }: WeatherDayItemProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames('flex flex-col justify-center  text-center weather-day weather-hours__item', {
        'weather-hours__item--active': isActive
      })}>
      <div className="weather-hours__control weather-hours__hour">
        {dayjs(hour.datetime).utc(true).format('HH')}h{dayjs(hour.datetime).utc(true).format('mm')}
      </div>
      <div className="flex items-center justify-center weather-hours__control">
        <div className="flex items-center justify-center  weather-hours__icon">
          {hour.weather === 'Clear sky' && <IconWeatherClearSky height={35} width={35} />}
          {hour.weather === 'Few clouds' && <IconWeatherFewClouds height={35} width={35} />}
          {hour.weather === 'Cloudy' && <IconWeatherCloudy height={35} width={35} />}
          {hour.weather === 'Mist' && <IconWeatherMist height={35} width={35} />}
          {hour.weather === 'Rain' && <IconWeatherRain height={35} width={35} />}
          {hour.weather === 'Snow' && <IconWeatherSnow height={35} width={35} />}
          {hour.weather === 'Thunderstorm' && <IconWeatherThunderStorm height={35} width={35} />}
        </div>
      </div>
      <div className="weather-hours__control weather-hours__temperature">{hour.temp}°</div>
      <div className="weather-hours__control">
        <div className="">
          <IconWind height={25} width={25} />
        </div>
        <div className=" weather-hours__wind">
          {hour.wind_speed}
          <br />
          km/h
        </div>
      </div>

      <div className="weather-hours__control">
        <div className="">
          <IconWaterDrop height={25} width={25} />
        </div>
        <div className=" weather-hours__wind-gust">
          {t('WEATHER.DAY.HUMIDITY')}
          <br />
          {hour.humidity}%
        </div>
      </div>
    </div>
  );
};

export default WeatherHourItem;
