import { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { NoteItem, Empty, Tabs, Pagination } from 'components';
import { IDropdownOption } from 'components/Dropdown/Dropdown';
import { ITabOption } from 'components/Tabs/Tabs';
import { NotesSearch, NotesCreate } from 'elements';
import { useQueryPlots, useQueryPlotShadepanels, useQueryNotes, useQueryTypesAction } from 'hooks';
import { PAGE_SIZE } from 'services/api/apiService';

import './Notes.scss';

interface INotesProps {
  isMobile: boolean;
}

const Notes = ({ isMobile }: INotesProps) => {
  const { t } = useTranslation();

  // Plots
  const [plotId, setPlotId] = useState<number>();
  const { data: plots } = useQueryPlots();
  const { data: plotShadepanels } = useQueryPlotShadepanels(plotId as number);

  // Shadepanel
  const [shadepanelId, setShadepanelId] = useState<number>();

  // Treatement
  const [treatment, setTreatment] = useState<string>();
  const { data: typesAction } = useQueryTypesAction();

  // Date
  const [date, setDate] = useState<string>();

  // Notes
  const [page, setCurrentPage] = useState<number>(1);
  const { data: notes } = useQueryNotes(
    date as string,
    page,
    PAGE_SIZE,
    plotId as number,
    shadepanelId as number,
    treatment as string
  );

  // Tab mobile
  const tabsMobile = [
    { id: 'my-notes', label: t('NOTES_PAGE.TABS.MY_NOTES') },
    { id: 'create-note', label: t('NOTES_PAGE.TABS.CREATE_NOTE') }
  ];
  const [tabSelected, setTabSelected] = useState<string>('my-notes');

  const onChangeTab = (tab: ITabOption) => {
    if (!tab) return;
    setTabSelected(tab.value as string);
  };

  const onChangeShadowPanel = (shadowPanel: ITabOption) => {
    if (!shadowPanel) return;
    setShadepanelId(shadowPanel.value as number);
  };

  const onChangePlot = (plot: IDropdownOption) => {
    if (!plot) return;
    setShadepanelId(undefined);
    setPlotId(plot.value as number);
  };

  const onChangeTypeAction = (treatmentOption: IDropdownOption) => {
    if (!treatmentOption) return;
    setTreatment(treatmentOption.value as string);
  };

  const onChangeDate = (dateChanged: string) => {
    setDate(dateChanged);
  };

  const onPageChange = (changeToPage: string | number) => {
    if (typeof changeToPage === 'number') {
      setCurrentPage(changeToPage);
    }
  };

  return (
    <div
      className={classNames('notes', {
        'notes--mobile': isMobile,
        'container-fluid': !isMobile
      })}>
      {!isMobile && (
        <div className="">
          <div className="flex">
            <div className="my-4 notes__container w-col-70">
              <div className="notes__title">{t('NOTES_PAGE.SEARCH.TITLE')}</div>
              <div className="my-4 notes__widget">
                {plots && (
                  <NotesSearch
                    onChangeDate={onChangeDate}
                    onChangePlot={onChangePlot}
                    onChangeShadowPanel={onChangeShadowPanel}
                    onChangeTypeAction={onChangeTypeAction}
                    plotShadepanels={plotShadepanels}
                    plots={plots}
                    typesAction={typesAction}
                  />
                )}
              </div>
              <div>
                <div className="notes__title">
                  {t('NOTES_PAGE.LIST.TITLE')} ({notes && notes.pagination.total})
                </div>
                <div className="notes__widget">
                  <div className="notes__list">
                    {notes && notes.items.map((note) => <NoteItem key={note.id} note={note} />)}
                    {notes && notes.items.length === 0 && (
                      <Empty height={300} message={t('NOTES_PAGE.LIST.EMPTY.MESSAGE')} title={t('NOTES_PAGE.LIST.EMPTY.TITLE')} />
                    )}
                  </div>
                </div>

                {notes && notes.pagination.total > notes.pagination.perPage && (
                  <div className="flex justify-end my-2">
                    <Pagination
                      currentPage={page}
                      onPageChange={onPageChange}
                      perPage={notes.pagination.perPage}
                      total={notes.pagination.total}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="w-col-30">
              <NotesCreate />
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="my-4">
          <div className="flex justify-center notes__tabs">
            <Tabs
              bindLabel="label"
              bindValue="id"
              className="w-col-50"
              defaultValue={tabSelected}
              items={tabsMobile}
              onSelectOption={(event) => onChangeTab(event)}
            />
          </div>
          {tabSelected === 'my-notes' && (
            <div className="notes__my-notes">
              <div className="my-4">
                <div className="my-4 notes__title">{t('NOTES_PAGE.SEARCH.TITLE')}</div>
                <div className="">
                  {plotShadepanels && plots && (
                    <NotesSearch
                      onChangeDate={onChangeDate}
                      onChangePlot={onChangePlot}
                      onChangeShadowPanel={onChangeShadowPanel}
                      onChangeTypeAction={onChangeTypeAction}
                      plotShadepanels={plotShadepanels}
                      plots={plots}
                      typesAction={typesAction}
                    />
                  )}
                </div>
                <div className="my-4 notes__list">
                  <div className="notes__title">
                    {t('NOTES_PAGE.LIST.TITLE')} ({notes && notes.pagination.total})
                  </div>
                  <div className="">
                    <div className="notes__list">
                      {notes && notes.items.map((note) => <NoteItem key={note.id} note={note} />)}
                      {notes && notes.items.length === 0 && (
                        <Empty
                          height={300}
                          message={t('NOTES_PAGE.LIST.EMPTY.MESSAGE')}
                          title={t('NOTES_PAGE.LIST.EMPTY.TITLE')}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {tabSelected === 'create-note' && (
            <div className="notes__create">
              <div className="my-4">
                <NotesCreate />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notes;
