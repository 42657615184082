import { Icon } from './icon.interface';

const IconWeatherMist = ({ height = 100, width = 100 }: Icon) => (
  <svg fill="none" height={height} viewBox="0 0 100 100" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M73.1213 73.265H39.8318V73.2405C27.2884 72.6753 17.2925 62.3218 17.2925 49.6324C17.2925 36.5806 27.8675 26 40.9125 26C51.3683 26 60.2374 32.7973 63.3461 42.2162C66.1206 40.2289 69.5135 39.0603 73.1774 39.0603C82.5562 39.0603 90.1592 46.7173 90.1592 56.1627C90.1592 65.3998 82.8878 72.9265 73.7952 73.254V73.265H73.2336C73.2149 73.2651 73.1962 73.2651 73.1774 73.2651C73.1587 73.2651 73.14 73.2651 73.1213 73.265Z"
      fill="#F5F5F6"
      fillRule="evenodd"
    />
    <circle cx="74.4159" cy="56.4044" fill="#AAB2BC" r="16.5842" />
    <path
      clipRule="evenodd"
      d="M74.4152 68.3201C82.1638 67.6222 88.2361 61.1101 88.2361 53.1798C88.2361 44.7838 81.4298 37.9776 73.0338 37.9776C64.6379 37.9776 57.8316 44.7838 57.8316 53.1798C57.8316 53.9628 57.8908 54.732 58.005 55.4831H37.5614V72.9887H74.4152V68.3201Z"
      fill="#F5F5F6"
      fillRule="evenodd"
    />
    <rect fill="#AAB2BC" height="4.60673" rx="2.30337" width="54.3595" x="9.92139" y="50.8763" />
    <rect fill="#E6E9ED" height="4.60673" rx="2.30337" width="38.6966" x="9" y="61.0111" />
    <path
      d="M20.0566 70.6854C20.0566 69.4133 21.0879 68.382 22.36 68.382H74.4161V72.9888H22.36C21.0879 72.9888 20.0566 71.9575 20.0566 70.6854V70.6854Z"
      fill="#AAB2BC"
    />
  </svg>
);

export default IconWeatherMist;
