import { AnyAction } from 'redux';

import types from 'store/types';

export interface UiState {
  loading: boolean;
}

const initialUiState: UiState = {
  loading: false
};

const uiReducer = (state = initialUiState, action: AnyAction) => {
  switch (action.type) {
    case types.UI_START_LOADING:
      return {
        ...state,
        loading: true
      };

    case types.UI_FINISH_LOADING:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
      break;
  }
};

export default uiReducer;
