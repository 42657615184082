import { HTMLAttributes, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';

import { Skeleton } from 'components';
import Dropdown, { IDropdownOption } from 'components/Dropdown/Dropdown';
import {
  IconWeatherFewClouds,
  IconWeatherCloudy,
  IconWeatherMist,
  IconWeatherRain,
  IconWeatherSnow,
  IconWeatherThunderStorm
} from 'components/Icons';
import IconWaterDrop from 'components/Icons/IconWaterDrop';
import IconWeatherClearSky from 'components/Icons/IconWeatherClearSky';
import IconWind from 'components/Icons/IconWind';
import { useQueryWeatherData } from 'hooks';
import { IWeatherDataForecast } from 'interfaces/weatherInterface';

import './WeatherCard.scss';

dayjs.extend(utc);

export interface IWeatherCardProps extends HTMLAttributes<HTMLDivElement> {
  onChangeProvider: (provider: string) => void;
  location: number[];
  provider: string;
}

/**
 *
 *
 * @param {IWeatherCardProps}
 * @return {*}
 */
const WeatherCard = ({ className = '', provider, onChangeProvider, location }: IWeatherCardProps) => {
  const { t } = useTranslation();

  const sizeIcon = 130;

  const [weatherNow, setWeatherNow] = useState<IWeatherDataForecast>();

  const weatherProviders = [
    { id: 'OpenWeather', name: 'OpenWeather' },
    { id: 'MeteoConcept', name: 'MeteoConcept' }
  ];

  const {
    data: weatherData,
    isLoading: isLoadingPlotWeatherData,
    refetch: plotWeatherDataRefetch
  } = useQueryWeatherData(provider, location);

  const getHourForecast = (forecast: IWeatherDataForecast[]) => {
    const hourForecast = forecast.find((hour) => {
      const checkDate = dayjs(hour.datetime).utc(true).local();
      const today = dayjs().utc(true).local();
      return today.isSame(checkDate, 'hour');
    });
    if (hourForecast) {
      setWeatherNow(hourForecast);
    } else {
      setWeatherNow(forecast[0]);
    }
  };

  const changeProvider = (changedrovider: IDropdownOption) => {
    onChangeProvider(changedrovider.value as string);
  };

  useEffect(() => {
    plotWeatherDataRefetch();
  }, [location, provider]);

  useEffect(() => {
    if (weatherData) getHourForecast(weatherData.forecast);
  }, [weatherData]);

  return isLoadingPlotWeatherData ? (
    <Skeleton style={{ width: 'auto', height: 274 }} />
  ) : (
    <div className={`${className} weather`}>
      <div className="mb-3 weather-header">
        <Dropdown
          bindLabel="name"
          bindValue="id"
          className="col-span-3"
          defaultValue={provider}
          items={weatherProviders}
          onSelectOption={changeProvider}
          selectFirstOption={false}
          theme="dark"
        />
      </div>
      {weatherNow && (
        <div className="grid grid-cols-3 sm:grid-cols-1">
          <div className="col-span-2 grid grid-cols-2 items-center weather-body">
            <div className="col-span-1 weather-body__icon">
              {weatherNow.weather === 'Clear sky' && <IconWeatherClearSky height={sizeIcon} width={sizeIcon} />}
              {weatherNow.weather === 'Few clouds' && <IconWeatherFewClouds height={sizeIcon} width={sizeIcon} />}
              {weatherNow.weather === 'Cloudy' && <IconWeatherCloudy height={sizeIcon} width={sizeIcon} />}
              {weatherNow.weather === 'Mist' && <IconWeatherMist height={sizeIcon} width={sizeIcon} />}
              {weatherNow.weather === 'Rain' && <IconWeatherRain height={sizeIcon} width={sizeIcon} />}
              {weatherNow.weather === 'Snow' && <IconWeatherSnow height={sizeIcon} width={sizeIcon} />}
              {weatherNow.weather === 'Thunderstorm' && <IconWeatherThunderStorm height={sizeIcon} width={sizeIcon} />}
            </div>
            <div className="">
              <div className="weather-body__temperature">{weatherNow.temp}°c</div>
              <div className="weather-body__condition">
                {t(`WEATHER.CARD.SKY_STATUS.${weatherNow.weather.replaceAll(' ', '-')}`)}
              </div>
            </div>
          </div>

          <div className="gap-1 grid items-center sm:grid-cols-2 weather-footer">
            <div className="flex items-center">
              <IconWind />
              <div>
                <div className="weather-footer__wind">
                  {t('WEATHER.CARD.WIND_DIRECTION')}: <strong>{weatherNow.wind_dir}</strong>
                </div>
                <div className="weather-footer__wind-gust">
                  {t('WEATHER.CARD.WIND_GUST')}: <strong>{weatherNow.wind_speed} km/h</strong>
                </div>
              </div>
            </div>

            <div className="flex items-center ">
              <IconWaterDrop />
              <div className="weather-footer__precipitation">
                {t('WEATHER.CARD.HUMIDITY')}: <strong>{weatherNow.humidity}%</strong>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WeatherCard;
