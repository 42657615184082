import { Icon } from './icon.interface';

interface ILogoProps extends Icon {
  isWhite?: boolean;
}

const Logo = ({ className, height = 35, width = 35, isWhite = false }: ILogoProps) => (
  <div className="">
    {isWhite ? (
      <svg
        className={className}
        data-testid="svg-logo"
        fill="none"
        height={height}
        viewBox="0 0 136 136"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m78.242 43.633 1.06.702a175.556 175.556 0 0 1 29.65 24.673l.299.307.407-.1a163.114 163.114 0 0 1 25.629-3.931l.713-.049-.037-.722a67.6 67.6 0 0 0-12.409-35.704l-.27-.38-.462.083a162.647 162.647 0 0 0-43.446 14.552l-1.134.57Z"
          fill="#fff"
        />
        <path
          d="m67.64 37.199.358.199.356-.2a173.202 173.202 0 0 1 45.909-17.42l1.348-.307-1.001-.942A67.883 67.883 0 0 0 67.999 0a67.884 67.884 0 0 0-46.611 18.529l-1.006.942 1.348.307a173.2 173.2 0 0 1 45.91 17.42Z"
          fill="#fff"
        />
        <path
          d="M26.343 69.215c2.282.541 4.39 1.08 6.449 1.66 1.805.502 4.167 1.175 6.508 1.931a163.734 163.734 0 0 1 28.34 12.321l.359.2.355-.2A163.687 163.687 0 0 1 96.7 72.808l1.165-.376-.886-.852a165.41 165.41 0 0 0-28.597-22.049 172.407 172.407 0 0 0-5.834-3.384c-1.881-1.038-3.797-2.009-5.927-3.086a162.892 162.892 0 0 0-43.442-14.55l-.459-.084-.276.38A67.608 67.608 0 0 0 .037 64.52L0 65.238l.72.053c8.632.627 17.2 1.94 25.623 3.928"
          fill="#fff"
        />
        <path
          d="M68.379 97.454c-1.961-1.201-3.935-2.35-5.871-3.415-2.173-1.2-4.1-2.214-5.887-3.099a152.277 152.277 0 0 0-26.06-10.043 155.988 155.988 0 0 0-6.407-1.663 147.811 147.811 0 0 0-6.502-1.402 153.288 153.288 0 0 0-16.374-2.226l-.904-.077.113.901a67.54 67.54 0 0 0 4.838 18.065 68.894 68.894 0 0 0 6.57 11.939 68.712 68.712 0 0 0 12.097 13.378 69.654 69.654 0 0 0 4.645 3.609 67.856 67.856 0 0 0 73.6 3.307l.782-.456-.607-.674a154.586 154.586 0 0 0-34.033-28.144ZM118.342 77.83a153.96 153.96 0 0 0-6.502 1.402 150.1 150.1 0 0 0-6.405 1.67 152.128 152.128 0 0 0-26.07 10.04l-1.14.56 1.061.707a164.997 164.997 0 0 1 31.692 27.53l.474.537.552-.463a68.836 68.836 0 0 0 12.098-13.38 68.981 68.981 0 0 0 6.565-11.933 67.298 67.298 0 0 0 4.839-18.072l.113-.896-.896.077a153.198 153.198 0 0 0-16.375 2.226"
          fill="#fff"
        />
      </svg>
    ) : (
      <svg
        className={className}
        data-testid="svg-logo"
        fill="none"
        height={height}
        viewBox="0 0 136 136"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m78.242 43.633 1.06.702a175.556 175.556 0 0 1 29.65 24.673l.299.307.407-.1a163.114 163.114 0 0 1 25.629-3.931l.713-.049-.037-.722a67.6 67.6 0 0 0-12.409-35.704l-.27-.38-.462.083a162.647 162.647 0 0 0-43.446 14.552l-1.134.57Z"
          fill="#65B22E"
        />
        <path
          d="m67.64 37.199.358.199.356-.2a173.202 173.202 0 0 1 45.909-17.42l1.348-.307-1.001-.942A67.883 67.883 0 0 0 67.999 0a67.884 67.884 0 0 0-46.611 18.529l-1.006.942 1.348.307a173.2 173.2 0 0 1 45.91 17.42Z"
          fill="#DBCD00"
        />
        <path
          d="M26.343 69.215c2.282.541 4.39 1.08 6.449 1.66 1.805.502 4.167 1.175 6.508 1.931a163.734 163.734 0 0 1 28.34 12.321l.359.2.355-.2A163.687 163.687 0 0 1 96.7 72.808l1.165-.376-.886-.852a165.41 165.41 0 0 0-28.597-22.049 172.407 172.407 0 0 0-5.834-3.384c-1.881-1.038-3.797-2.009-5.927-3.086a162.892 162.892 0 0 0-43.442-14.55l-.459-.084-.276.38A67.608 67.608 0 0 0 .037 64.52L0 65.238l.72.053c8.632.627 17.2 1.94 25.623 3.928"
          fill="#65B22E"
        />
        <path
          d="M68.379 97.454c-1.961-1.201-3.935-2.35-5.871-3.415-2.173-1.2-4.1-2.214-5.887-3.099a152.277 152.277 0 0 0-26.06-10.043 155.988 155.988 0 0 0-6.407-1.663 147.811 147.811 0 0 0-6.502-1.402 153.288 153.288 0 0 0-16.374-2.226l-.904-.077.113.901a67.54 67.54 0 0 0 4.838 18.065 68.894 68.894 0 0 0 6.57 11.939 68.712 68.712 0 0 0 12.097 13.378 69.654 69.654 0 0 0 4.645 3.609 67.856 67.856 0 0 0 73.6 3.307l.782-.456-.607-.674a154.586 154.586 0 0 0-34.033-28.144ZM118.342 77.83a153.96 153.96 0 0 0-6.502 1.402 150.1 150.1 0 0 0-6.405 1.67 152.128 152.128 0 0 0-26.07 10.04l-1.14.56 1.061.707a164.997 164.997 0 0 1 31.692 27.53l.474.537.552-.463a68.836 68.836 0 0 0 12.098-13.38 68.981 68.981 0 0 0 6.565-11.933 67.298 67.298 0 0 0 4.839-18.072l.113-.896-.896.077a153.198 153.198 0 0 0-16.375 2.226"
          fill="#1D7B33"
        />
      </svg>
    )}
  </div>
);

export default Logo;
