import { Icon } from './icon.interface';

<svg fill="none" height="40" viewBox="0 0 31 40" width="31" xmlns="http://www.w3.org/2000/svg" />;

const IconFilePDF = ({ height = 40, width = 31 }: Icon) => (
  <svg
    data-testid="icon-file-image"
    fill="none"
    height={height}
    viewBox="0 0 40 31"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.556 38.537H3.444A2.944 2.944 0 0 1 .5 35.593V3.444A2.944 2.944 0 0 1 3.444.5h18.062c.796 0 1.558.322 2.113.894l6.05 6.233c.533.549.831 1.285.831 2.05v25.916a2.944 2.944 0 0 1-2.944 2.944Z"
      fill="#fff"
      stroke="#4C4E58"
    />
    <path
      d="M25.86 23.047c-.51-.918-2.27-1.206-3.076-1.336a12.112 12.112 0 0 0-1.93-.132 23.262 23.262 0 0 0-1.494.05c-.18.012-.36.028-.54.044-.183-.193-.36-.39-.532-.594-1.093-1.308-1.975-2.791-2.694-4.333.19-.745.343-1.522.436-2.33.168-1.473.226-3.152-.32-4.556-.189-.485-.691-1.075-1.271-.781-.667.338-.855 1.295-.909 1.974a6.663 6.663 0 0 0 .078 1.638c.093.543.242 1.06.405 1.574.152.474.32.942.501 1.405-.114.361-.236.72-.367 1.074-.301.798-.626 1.555-.939 2.284l-.484 1.06c-.5 1.11-1.043 2.2-1.651 3.253-1.42.507-2.695 1.094-3.754 1.784-.568.371-1.07.774-1.487 1.216-.394.417-.795.958-.83 1.558a.987.987 0 0 0 .387.867c.377.285.876.266 1.313.173 1.433-.304 2.533-1.549 3.47-2.598.645-.723 1.38-1.64 2.147-2.75l.006-.008a32.214 32.214 0 0 1 4.273-1.018 28.982 28.982 0 0 1 2.137-.28c.512.485 1.065.927 1.669 1.296.47.292.97.539 1.493.723.529.175 1.061.317 1.61.408.278.04.562.058.852.047.647-.025 1.576-.276 1.637-1.07a1.118 1.118 0 0 0-.137-.642Zm-15.495 1.456c-.277.436-.567.861-.87 1.278-.675.928-1.446 2.03-2.561 2.442-.212.079-.491.16-.785.142-.262-.016-.52-.132-.509-.433.006-.158.082-.358.2-.556.127-.216.286-.414.457-.597.367-.39.832-.768 1.366-1.119.82-.537 1.799-1.021 2.89-1.453l-.188.296Zm3.804-11.906a5.454 5.454 0 0 1-.045-1.484c.025-.237.074-.472.145-.7.06-.187.19-.646.395-.705.34-.097.444.64.483.849.219 1.186.026 2.505-.237 3.669-.042.185-.088.367-.136.549a17.196 17.196 0 0 1-.23-.681 11.27 11.27 0 0 1-.375-1.497Zm2.332 9.366c-1.224.2-2.437.469-3.63.806.137-.039.764-1.241.904-1.491.66-1.175 1.2-2.408 1.588-3.702.684 1.37 1.516 2.68 2.537 3.846.094.105.19.21.287.313a32.63 32.63 0 0 0-1.686.228Zm8.629 1.652c-.047.255-.586.401-.837.441-.743.118-1.529.024-2.24-.218a7.142 7.142 0 0 1-1.405-.662 8.344 8.344 0 0 1-1.25-.943c.48-.03.965-.048 1.452-.039.488.005.98.03 1.464.094.91.102 1.93.418 2.646 1.015.141.118.186.222.17.312Z"
      fill="#FB3449"
    />
  </svg>
);

export default IconFilePDF;
